import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import { XR } from "@react-three/xr";
import { XrUi } from "react-xr-ui";
import App from "@/components/app";
import { appState } from "@/state/app-state";

if ("serviceWorker" in navigator && process.env.NODE_ENV === "production") {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("./service-worker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}

const rootElement = document.querySelector("#root");
const root = ReactDOM.createRoot(rootElement!);

window.addEventListener("click", () => {
  appState.getState().setInteracted();
});

appState
  .getState()
  .init()
  .finally(() => {
    root.render(
      <Canvas legacy flat linear>
        <XR
          onSessionEnd={() => {
            window.location.href = "./";
          }}
        >
          <XrUi layerResolution={512} optimizedRendering={true} fontFamily="Optimistic Display, sans-serif">
            <Router>
              <App />
            </Router>
          </XrUi>
        </XR>
      </Canvas>
    );
  });
