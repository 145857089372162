import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8CallOpening() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const [videoSrc, setVideoSrc] = React.useState(assets.video3);

  return (
    <>
      {![0, 3].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          src={videoSrc}
          play={false}
          start={videoSplit["3"]["1"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["1"].end}
        />
      )}
      <Title textContent="CALL OPENING" />
      {step === 0 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["3"]["1"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["1"].end}
          src={assets.video3}
          onEnded={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Showing \nup prepared`}
          subtitle="In the call opening: "
          image={assets["./content/autogenerated/new-images/3-2-1.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/call-opening-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(2)} />, <ArrowButton dir="next" onDown={() => setStep(3)} />]}
        />
      )}
      {step === 2 && (
        <CardLarge
          title={`Showing \nup prepared`}
          image={assets["./content/autogenerated/new-images/3-2-2.jpg"]}
          subtitle="Examples:"
          descriptionImage={assets["./content/autogenerated/shared/call-opening-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(3)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 3 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["3"]["2"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["2"].end}
          src={assets.video3}
          onEnded={() => setStep(4)}
        />
      )}
      {step === 4 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Call Opening"
          image={assets["./content/autogenerated/script2/call-opening-step-4.png"]}
          description={`Sindy did a great job of displaying the preparation for the call by referring to their last conversation and the advertiser’s previously stated business goal.\n\n   Showing preparation builds credibility and as a result, the advertiser will be more likely to trust Sindy’s recommendations.`}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(5)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 5 && (
        <MultipleChoiceQuestion
          subtitle={`Call Opening`}
          image={assets["./content/autogenerated/new-images/3-2-5-7.jpg"]}
          imageIncorrect={assets["./content/autogenerated/new-images/3-2-9.jpg"]}
          descriptionCorrect="It’s important to provide context for questions to gain buy-in, especially when the advertiser has confirmed that time is limited."
          question={`What could Sindy have done to open the call more effectively?`}
          answers={[
            {
              answer: `Sindy should have asked about when to schedule the next call before going over the agenda.`,
              correct: false,
            },
            {
              answer: `Sindy should have checked if the advertiser had opened any new support cases since the last call.`,
              correct: false,
            },
            {
              answer: `Sindy should have confirmed that the advertiser agreed to the stated agenda.`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/discovery`);
          }}
        />
      )}
    </>
  );
}
