export default {
  1: {
    1: {
      start: "00:00:00.00",
      end: "00:00:25.06",
    },
    2: {
      start: "00:00:25.06",
      end: "00:04:40.29",
    },
    3: {
      start: "00:04:40.29",
      end: "00:07:44.07",
    },
    4: {
      start: "00:07:44.07",
      end: "00:11:27.29",
    },
    5: {
      start: "00:11:27.29",
      end: "00:12:34.58",
    },
    6: {
      start: "00:12:34.58",
      end: "00:19:40.10",
    },
    7: {
      start: "00:19:40.10",
      end: "00:21:35.09",
    },
  },
  2: {
    1: {
      start: "00:00:00.00",
      end: "00:00:36.04",
    },
    2: {
      start: "00:00:36.04",
      end: "00:02:03.01",
    },
    3: {
      start: "00:02:03.01",
      end: "00:04:21.21",
    },
    4: {
      start: "00:04:21.21",
      end: "00:11:38.25",
    },
    5: {
      start: "00:11:38.25",
      end: "00:15:35.01",
    },
    6: {
      start: "00:15:35.01",
      end: "00:16:09.11",
    },
    7: {
      start: "00:16:09.11",
      end: "00:17:48.07",
    },
  },
  3: {
    1: {
      start: "00:00:00.00",
      end: "00:00:25.30",
    },
    2: {
      start: "00:00:26.00",
      end: "00:01:32.17",
    },
    3: {
      start: "00:01:32.17",
      end: "00:04:50.10",
    },
    4: {
      start: "00:04:50.10",
      end: "00:05:48.10",
    },
    5: {
      start: "00:05:48.10",
      end: "00:09:41.20",
    },
    6: {
      start: "00:09:41.20",
      end: "00:10:39.05",
    },
    7: {
      start: "00:10:39.05",
      end: "00:13:28.21",
    },
  },
  4: {
    1: {
      start: "00:00:00.00",
      end: "00:01:07.12",
    },
    2: {
      start: "00:01:07.12",
      end: "00:02:43.16",
    },
    3: {
      start: "00:02:43.16",
      end: "00:03:54.15",
    },
    4: {
      start: "00:03:54.15",
      end: "00:06:24.12",
    },
    5: {
      start: "00:06:24.12",
      end: "00:06:47.10",
    },
    6: {
      start: "00:06:47.10",
      end: "00:07:56.11",
    }
  },
  5: {
    1: {
      start: "00:00:00.00",
      end: "00:00:30.27",
    },
    2: {
      start: "00:00:30.27",
      end: "00:02:01.02",
    },
    3: {
      start: "00:02:01.02",
      end: "00:06:36.03",
    },
    4: {
      start: "00:06:36.03",
      end: "00:07:59.29",
    },
    5: {
      start: "00:07:59.29",
      end: "00:10:12.16",
    },
    6: {
      start: "00:10:12.16",
      end: "00:10:44.15",
    },
    7: {
      start: "00:10:44.15",
      end: "00:12:00.20",
    },
  },
  6: {
    1: {
      start: "00:00:00.00",
      end: "00:00:20.04",
    },
    2: {
      start: "00:00:20.04",
      end: "00:01:52.05",
    },
    3: {
      start: "00:01:52.05",
      end: "00:03:06.20",
    },
    4: {
      start: "00:03:06.20",
      end: "00:05:22.10",
    },
    5: {
      start: "00:05:22.10",
      end: "00:06:09.11",
    },
    6: {
      start: "00:06:09.11",
      end: "00:07:21.25",
    },
  },
  7: {
    1: {
      start: "00:00:00.00",
      end: "00:00:29.14",
    },
    2: {
      start: "00:00:29.14",
      end: "00:03:05.01",
    },
    3: {
      start: "00:03:05.01",
      end: "00:04:36.07",
    },
    4: {
      start: "00:04:36.07",
      end: "00:05:51.00",
    },
    5: {
      start: "00:05:51.00",
      end: "00:08:35.10",
    },
    6: {
      start: "00:08:35.10",
      end: "00:09:32.50",
    },
    7: {
      start: "00:09:33.00",
      end: "00:10:30.06",
    },
  },
  8: {
    1: {
      start: "00:00:00.00",
      end: "00:00:30.04",
    },
    2: {
      start: "00:00:30.04",
      end: "00:01:03.18",
    },
    3: {
      start: "00:01:03.18",
      end: "00:02:10.00",
    },
    4: {
      start: "00:02:10.00",
      end: "00:09:34.06",
    },
    5: {
      start: "00:09:34.06",
      end: "00:11:42.05",
    },
  },
} as const;
