import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Interaction, Layer } from "react-xr-ui";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";

type GLTFResult = GLTF & {
  nodes: {
    Cylinder048: THREE.Mesh;
    Cylinder048_1: THREE.Mesh;
    Cylinder048_2: THREE.Mesh;
    Cylinder001: THREE.Mesh;
    Cylinder001_1: THREE.Mesh;
    Cylinder001_2: THREE.Mesh;
    Cylinder002: THREE.Mesh;
    Cylinder002_1: THREE.Mesh;
    Cylinder002_2: THREE.Mesh;
    Cylinder003: THREE.Mesh;
    Cylinder003_1: THREE.Mesh;
    Cylinder003_2: THREE.Mesh;
    Cylinder004: THREE.Mesh;
    Cylinder004_1: THREE.Mesh;
    Cylinder004_2: THREE.Mesh;
    Cylinder005: THREE.Mesh;
    Cylinder005_1: THREE.Mesh;
    Cylinder005_2: THREE.Mesh;
    Cylinder006: THREE.Mesh;
    Cylinder006_1: THREE.Mesh;
    Cylinder006_2: THREE.Mesh;
    Cylinder007: THREE.Mesh;
    Cylinder007_1: THREE.Mesh;
    Cylinder007_2: THREE.Mesh;
  };
  materials: {
    ["meta blue"]: THREE.MeshStandardMaterial;
    white: THREE.MeshStandardMaterial;
    ["white.001"]: THREE.MeshStandardMaterial;
  };
};

type Vector = [x: number, y: number, z: number];

type Modules = {
  [id: string]: { rotation: Vector; position: Vector; title: string };
};

const MODULES: Modules = {
  "1": {
    rotation: [0, 0, 0],
    position: [-2.5, 1.8, 1],
    title: "Building Trust with a New \n Advertiser",
  },
  "2": {
    rotation: [0, 0, 0],
    position: [-2, 2.2, 1],
    title: "Securing Budget \nCommitment",
  },
  "3": {
    rotation: [0, 0, 0],
    position: [-1.25, 2.6, 1],
    title: "Building Trust Through Initial\nAccount Optimization",
  },
  "4": {
    rotation: [0, 0, 0],
    position: [-0.5, 2.8, 1],
    title: "Delivering Tailored Solutions",
  },
  "5": {
    rotation: [0, 0, 0],
    position: [0.5, 2.8, 1],
    title: "Overcoming Business Integrity\nBlockers",
  },
  "6": {
    rotation: [0, 0, 0],
    position: [1.25, 2.6, 1],
    title: "Re-engaging a \nLapsed Advertiser",
  },
  "7": {
    rotation: [0, 0, 0],
    position: [2.5, 1.8, 1],
    title: "Re-engaging a Doubtful\nAdvertiser",
  },
  "8": {
    rotation: [0, 0, 0],
    position: [2, 2.2, 1],
    title: "Experienced Advertiser Setting\nup Product Catalog",
  },
};

const ACTIONS = [
  "4Action.002",
  "4Action",
  "4Action.003",
  "4Action.004",
  "4Action.005",
  "4Action.006",
  "4Action.007",
  "4Action.008",
];

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials, animations } = useGLTF(
    assets["./content/autogenerated/models/meta-menu.gltf"]
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);
  const [selected, setSelected] = React.useState<keyof typeof MODULES | "">("");
  const navigate = useNavigate();

  const [params, setParams] = useSearchParams();

  React.useEffect(() => {
    if (!params.has("module-selection")) {
      setParams({ "module-selection": "true" });
    }
  }, [params]);

  const setModuleId = (moduleId: string) => {
    // Set moduleId param in url
    navigate(`/?module-selection=${moduleId}`);
  };

  React.useEffect(() => {
    ACTIONS.forEach((action, index) => {
      const active = String(index + 1) === selected;
      actions[action]!.loop = THREE.LoopOnce;
      actions[action]!.clampWhenFinished = true;
      actions[action]!.paused = false;
      actions[action]!.timeScale = active ? 1 : -1;
      actions[action]!.play();
    });
  }, [selected, actions]);

  const materialsMap = React.useMemo(() => {
    return {
      default: new THREE.MeshStandardMaterial({ color: 0x0000ff }),
      selected: new THREE.MeshStandardMaterial({ color: 0x00aaff }),
      completed: new THREE.MeshStandardMaterial({ color: 0x888888 }),
    };
  }, [materials]);

  const completedModuleIds = appState((state) => state.completedModuleIds);

  const getMaterial = React.useCallback(
    (id: string) => {
      if (id === selected) {
        return materialsMap.selected;
      }
      if (completedModuleIds.includes(id)) {
        return materialsMap.completed;
      }
      return materialsMap.default;
    },
    [selected, materialsMap, completedModuleIds]
  );

  const menuStep = appState((state) => state.menuStep);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <Interaction
          onOver={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setSelected("1");
          }}
          onDown={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setModuleId("1");
          }}
        >
          <group
            name="Cylinder1"
            position={[-1.662, 0.788, 1.088]}
            rotation={[0, 1.213, 0]}
            scale={[2.966, 0.054, 2.966]}
          >
            <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={getMaterial("1")} />
            <mesh
              name="Cylinder001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001_1.geometry}
              material={materials.white}
            />
            <mesh name="Cylinder001_2" geometry={nodes.Cylinder001_2.geometry} material={materials.white}>
              {!completedModuleIds.includes("1") && <meshPhongMaterial color="gray" opacity={0.5} transparent />}
            </mesh>
          </group>
        </Interaction>
        <Interaction
          onOver={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setSelected("2");
          }}
          onDown={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setModuleId("2");
          }}
        >
          <group
            name="Cylinder2"
            position={[-1.263, 0.788, 0.648]}
            rotation={[0, 1.213, 0]}
            scale={[2.966, 0.054, 2.966]}
          >
            <mesh
              name="Cylinder002"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder002.geometry}
              material={getMaterial("2")}
            />
            <mesh
              name="Cylinder002_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder002_1.geometry}
              material={materials.white}
            />
            <mesh
              name="Cylinder002_2"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder002_2.geometry}
              material={materials["white.001"]}
            >
              {!completedModuleIds.includes("2") && <meshPhongMaterial color="gray" opacity={0.5} transparent />}
            </mesh>
          </group>
        </Interaction>
        <Interaction
          onOver={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setSelected("3");
          }}
          onDown={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setModuleId("3");
          }}
        >
          <group
            name="Cylinder3"
            position={[-0.77, 0.788, 0.365]}
            rotation={[0, 1.213, 0]}
            scale={[2.966, 0.054, 2.966]}
          >
            <mesh
              name="Cylinder048"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder048.geometry}
              material={getMaterial("3")}
            />
            <mesh
              name="Cylinder048_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder048_1.geometry}
              material={materials.white}
            />
            <mesh
              name="Cylinder048_2"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder048_2.geometry}
              material={materials.white}
            >
              {!completedModuleIds.includes("3") && <meshPhongMaterial color="gray" opacity={0.5} transparent />}
            </mesh>
          </group>
        </Interaction>
        <Interaction
          onOver={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setSelected("4");
          }}
          onDown={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setModuleId("4");
          }}
        >
          <group
            name="Cylinder4"
            position={[-0.216, 0.788, 0.234]}
            rotation={[0, 1.213, 0]}
            scale={[2.966, 0.054, 2.966]}
          >
            <mesh
              name="Cylinder007"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder007.geometry}
              material={getMaterial("4")}
            />
            <mesh
              name="Cylinder007_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder007_1.geometry}
              material={materials.white}
            />
            <mesh
              name="Cylinder007_2"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder007_2.geometry}
              material={materials.white}
            >
              {!completedModuleIds.includes("4") && <meshPhongMaterial color="gray" opacity={0.5} transparent />}
            </mesh>
          </group>
        </Interaction>
        <Interaction
          onOver={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setSelected("5");
          }}
          onDown={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setModuleId("5");
          }}
        >
          <group
            name="Cylinder5"
            position={[0.33, 0.788, 0.241]}
            rotation={[0, 1.213, 0]}
            scale={[2.966, 0.054, 2.966]}
          >
            <mesh
              name="Cylinder006"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder006.geometry}
              material={getMaterial("5")}
            />
            <mesh
              name="Cylinder006_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder006_1.geometry}
              material={materials.white}
            />
            <mesh
              name="Cylinder006_2"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder006_2.geometry}
              material={materials.white}
            >
              {!completedModuleIds.includes("5") && <meshPhongMaterial color="gray" opacity={0.5} transparent />}
            </mesh>
          </group>
        </Interaction>
        <Interaction
          onOver={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setSelected("6");
          }}
          onDown={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setModuleId("6");
          }}
        >
          <group
            name="Cylinder6"
            position={[0.881, 0.788, 0.404]}
            rotation={[0, 1.213, 0]}
            scale={[2.966, 0.054, 2.966]}
          >
            <mesh
              name="Cylinder005"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder005.geometry}
              material={getMaterial("6")}
            />
            <mesh
              name="Cylinder005_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder005_1.geometry}
              material={materials.white}
            />
            <mesh
              name="Cylinder005_2"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder005_2.geometry}
              material={materials.white}
            >
              {!completedModuleIds.includes("6") && <meshPhongMaterial color="gray" opacity={0.5} transparent />}
            </mesh>
          </group>
        </Interaction>
        <Interaction
          onOver={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setSelected("7");
          }}
          onDown={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setModuleId("7");
          }}
        >
          <group
            name="Cylinder7"
            position={[1.388, 0.788, 0.695]}
            rotation={[0, 1.213, 0]}
            scale={[2.966, 0.054, 2.966]}
          >
            <mesh
              name="Cylinder004"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder004.geometry}
              material={getMaterial("7")}
            />
            <mesh
              name="Cylinder004_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder004_1.geometry}
              material={materials.white}
            />
            <mesh
              name="Cylinder004_2"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder004_2.geometry}
              material={materials.white}
            >
              {!completedModuleIds.includes("7") && <meshPhongMaterial color="gray" opacity={0.5} transparent />}
            </mesh>
          </group>
        </Interaction>
        <Interaction
          onOver={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setSelected("8");
          }}
          onDown={() => {
            if (params.get("module-selection") !== "true" || menuStep === 5) return;
            setModuleId("8");
          }}
        >
          <group
            name="Cylinder8"
            position={[1.745, 0.788, 1.137]}
            rotation={[0, 1.213, 0]}
            scale={[2.966, 0.054, 2.966]}
          >
            <mesh
              name="Cylinder003"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder003.geometry}
              material={getMaterial("8")}
            />
            <mesh
              name="Cylinder003_1"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder003_1.geometry}
              material={materials.white}
            />
            <mesh
              name="Cylinder003_2"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder003_2.geometry}
              material={materials.white}
            >
              {!completedModuleIds.includes("8") && <meshPhongMaterial color="gray" opacity={0.5} transparent />}
            </mesh>
          </group>
        </Interaction>
        {selected && (
          <Layer
            width="150%"
            height="50%"
            backgroundSize="contain"
            alphaTest={1}
            backgroundImage={assets["./content/autogenerated/menu-title.png"]}
            position={[0, 0.5, 1]}
          >
            <Layer
              textContent={MODULES[selected]?.title}
              width="100%"
              textAlign="center"
              position={[0, -0.32, 0]}
              fontSize={0.1}
            />
          </Layer>
        )}
      </group>
    </group>
  );
}

useGLTF.preload(assets["./content/autogenerated/models/meta-menu.gltf"]);
