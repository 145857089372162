import React from "react";
import * as THREE from "three";
import { Layer, LayerRef } from "react-xr-ui";
import assets from "@/config/assets";
import ButtonInteraction from "@/components/button-interaction";

type Props = {
  dir: "prev" | "next";
  onDown?: () => void;
  enabled?: boolean;
};

export default function ArrowButton({ dir, onDown, enabled = true }: Props) {
  const layerRef = React.useRef<LayerRef>(null);

  return (
    <group rotation-z={THREE.MathUtils.degToRad(dir === "prev" ? 180 : 0)}>
      <Layer ref={layerRef} opacity={enabled ? 1 : 0} height="100%" aspectRatio={64 / 60} backgroundColor="#1877F2">
        <Layer
          height="30%"
          aspectRatio={29 / 20}
          backgroundImage={assets.arrow}
          backgroundPosition={[0.5, 0.5]}
          backgroundSize="contain"
        />
        <ButtonInteraction
          enabled={enabled}
          onOver={() => {
            if (layerRef.current === null) return;
            layerRef.current.setAttrs((attrs) => {
              return { ...attrs, backgroundColor: "#1C2B33" };
            });
          }}
          onOut={() => {
            if (layerRef.current === null) return;
            layerRef.current.setAttrs((attrs) => {
              return { ...attrs, backgroundColor: "#1877F2" };
            });
          }}
          onDown={onDown}
        >
          <Layer autoLayout={false} width="100%" height="100%" />
        </ButtonInteraction>
      </Layer>
    </group>
  );
}
