import React from "react";
import assets from "@/config/assets";
import Card from "@/components/card";
import StartButton from "@/components/start-button";
import { useNavigate } from "react-router-dom";
import ArrowButton from "@/components/arrow-button";

export default function Module8Home() {
  const navigate = useNavigate();

  return (
    <>
      <Card
        title={`Re-engaging a Doubtful\nAdvertiser`}
        image={assets["./content/autogenerated/script7/home.jpeg"]}
        descriptionFontSize={1 / 13}
        description={`This advertiser had just been added to the Marketing Pro’s book of business. After reviewing the notes, they see that the previous Marketing Pro who managed the account says the advertiser requested they stop calling because they deemed it unhelpful.`}
        buttons={[
          <ArrowButton
            onDown={() => {
              navigate(`/?module-selection=true`);
            }}
            dir="prev"
          />,
          <StartButton moduleId="7" />,
        ]}
      />
    </>
  );
}
