import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8CallOpening() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const [videoSrc, setVideoSrc] = React.useState(assets.video4);

  const videoRotation = useVideoRotation([0, -80, 0]);

  return (
    <>
      {![0, 3].includes(step) && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["4"]["1"].start}
          subtitlesFile="./subtitles/4.vtt"
          end={videoSplit["4"]["1"].end}
          src={videoSrc}
          play={false}
        />
      )}
      <Title textContent="CALL OPENING" />
      {step === 0 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["4"]["1"].start}
          subtitlesFile="./subtitles/4.vtt"
          end={videoSplit["4"]["1"].end}
          src={assets.video4}
          onEnded={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Showing \nup prepared`}
          subtitle="In the call opening: "
          image={assets["./content/autogenerated/script3/call-opening-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/call-opening-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(2)} />, <ArrowButton dir="next" onDown={() => setStep(3)} />]}
        />
      )}
      {step === 2 && (
        <CardLarge
          title={`Showing \nup prepared`}
          image={assets["./content/autogenerated/script2/call-opening-step-2.jpeg"]}
          subtitle="Examples:"
          descriptionImage={assets["./content/autogenerated/shared/call-opening-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(3)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 3 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["4"]["2"].start}
          subtitlesFile="./subtitles/4.vtt"
          end={videoSplit["4"]["2"].end}
          src={assets.video4}
          onEnded={() => setStep(4)}
        />
      )}
      {step === 4 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Call Opening"
          image={assets["./content/autogenerated/script2/call-opening-step-4.png"]}
          description={`Sindy demonstrated excellent preparation for the conversation, this time by referring to previous conversations and the successful implementation of CAPI.\n\n   This is important as it maintains credibility and builds trust.\n\n   Sindy also presented a clear agenda for the call, giving the advertiser a sense of purpose and focus. Well done!`}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(5)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 5 && (
        <MultipleChoiceQuestion
          subtitle={`Call Opening`}
          image={assets["./content/autogenerated/script2/call-opening-step-5.png"]}
          imageCorrect={assets["./content/autogenerated/script2/call-opening-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/script2/call-opening-incorrect.png"]}
          descriptionCorrect="Sindy could have asked the advertiser if they had any agenda items they’d like to include. Be sure to use the POInT framework when setting the agenda."
          question={`What step did Sindy skip?`}
          answers={[
            {
              answer: `Sindy didn’t check the last four digits of the account ID to verify the advertiser’s identity.`,
              correct: false,
            },
            {
              answer: `Sindy didn’t use an appropriate opening to greet the advertiser.`,
              correct: false,
            },
            {
              answer: `Sindy didn’t validate the agenda to see if the advertiser had any additional items to include.`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/discovery`);
          }}
        />
      )}
    </>
  );
}
