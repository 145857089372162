import React from "react";
import assets from "@/config/assets";
import Card from "@/components/card";
import StartButton from "@/components/start-button";
import { useNavigate } from "react-router-dom";
import ArrowButton from "@/components/arrow-button";

export default function Module8Home() {
  const navigate = useNavigate();

  return (
    <>
      <Card
        title={`Re-engaging A Lapsed Advertiser`}
        image={assets["./content/autogenerated/script6/home.png"]}
        descriptionFontSize={1 / 13}
        description={`The Marketing Pro is about to make  a cold call to a previously active advertiser on the platform for their retail business, Wind & Wool. Recently, she stopped running ads, and no longer wants help from Meta Marketing Pros. Sindy is going to sell the value of Marketing Pros by demonstrating knowledge of her business, and recommending why they should start advertising with Meta again.`}
        buttons={[
          <ArrowButton
            onDown={() => {
              navigate(`/?module-selection=true`);
            }}
            dir="prev"
          />,
          <StartButton moduleId="6" />,
        ]}
      />
    </>
  );
}
