import React from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

type Props = {
  children: React.ReactNode;
  delay?: number;
  speed?: number;
};

export default function FadeIn({ children, delay = 0, speed = 0.05 }: Props) {
  const groupRef = React.useRef<THREE.Group>(null);

  const startRef = React.useRef(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const group = groupRef.current;
      if (group === null) return;
      group.traverse((object) => {
        if (!(object instanceof THREE.Mesh)) return;
        if (!(object.material instanceof THREE.Material)) return;
        object.material.transparent = true;
        object.material.opacity = 0;
        object.material.needsUpdate = true;
      });

      startRef.current = true;
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  useFrame(() => {
    if (!startRef.current) return;
    const group = groupRef.current;
    if (group === null) return;
    group.visible = true;
    group.traverse((object) => {
      if (!(object instanceof THREE.Mesh)) return;
      if (!(object.material instanceof THREE.Material)) return;
      object.material.transparent = true;
      object.material.opacity = THREE.MathUtils.lerp(object.material.opacity, 1, speed);
      object.material.needsUpdate = true;
    });
  });

  return (
    <group ref={groupRef} visible={false}>
      {children}
    </group>
  );
}
