import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8Discovery() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!)
  }, [step])
  const navigate = useNavigate()


  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          start={videoSplit["1"]["3"].start}
          subtitlesFile="./subtitles/1.vtt"
          end={videoSplit["1"]["3"].end}
          src={assets.video1}
          play={false}
        />
      )}
      <Title textContent="DISCOVERY" />
      {step === 0 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          subtitle="During Discovery: "
          image={assets.discoveryStep1}
          descriptionImage={assets["./content/autogenerated/shared/discovery-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Seek to \nunderstand`}

          image={assets["./content/autogenerated/script1/discovery-step-1.jpeg"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          start={videoSplit["1"]["3"].start}
          subtitlesFile="./subtitles/1.vtt"
          end={videoSplit["1"]["3"].end}
          src={assets.video1}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Discovery & Connection"
          image={assets["./content/autogenerated/script1/discovery-step-3.png"]}
          descriptionFontSize={0.04}
          description={`Simon asked a broad opening question and then became more specific and focused as they progressed. This is a good example of asking structured questions, which can help keep the conversation focused.\n\nSimon also asked some cohesive questions. These are often in direct response to the information just provided by the advertiser and result in the conversation having a natural and logical flow. \n\nSimon asked closed questions that could have been asked as open questions Such as;\n\n“Do you have a specific goal in mind for that?”\n\n“Do you advertise online on any other platforms, outside of us and Google?”`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Discovery`}
          image={assets["./content/autogenerated/script1/discovery-question.png"]}
          imageCorrect={assets["./content/autogenerated/script1/discovery-step-1.jpeg"]}
          imageIncorrect={assets["./content/autogenerated/script1/discovery-question-incorrect.png"]}
          descriptionCorrect={`Closed questions only require a yes or a no answer, but open questions need a longer answer, which often contains important information.\n\nSimon also provided an excellent summary and gave the advertiser an opportunity to correct or add anything at the end. This ensures they are ending the conversation on the same page.`}
          question={`Why is it important to communicate existing knowledge of the advertiser’s situation?`}
          answers={[
            {
              answer: `Open questions often enable you to gather additional information from the answers.`,
              correct: true,
            },
            {
              answer: `Open questions often allow the advertiser a chance to talk while you are researching your next topic or question.`,
              correct: false,
            },
            {
              answer: `Open questions often make you seem more qualified to help with an advertiser’s needs.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/commercial-conversations`);
          }}
        />
      )}
    </>
  );
}
