import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8SecuringCommitment() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const videoRotation = useVideoRotation([0, -80, 0]);

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["4"]["5"].start}
          subtitlesFile="./subtitles/4.vtt"
          end={videoSplit["4"]["5"].end}
          src={assets.video4}
          play={false}
        />
      )}
      <Title textContent="SECURING COMMITMENT" />
      {step === 0 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-0.png"]}
          subtitle="During Securing Commitment:"
          descriptionImage={assets[`./content/autogenerated/shared/securing-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-1.jpeg"]}
          descriptionImage={assets[`./content/autogenerated/shared/securing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["4"]["5"].start}
          subtitlesFile="./subtitles/4.vtt"
          end={videoSplit["4"]["5"].end}
          src={assets.video4}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Securing Commitment`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-3.png"]}
          description={`Sindy validated that the advertiser is aligned on the approach. This is important to ensure that time together on the next call is well spent.\n\n   Sindy did well to offer a proactive walkthrough! Sindy asked “Do you want me to walk you through it now?” when they offered this to the advertiser.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Discovery`}
          image={assets["./content/autogenerated/script4/securing-question.png"]}
          imageCorrect={assets["./content/autogenerated/script4/securing-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/script4/securing-incorrect.png"]}
          descriptionCorrect="Sharing additional context about what the walkthrough would entail, how long it might take, and why the advertiser might benefit from implementing it on the call with Sindy instead of on her own, later, could have been more convincing."
          question={`What other details could they have added to present this option to the advertiser more effectively?`}
          answers={[
            { answer: `The kind of actions or steps that would be part of the walkthrough`, correct: false },
            {
              answer: `An estimate for how long the walkthrough would take`,
              correct: false,
            },
            {
              answer: `What the advertiser would gain by agreeing to the walkthrough`,
              correct: false,
            },
            {
              answer: `All of the above`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/call-closing`);
          }}
        />
      )}
    </>
  );
}
