import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import CardSummary from "@/components/card-summary";

export default function Module8CallClosing() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!)
  }, [step])
  const navigate = useNavigate()


  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
        rotation={[0,-110,0]}
          start={videoSplit["5"]["7"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["7"].end}
        src={assets.video5}
          play={false}
        />
      )}
      <Title textContent="CALL CLOSING" />
      {step === 0 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          subtitle="During the call closing:"
          image={assets["./content/autogenerated/script1/call-closing-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/call-closing-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Plan for \nthe next call`}

          image={assets["./content/autogenerated/script1/call-closing-step-1.jpeg"]}
          descriptionImage={assets[`./content/autogenerated/shared/call-closing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
                rotation={[0,-110,0]}
          start={videoSplit["5"]["7"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["7"].end}
                src={assets.video5}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Call Closing`}
          image={assets["./content/autogenerated/new-images/5-7-3.jpg"]}
          description={`Excellent! Sindy summarized the key takeaways, validated that the advertiser had no unanswered questions and set expectations of what will be discussed on the next call.\n\n   Sindy could have repeated the advertiser actions one more time and checked in again to ensure they were clear on what to do before the next call.`}
          buttons={[
            <ArrowButton
              dir="next"
              onDown={() => {
                setStep(4);
              }}
            />,
          ]}
        />
      )}
      {step === 4 && (
        <CardSummary
          onPrev={() => {
            setStep(3);
          }}
          onFinish={() => {
            const state = appState.getState();
            state.endSession(params.moduleId!);
            state.reset();
            state.completeModule("5");
            navigate("/?module-selection=true");
          }}
        />
      )}
    </>
  );
}
