import React from "react";
import { Layer } from "react-xr-ui";
import FadeIn from "@/components/fade-in";
import Frame from "@/components/frame";
import assets from "@/config/assets";
import Button from "../../components/button";
import { appState } from "@/state/app-state";
import ArrowButton from "@/components/arrow-button";

export default function CardModuleMenu3() {
  const setStep = appState((state) => state.setMenuStep);

  return (
    <Frame>
      <Layer
        height="100%"
        aspectRatio={1200 / 700}
        backgroundImage={assets["./content/autogenerated/module-menu-5-bg.png"]}
        backgroundSize="contain"
        backgroundColor="rgba(255, 255, 255, 0.95)"
        autoLayout={false}
      ></Layer>
      <FadeIn delay={1000}>
        <Layer
          height="100%"
          aspectRatio={1200 / 700}
          backgroundImage={assets["./content/autogenerated/module-menu-4-1.png"]}
          backgroundSize="contain"
          autoLayout={false}
        ></Layer>
      </FadeIn>
      <FadeIn delay={2000}>
        <Layer
          height="100%"
          aspectRatio={1200 / 700}
          backgroundImage={assets["./content/autogenerated/module-menu-4-2.png"]}
          backgroundSize="contain"
          autoLayout={false}
        ></Layer>
      </FadeIn>
      <Layer autoLayout={false} width="83.5%" height="100%" alignItems="end" justifyContent="end">
        <Layer width="100%" height="8.5%" justifyContent="space-between" alignItems="end" gap={0.01}>
          <ArrowButton
            onDown={() => {
              setStep(2.5);
            }}
            dir="prev"
          />
          <Button
            text="BEGIN"
            onDown={() => {
              setStep(4);
            }}
            height="100%"
            aspectRatio={202 / 60}
            fontSize="30%"
          />
        </Layer>
      </Layer>
    </Frame>
  );
}
