import React from "react";
import assets from "@/config/assets";
import Card from "@/components/card";
import StartButton from "@/components/start-button";
import { useNavigate } from "react-router-dom";
import ArrowButton from "@/components/arrow-button";

export default function Module8Home() {
  const navigate = useNavigate();

  return (
    <>
      <Card
        title={`Overcoming Business Integrity\nBlockers`}
        image={assets["./content/autogenerated/script5/home.png"]}
        description={`This advertiser has a support ticket in the queue and has been having issues with getting impressions on his recent ads. He’s not seeing the impressive “reach” he’s heard so much about. He’s frustrated and is looking for answers, as well as someone to vent to.`}
        buttons={[
          <ArrowButton
            onDown={() => {
              navigate(`/?module-selection=true`);
            }}
            dir="prev"
          />,
          <StartButton moduleId="5" />,
        ]}
      />
    </>
  );
}
