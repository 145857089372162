import React, { Suspense } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import Title from "@/components/title";
import CardModuleMenu from "@/pages/menu/menu-0";
import { appState } from "@/state/app-state";
import CardModuleMenu1 from "./menu/menu-1";
import CardModuleMenu2 from "./menu/menu-2";
import CardModuleMenu25 from "./menu/menu-2-5";
import CardModuleMenu3 from "./menu/menu-3";
import SphereImage from "@/components/sphere-image";
import assets from "@/config/assets";
import Menu3D from "@/components/menu3d";
import Module1Home from "./1/module-1-home";
import Module2Home from "./2/module-2-home";
import Module3Home from "./3/module-3-home";
import Module4Home from "./4/module-4-home";
import Module5Home from "./5/module-5-home";
import Module6Home from "./6/module-6-home";
import Module7Home from "./7/module-7-home";
import Module8Home from "./8/module-8-home";
import CardModuleMenuComplete from "./menu/menu-complete";
import { IS_SINGLE_MODULE } from "@/config/consts";
import Redirect from "@/components/redirect";
import { useXR } from "@react-three/xr";

export default function ModuleSelection() {
  const step = appState((state) => state.menuStep);
  const [searchParams] = useSearchParams();
  const completedModuleIds = appState((state) => state.completedModuleIds);
  const [repeat, setRepeat] = useLocalStorage("repeat", false);
  const location = useLocation();

  React.useEffect(() => {
    if (completedModuleIds.length >= 8) {
      if (!repeat) {
        appState.getState().setMenuStep(5);
      } else {
        appState.getState().setMenuStep(4);
      }
    }
  }, [location]);

  React.useEffect(() => {
    if (repeat) {
      appState.getState().setMenuStep(4);
    }
  }, [repeat]);

  React.useEffect(() => {
    setRepeat(false);
  }, []);

  if (IS_SINGLE_MODULE) {
    if (searchParams.get("module-selection") || step === 4 || searchParams.get("module-selection") !== null) {
      return <Redirect to={`/${process.env.MODULE_ID}`} />;
    }
    return (
      <>
        <SphereImage src={assets["./content/autogenerated/main-room.jpg"]} />
        {step === 0 && <CardModuleMenu />}
        {step === 1 && <CardModuleMenu1 />}
        {step === 2 && <CardModuleMenu2 />}
        {step === 2.5 && <CardModuleMenu25 />}
        {step === 3 && <CardModuleMenu3 />}
      </>
    );
  }

  return (
    <>
      {(step === 4 || searchParams.get("module-selection") !== null) && <Modules />}
      {!searchParams.get("module-selection") && (
        <>
          <SphereImage src={assets["./content/autogenerated/main-room.jpg"]} />
          {step === 0 && <CardModuleMenu />}
          {step === 1 && <CardModuleMenu1 />}
          {step === 2 && <CardModuleMenu2 />}
          {step === 2.5 && <CardModuleMenu25 />}
          {step === 3 && <CardModuleMenu3 />}
        </>
      )}
      {step === 5 && <CardModuleMenuComplete />}
    </>
  );
}

type Modules = {
  [id: string]: { component: React.JSX.Element };
};

const MODULES: Modules = {
  "1": {
    component: <Module1Home />,
  },
  "2": {
    component: <Module2Home />,
  },
  "3": {
    component: <Module3Home />,
  },
  "4": {
    component: <Module4Home />,
  },
  "5": {
    component: <Module5Home />,
  },
  "6": {
    component: <Module6Home />,
  },
  "7": {
    component: <Module7Home />,
  },
  "8": {
    component: <Module8Home />,
  },
};

const Modules = () => {
  const [searchParams] = useSearchParams(new URLSearchParams(window.location.search));
  const moduleId = searchParams.get("module-selection");
  const isPresenting = useXR((state) => state.isPresenting);
  const position = React.useMemo(() => {
    if (isPresenting) {
      return [2.5, -0.8, 1.2] as const;
    }
    return [-0.125, -2, 0] as const;
  }, [isPresenting]);

  return (
    <>
      <Title textContent="Module Selection" />
      <group position-y={isPresenting ? 1 : 0} position-z={isPresenting ? 0.6 : 2} scale={0.6}>
        {moduleId && moduleId !== "true" && moduleId !== "" && MODULES[moduleId as string].component}
      </group>
      <SphereImage src={assets["./content/autogenerated/main-room.jpg"]}>
        <Suspense fallback={<></>}>
          <group rotation={[0, -2, 0]} position={position} scale-x={-1}>
            <Menu3D />
          </group>
        </Suspense>
      </SphereImage>
    </>
  );
};
