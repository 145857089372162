import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import { appState } from "@/state/app-state";

export default function Module8CallOpening() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const [videoSrc, setVideoSrc] = React.useState(assets.video6);

  return (
    <>
      {![0, 3].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["6"]["1"].start}
          subtitlesFile="./subtitles/6.vtt"
          end={videoSplit["6"]["1"].end}
          src={videoSrc}
          play={false}
        />
      )}
      <Title textContent="CALL OPENING" />
      {step === 0 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["6"]["1"].start}
          subtitlesFile="./subtitles/6.vtt"
          end={videoSplit["6"]["1"].end}
          src={assets.video6}
          onEnded={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Showing \nup prepared`}
          subtitle="In the call opening: "
          image={assets["./content/autogenerated/new-images-2/6-2-2.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/call-opening-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(2)} />, <ArrowButton dir="next" onDown={() => setStep(3)} />]}
        />
      )}
      {step === 2 && (
        <CardLarge
          title={`Showing \nup prepared`}
          image={assets["./content/autogenerated/script2/call-opening-step-2.jpeg"]}
          subtitle="Examples:"
          descriptionImage={assets["./content/autogenerated/shared/call-opening-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(3)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 3 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["6"]["2"].start}
          subtitlesFile="./subtitles/6.vtt"
          end={videoSplit["6"]["2"].end}
          src={assets.video6}
          onEnded={() => setStep(4)}
        />
      )}
      {step === 4 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Call Opening"
          image={assets["./content/autogenerated/new-images/6-2-4.jpg"]}
          description={`The reason Sindy was calling was clear from the outset. There were also some very clear signals that they’d prepared for the call. Sindy successfully positioned the "hook" of scaling the business, lowering CPAs and driving profitability.\n\n   This is important because it creates a sense of urgency and importance for the advertiser, which helps them prioritize time for the call. Well done!`}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/discovery`)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
    </>
  );
}
