import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8Discovery() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["3"]["3"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["3"].end}
          src={assets.video3}
          play={false}
        />
      )}
      <Title textContent="DISCOVERY" />
      {step === 0 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          subtitle="During Discovery: "
          image={assets["./content/autogenerated/new-images-2/3-3-1.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Seek to \nunderstand`}

          image={assets["./content/autogenerated/script2/discovery-step-1.jpeg"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["3"]["3"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["3"].end}
          src={assets.video3}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Discovery & Connection"
          image={assets["./content/autogenerated/script2/discovery-step-3.png"]}
          description={`Sindy could have asked more structured and relevant questions to deepen their understanding of the advertiser’s business challenges and priorities.\n\n   Spending more time on Discovery gives the advertiser a chance to confirm for the MP (and for themselves) what’s most important. Getting this buy-in increases the likelihood of them actioning on the solution that is eventually recommended.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Discovery`}
          image={assets["./content/autogenerated/new-images-2/3-3-question.png"]}
          imageCorrect={assets["./content/autogenerated/script2/discovery-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/script3/discovery-incorrect.png"]}
          descriptionCorrect="It’s important to provide context for questions to gain buy-in, especially when the advertiser has confirmed that time is limited."
          questionFontSize={1 / 4.9}
          question={`Sindy only asked two discovery questions; How satisfied are you with your campaigns? And Have you tried Lead Ads? How could Sindy have improved their questions?`}
          answers={[
            {
              answer: `Sindy could have asked more closed questions, because the advertiser was so short on time.`,
              correct: false,
            },
            {
              answer: `Sindy could have provided context for why they wanted to know the answers to their questions.`,
              correct: true,
            },
            {
              answer: `Sindy could have used more technical terms to impress the advertiser and sound like more of an expert.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/commercial-conversations`);
          }}
        />
      )}
    </>
  );
}
