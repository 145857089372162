import React from "react";
import { Layer } from "react-xr-ui";
import FadeIn from "@/components/fade-in";
import Frame from "@/components/frame";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import ArrowButton from "@/components/arrow-button";

export default function CardModuleMenu1() {
  const step = appState((state) => state.menuStep);
  const setStep = appState((state) => state.setMenuStep);

  return (
    <Frame>
      <Layer
        height="100%"
        aspectRatio={1200 / 700}
        backgroundImage={assets["./content/autogenerated/module-menu-3-bg.png"]}
        backgroundSize="contain"
        backgroundColor="rgba(255, 255, 255, 0.95)"
        autoLayout={false}
      >
        <Layer autoLayout={false} width="100%" height="100%" alignItems="end" justifyContent="end">
          <Layer width="100%" height="8.5%" justifyContent="space-between" gap={0.01}>
            <ArrowButton
              onDown={() => {
                setStep(1);
              }}
              dir="prev"
            />
            <ArrowButton
              onDown={() => {
                setStep(2.5);
              }}
              dir="next"
            />
          </Layer>
        </Layer>
      </Layer>
      <FadeIn delay={1000}>
        <Layer
          height="100%"
          aspectRatio={1200 / 700}
          backgroundImage={assets["./content/autogenerated/module-menu-3-1.svg"]}
          backgroundSize="contain"
          autoLayout={false}
        ></Layer>
      </FadeIn>
      <FadeIn delay={2000}>
        <Layer
          height="100%"
          aspectRatio={1200 / 700}
          backgroundImage={assets["./content/autogenerated/module-menu-3-2.png"]}
          backgroundSize="contain"
          autoLayout={false}
        ></Layer>
      </FadeIn>
    </Frame>
  );
}
