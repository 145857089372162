import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import { appState } from "@/state/app-state";

export default function Module8CallOpening() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const [videoSrc, setVideoSrc] = React.useState(assets.video5);

  return (
    <>
      {![0, 3].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["5"]["1"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["1"].end}
          src={videoSrc}
          play={false}
        />
      )}
      <Title textContent="CALL OPENING" />
      {step === 0 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["5"]["1"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["1"].end}
          src={assets.video5}
          onEnded={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Showing \nup prepared`}
          subtitle="In the call opening: "
          image={assets["./content/autogenerated/new-images/5-2-1.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/call-opening-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(2)} />, <ArrowButton dir="next" onDown={() => setStep(3)} />]}
        />
      )}
      {step === 2 && (
        <CardLarge
          title={`Showing \nup prepared`}
          image={assets["./content/autogenerated/script2/call-opening-step-2.jpeg"]}
          subtitle="Examples:"
          descriptionImage={assets["./content/autogenerated/shared/call-opening-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(3)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 3 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["5"]["2"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["2"].end}
          src={assets.video5}
          onEnded={() => setStep(4)}
        />
      )}
      {step === 4 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Call Opening"
          image={assets["./content/autogenerated/script2/call-opening-step-4.png"]}
          description={`Sindy did a great job of acknowledging this advertiser’s frustration and reassuring them that they are committed to helping resolve the issues.\n\n   Sindy did not yet communicate much existing knowledge of the advertiser's business, and they didn’t provide any information about the agenda. In this case, those items are covered a little later in the call. The most urgent need was to address the advertiser’s frustrations. `}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/discovery`)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
    </>
  );
}
