import React from "react";
import assets from "@/config/assets";
import Card from "@/components/card";
import StartButton from "@/components/start-button";
import { useNavigate } from "react-router-dom";
import ArrowButton from "@/components/arrow-button";

export default function Module8Home() {
  const navigate = useNavigate();

  return (
    <>
      <Card
        title={`Delivering Tailored Solutions\n`}
        image={assets["./content/autogenerated/script4/home.png"]}
        description={`This is the third call with an advertiser that had initially been hesitant to adopt a messaging strategy because they were worried about a high CPA. They saw success with more appointments booked via messages. The Marketing Pro was able to build trust and credibility by helping with the messaging campaign.\n\n   During their last call, the client mentioned she wanted to start running some sales campaigns for a new line of nail polish she's selling on her website.  After looking at the client’s ad setup, Sindy wants to pitch Structure for Scale to simplify ads and amplify Advantage+ Placements.`}
        descriptionFontSize={1 / 20}
        contentAlignItems="start"
        descriptionHeight="140%"
        buttons={[
          <ArrowButton
            onDown={() => {
              navigate(`/?module-selection=true`);
            }}
            dir="prev"
          />,
          <StartButton moduleId="4" />,
        ]}
      />
    </>
  );
}
