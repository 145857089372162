import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import CardSummary from "@/components/card-summary";

export default function Module8CallClosing() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["7"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["7"].end}
          src={assets.video7}
          play={false}
        />
      )}
      <Title textContent="CALL CLOSING" />
      {step === 0 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          subtitle="During the call closing:"
          image={assets["./content/autogenerated/script1/call-closing-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/call-closing-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          image={assets["./content/autogenerated/script1/call-closing-step-1.jpeg"]}
          descriptionImage={assets[`./content/autogenerated/shared/call-closing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["7"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["7"].end}
          src={assets.video7}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Call Closing`}
          image={assets["./content/autogenerated/script1/call-closing-step-3.png"]}
          description={`Simon reviewed the key takeaways from the discussion and checked to make sure the advertiser had no further questions. Then they confirmed their own next steps and shared how the advertiser can get in touch if they need to.\n\n   Simon could have reiterated what they would talk about in a subsequent call, if given the chance, to help the client decide if it’s worth giving the Meta Marketing Pro team another chance!`}
          buttons={[
            <ArrowButton
              dir="next"
              onDown={() => {
                setStep(4);
              }}
            />,
          ]}
        />
      )}
      {step === 4 && (
        <CardSummary
          onPrev={() => {
            setStep(3);
          }}
          onFinish={() => {
            const state = appState.getState();
            state.endSession(params.moduleId!);
            state.reset();
            state.completeModule("7");
            navigate("/?module-selection=true");
          }}
        />
      )}
    </>
  );
}
