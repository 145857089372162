import React from "react";
import { Layer, LayerRef } from "react-xr-ui";
import ButtonInteraction from "@/components/button-interaction";

type Props = {
  onDown?: () => void;
  enabled?: boolean;
};

export default function ExamplesButton({ onDown, enabled = true }: Props) {
  const layerRef = React.useRef<LayerRef>(null);

  return (
    <Layer ref={layerRef} opacity={enabled ? 1 : 0} height={0.195} aspectRatio={264 / 58} backgroundColor="#1877F2">
      <Layer width="76.13636363636364" height="39.6551724137931%">
        <Layer
          width="100%"
          height="100%"
          textContent="EXAMPLES"
          fontSize={1}
          color="#FFFFFF"
          textAlign="center"
          position-y={-0.005}
        />
      </Layer>
      <ButtonInteraction
        enabled={enabled}
        onOver={() => {
          if (layerRef.current === null) return;
          layerRef.current.setAttrs((attrs) => {
            return { ...attrs, backgroundColor: "#1C2B33" };
          });
        }}
        onOut={() => {
          if (layerRef.current === null) return;
          layerRef.current.setAttrs((attrs) => {
            return { ...attrs, backgroundColor: "#1877F2" };
          });
        }}
        onDown={onDown}
      >
        <Layer autoLayout={false} width="100%" height="100%" />
      </ButtonInteraction>
    </Layer>
  );
}
