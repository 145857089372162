import React from "react";
import { Layer, LayerProps } from "react-xr-ui";
import SlideUp from "@/components/slide-up";
import FadeIn from "@/components/fade-in";
import Frame from "@/components/frame";
import { STACKED_TRANSITION_DELAY } from "@/config/consts";

type Props = {
  title: string;
  subtitle?: string;
  image: string;
  description?: string;
  descriptionFontSize?: number | `${number}px` | undefined;
  descriptionImage?: string;
  descriptionWidth?: number | `${number}%` | undefined;
  descriptionHeight?: number | `${number}%` | undefined;
  buttons: React.ReactNode[];
  buttonsJustifyContent?: LayerProps["justifyContent"];
  direction?: "ltr" | "rtl";
};

export default function CardLarge({
  title,
  image,
  subtitle,
  description = '',
  descriptionFontSize = "35px",
  buttons,
  buttonsJustifyContent = "end",
  direction = "ltr",
  descriptionImage,
  descriptionWidth = "100%",
  descriptionHeight,
}: Props) {
  const titleLines = React.useMemo(() => {
    return title.split("\n").length;
  }, [title]);

  const [descriptionBackgroundColor, setDescriptionBackgroundColor] = React.useState<"white" | undefined>(undefined);

  return (
    <Frame>
      <Layer height="100%" aspectRatio={1200 / 700}>
        <Layer
          width="100%"
          height="100%"
          backgroundColor="#ffffff"
          zIndex={-3}
          justifyContent="space-around"
          flexDirection={direction === "rtl" ? "row-reverse" : "row"}
        >
          <Layer
            width="47.91666666666667%"
            height="100%"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
          >
            <Layer width="100%" height="8.285714285714285%" />
            <SlideUp delay={STACKED_TRANSITION_DELAY * (direction === "rtl" ? 1 : 0)} isVisible={false}>
              <Layer
                width="100%"
                height={`${8.571428571428571 * titleLines}%`}
                fontSize={.98 / titleLines}
                fontWeight="800"
                lineHeight={1}
                textContent={title}
                color="#1C2B33"
                verticalAlign="bottom"
              />
            </SlideUp>
            {!descriptionImage && subtitle !== undefined && <Layer width="100%" height="4.285714285714286%" />}
            {!descriptionImage && subtitle !== undefined && (
              <SlideUp delay={STACKED_TRANSITION_DELAY * 2} isVisible={false}>
                <Layer
                  width="100%"
                  height="5%"
                  fontWeight="700"
                  textContent={subtitle}
                  fontSize={1 / 1.5}
                  color="#000000"
                />
              </SlideUp>
            )}
            <Layer width="100%" height="4.285714285714286%" />
            <SlideUp onFinish={() => {
              setDescriptionBackgroundColor("white");
            }} delay={STACKED_TRANSITION_DELAY * 2} isVisible={false}>
              <Layer
                width={descriptionWidth}
                height={descriptionHeight || ((subtitle !== undefined) ? "58.285714285714285%" : "67.57142857142857%")}
                textContent={description}
                fontSize={descriptionFontSize}
                backgroundSize="contain"
                backgroundColor={descriptionBackgroundColor}
                backgroundImage={descriptionImage}
                zIndex={-1}
                lineHeight={1.65}
                fontWeight="300"
                color="#000000"
              />
            </SlideUp>
          </Layer>
          <SlideUp delay={STACKED_TRANSITION_DELAY * (direction === "rtl" ? 0 : 3)} isVisible={false}>
            <Layer
              width="32.5%"
              height="100%"
              backgroundSize="cover"
              backgroundPosition={[0.5, 0.5]}
              backgroundImage={image}
            />
          </SlideUp>
        </Layer>
        <Layer autoLayout={false} width="100%" height="100%" alignItems="end" justifyContent="end">
          <FadeIn delay={STACKED_TRANSITION_DELAY * 4}>
            <Layer width="100%" height="8.5%" justifyContent={buttonsJustifyContent} gap={0.05}>
              {buttons.map((button, index) => {
                return <React.Fragment key={index}>{button}</React.Fragment>;
              })}
            </Layer>
          </FadeIn>
        </Layer>
      </Layer>
    </Frame>
  );
}
