import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import CardSummary from "@/components/card-summary";

export default function Module8CallClosing() {
  const [step, setStep] = React.useState(2);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          start={videoSplit["1"]["7"].start}
          subtitlesFile="./subtitles/1.vtt"
          end={videoSplit["1"]["7"].end}
          src={assets.video1}
          play={false}
        />
      )}
      <Title textContent="CALL CLOSING" />
      {step === 0 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          subtitle="During the call closing:"
          image={assets["./content/autogenerated/new-images/1-6-1.jpg"]}
          descriptionImage={assets[`./content/autogenerated/shared/call-closing-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          image={assets["./content/autogenerated/script1/call-closing-step-1.jpeg"]}
          descriptionImage={assets[`./content/autogenerated/shared/call-closing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          start={videoSplit["1"]["7"].start}
          subtitlesFile="./subtitles/1.vtt"
          end={videoSplit["1"]["7"].end}
          src={assets.video1}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Call Closing`}
          image={assets["./content/autogenerated/script1/call-closing-step-3.png"]}
          description={`Simon thoroughly summarized the key takeaways and checked that the advertiser had no unanswered questions.\n\n   They also outlined the follow-up actions and set expectations of what will be discussed on the next call. Well done!`}
          buttons={[
            <ArrowButton
              dir="next"
              onDown={() => {
                setStep(4);
              }}
            />,
          ]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Call Closing`}
          image={assets[`./content/autogenerated/script1/call-closing-step-4.png`]}
          imageCorrect={assets["./content/autogenerated/script1/call-closing-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/script1/call-closing-incorrect.png"]}
          descriptionCorrect="Sharing more specifics about what business challenges you plan to tackle together on the next call is a great way to keep advertisers engaged."
          question={`How could Simon have driven more excitement and interest for the next call?`}
          answers={[
            {
              answer: `They could share more detail on what they mean by “look into building sales campaigns.”`,
              correct: true,
            },
            {
              answer: `They could ask the advertiser for a backup option in case the advertiser is unable to meet the scheduled time.`,
              correct: false,
            },
            {
              answer: `They could elaborate on what they mean by “eager to improve the experience” when describing the survey.`,
              correct: false,
            },
          ]}
          onAnswer={() => {
            setStep(5);
          }}
        />
      )}
      {step === 5 && (
        <CardSummary
          onPrev={() => {
            setStep(4);
          }}
          onFinish={() => {
            const state = appState.getState();
            state.endSession(params.moduleId!);
            state.reset();
            state.completeModule("1");
            navigate("/?module-selection=true");
          }}
        />
      )}
    </>
  );
}
