import { chunk } from "lodash";
import type { Immerse, ParsedSubtitle, Session } from "@/types";
import { appState } from "@/state/app-state";
import assets from "@/config/assets";

export async function isXrModeSupported(mode: XRSessionMode): Promise<boolean> {
  try {
    if (navigator.xr === undefined) {
      return false;
    }
    return await navigator.xr!.isSessionSupported(mode);
  } catch (err) {
    console.error(err);
    return false;
  }
}

export function getSignedVideos(session: Session) {
  return Array.from(Array(8)).map((_, index) => {
    return session.getRoute(`${index + 1}.mp4`);
  }) as string[];
}

// Preload assets to they are cached by the service worker for offline access
export async function preload() {
  const chunks = chunk(Object.entries(assets), 5);
  // Skip preloading of mp4 videos to save on memory usage
  for (const assets of chunks) {
    await Promise.all(
      assets.map(([, url]) => {
        if (url.endsWith(".mp4")) return;
        return fetch(url);
      })
    );
  }
}

export async function startSession(): Promise<{ session: Session; immerse: Immerse }> {
  const url = new URL(location.href);
  const token = url.searchParams.get("immerse-token");
  if (token === null) {
    throw "No immerse token found in query params";
  }
  const projectId = "648af5f975d184812b269baa";
  // @ts-ignore
  const { immerse } = await import("@/immerse-sdk.js");
  const session = await immerse.newSession(projectId, token, {
    sessionName: "User",
    host: "https://api-staging.immerse.io",
  });
  const signedVideos = getSignedVideos(session);
  signedVideos.forEach((signedVideo, index) => {
    const key = `video${index + 1}`;
    assets[key as keyof typeof assets] = signedVideo;
  });
  return { session, immerse };
}

export function endSession(moduleId: string) {
  const state = appState.getState();
  const answers = state.answers[moduleId];
  if (state.session === null || state.immerse === null) return;
  const totalAnswers = Object.keys(answers).length;
  const correctAnswersTotal = Object.entries(answers).filter(([scene, answer]) => answer?.correct === true).length;
  state.session.complete(state.immerse.verbs.completed, {
    completion: true,
    success: true,
    score: {
      min: 0,
      max: totalAnswers,
      raw: correctAnswersTotal,
    },
  });
}

export const calcLayerHeight = (childSize: number, parentSize: number) => {
  return `${(childSize / parentSize) * 100}%`;
};

export function parseSubtitles(subtitles: string) {
  const lines = subtitles.split(/\n/);
  const parsed: ParsedSubtitle[] = [];
  let currentChunk: ParsedSubtitle | null = null;
  const timestampToSeconds = (timestamp: string) => {
    const parts = timestamp.split(":");
    const minutes = parseInt(parts[0]);
    const seconds = parseFloat(parts[1]);
    return minutes * 60 + seconds;
  };
  for (const line of lines) {
    if (line.includes("-->")) {
      const [from, to] = line.split("-->").map((text) => text.trim());
      currentChunk = {
        from: timestampToSeconds(from),
        to: timestampToSeconds(to),
        lines: [],
      };
    }
    if (line.startsWith("-") && currentChunk !== null) {
      currentChunk.lines.push(line.split("-").join("").trim());
    }
    if (line.trim().length === 0 && currentChunk !== null) {
      parsed.push(currentChunk as ParsedSubtitle);
      currentChunk = null;
    }
  }
  return parsed;
}
