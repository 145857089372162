import React from "react";
import assets from "@/config/assets";
import SphereImage from "@/components/sphere-image";
import { Layer, LayerRef } from "react-xr-ui";
import Frame from "@/components/frame";
import { startSession } from "@react-three/xr";
import { appState } from "@/state/app-state";
import ButtonInteraction from "@/components/button-interaction";

export default function Splash() {
  const buttonRef = React.useRef<LayerRef>(null);

  const overButtonRef = React.useRef(false);

  const [error, setError] = React.useState("");

  React.useEffect(() => {
    async function onClick() {
      if (!overButtonRef.current) return;
      try {
        await startSession("immersive-vr", {
          // requiredFeatures: ["layers"],
          optionalFeatures: ["hit-test", "dom-overlay", "dom-overlay-for-handheld-ar"],
        });
        appState.getState().setInteracted();
        await appState.getState().startSession();
      } catch (err) {
        console.error(err);
        setError((err as Error).message);
      }
    }

    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <>
      <SphereImage src={assets["./content/autogenerated/main-room.jpg"]} />
      <Frame>
        <Layer
          width="100%"
          height="100%"
          backgroundColor="white"
          flexDirection="column"
          gap={0.2}
          alignItems="center"
          justifyContent="center"
        >
          {error !== "" && (
            <Layer
              width="55%"
              height={0.3}
              fontSize="85px"
              color="crimson"
              textContent={error}
              textAlign="center"
              verticalAlign="top"
            />
          )}
          <ButtonInteraction
            onOver={() => {
              overButtonRef.current = true;
              if (buttonRef.current === null) return;
              buttonRef.current.setAttrs((attrs) => {
                return { ...attrs, backgroundColor: "#1C2B33" };
              });
            }}
            onOut={() => {
              overButtonRef.current = false;
              if (buttonRef.current === null) return;
              buttonRef.current.setAttrs((attrs) => {
                return { ...attrs, backgroundColor: "#1877F2" };
              });
            }}
          >
            <Layer
              ref={buttonRef}
              width={1}
              aspectRatio={1 / 2.5}
              backgroundColor="#1877F2"
              color="white"
              textContent="Enter VR"
              fontSize="75px"
              textAlign="center"
              verticalAlign="middle"
            />
          </ButtonInteraction>
        </Layer>
      </Frame>
    </>
  );
}
