import React from "react";
import { Interaction, Layer } from "react-xr-ui";
import { useXR } from "@react-three/xr";
import assets from "@/config/assets";
import Static from "@/components/static";
import { useThree } from "@react-three/fiber";
import ButtonInteraction from "./button-interaction";
import { useNavigate, useParams } from "react-router-dom";
import scenes from "@/config/scenes";
import { appState } from "@/state/app-state";
import { VR_OFFSET_Y, WEB_OFFSET_Y } from "@/config/consts";

type Props = {
  current: number;
  total: number;
  height?: number;
};

export default function Progress({ current, total, height = 0.35 }: Props) {
  const viewport = useThree((state) => state.viewport);
  const navigate = useNavigate();
  const { moduleId, sceneId } = useParams();
  const progress = appState((state) => state.progress);

  const isPresenting = useXR((state) => state.isPresenting);
  const offsetY = React.useMemo(() => {
    return isPresenting ? VR_OFFSET_Y : WEB_OFFSET_Y;
  }, [isPresenting]);

  const items = React.useMemo(() => {
    return Array.from(Array(total));
  }, [total]);

  const getScene = (newScene: string) => {
    const chosenScene = scenes[moduleId as "1"][newScene as "1"];
    return chosenScene!.sceneId;
  };

  const subtitlesEnabled = appState((state) => state.subtitlesEnabled);

  const [homeHover, setHomeHover] = React.useState(false);
  const [subtitlesHover, setSubtitlesHover] = React.useState(false);

  const homeNavTimeoutRef = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    return () => {
      clearTimeout(homeNavTimeoutRef.current);
    };
  }, []);

  return (
    <Static>
      <Layer
        position-y={(-viewport.height / 2.08 + height / 2) * offsetY}
        height={height}
        aspectRatio={433 / 90}
        autoLayout={false}
        backgroundColor="rgba(217, 217, 217, 0.5)"
        borderRadius={0.2}
      >
        <Layer autoLayout={false} width="100%" height="100%" justifyContent="start">
          <Layer
            backgroundColor="rgba(217, 217, 217, 0.5)"
            height="100%"
            aspectRatio={1}
            borderRadius={0.2}
            position={[(90 / 433) * -2, 0, 0]}
          >
            <Interaction
              onOver={() => {
                setHomeHover(true);
                document.body.style.cursor = "pointer";
              }}
              onOut={() => {
                setHomeHover(false);
                document.body.style.cursor = "auto";
              }}
              onDown={() => {
                document.body.style.cursor = "auto";
                appState.getState().setMenuStep(0);
                homeNavTimeoutRef.current = setTimeout(() => {
                  navigate("/");
                }, 100);
              }}
            >
              <Layer
                backgroundSize="contain"
                backgroundPosition={[0.5, 0.5]}
                backgroundImage={assets[`./content/autogenerated/home-${homeHover ? "active" : "inactive"}.png`]}
                height="70%"
                aspectRatio={1}
              />
            </Interaction>
          </Layer>
        </Layer>
        <Layer width="100%" height="100%" justifyContent="space-around" position-y={0.02}>
          {items.map((item, index) => {
            const active = index === current - 1;
            try {
              var complete = progress[moduleId as "1"][getScene(index + 1 + "")];
            } catch {
              var complete = false;
            }
            return (
              <ButtonInteraction
                disableCursor={(() => {
                  try {
                    const sceneId = getScene(index + 1 + "");
                    var sceneCompleted = progress[moduleId as "1"][sceneId];
                  } catch {
                    var sceneCompleted = false;
                  }

                  if (!sceneCompleted) return true;
                  return false;
                })()}
                key={index}
                onDown={() => {
                  try {
                    const sceneId = getScene(index + 1 + "");
                    var sceneCompleted = progress[moduleId as "1"][sceneId];
                  } catch {
                    var sceneCompleted = false;
                  }

                  if (!sceneCompleted) return;
                  navigate(`/${moduleId}/${getScene((index + 1).toString())}`);
                }}
              >
                <Layer
                  height="35.55555555555556%"
                  aspectRatio={1}
                  borderRadius={0.45}
                  borderWidth={0.125}
                  borderColor={active || (!active && complete) ? "#1877F2" : "#D1D5DB"}
                  backgroundColor={!active && complete ? "#1877F2" : undefined}
                >
                  {active && <Layer height="31.25%" aspectRatio={1} backgroundColor="#1877F2" borderRadius={0.45} />}
                  {complete && !active && (
                    <Layer
                      height="50%"
                      aspectRatio={1}
                      backgroundImage={assets.tick}
                      backgroundSize="contain"
                      backgroundPosition={[0.5, 0.5]}
                    />
                  )}
                  <Layer
                    autoLayout={false}
                    textContent={`${index + 1}`}
                    fontSize={0.85}
                    height="62.5%"
                    width="100%"
                    color="#000000"
                    textAlign="center"
                    verticalAlign="middle"
                    position-y={-0.125}
                  />
                </Layer>
              </ButtonInteraction>
            );
          })}
        </Layer>
        <Layer autoLayout={false} width="100%" height="100%" justifyContent="end">
          <Layer
            backgroundColor="rgba(217, 217, 217, 0.5)"
            height="100%"
            aspectRatio={1}
            borderRadius={0.2}
            position={[(90 / 433) * 2, 0, 0]}
          >
            <Interaction
              onOver={() => {
                setSubtitlesHover(true);
                document.body.style.cursor = "pointer";
              }}
              onOut={() => {
                setSubtitlesHover(false);
                document.body.style.cursor = "auto";
              }}
              onDown={() => {
                document.body.style.cursor = "auto";
                appState.getState().toggleSubtitlesEnabled();
              }}
            >
              <Layer
                backgroundSize="contain"
                backgroundPosition={[0.5, 0.5]}
                backgroundImage={
                  assets[`./content/autogenerated/cc-${subtitlesEnabled || subtitlesHover ? "active" : "inactive"}.png`]
                }
                height="70%"
                aspectRatio={1}
              />
            </Interaction>
          </Layer>
        </Layer>
      </Layer>
    </Static>
  );
}
