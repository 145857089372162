import React from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { Controllers, useXR } from "@react-three/xr";
import Background from "@/components/background";
import Camera from "@/components/camera";
import ModuleSelection from "@/pages/module-selection";
import Scene from "@/pages/scene";
import { Layer } from "react-xr-ui";
import Static from "./static";
import emitter from "@/services/emitter";
import { isXrModeSupported } from "@/utils";
import Splash from "@/components/splash";

export default function App() {
  const location = useLocation();
  const [searchParms] = useSearchParams(new URLSearchParams(window.location.search));
  const moduleId = searchParms.get("module-selection");

  const isPresenting = useXR((state) => state.isPresenting);
  const [xrSupported, setXrSupported] = React.useState<boolean | undefined>(undefined);
  React.useLayoutEffect(() => {
    isXrModeSupported("immersive-vr").then(setXrSupported);
  }, []);

  React.useEffect(() => {
    if (moduleId && moduleId !== "true" && moduleId !== "") {
      emitter.emit("controls.disable");
    } else {
      emitter.emit("controls.enable");
    }
  }, [searchParms, location]);

  if (xrSupported === undefined) {
    return <></>;
  }

  return (
    <>
      {/*<StartOnHomePage />*/}
      <Background />
      <Camera />

      {!isPresenting && xrSupported ? (
        <Splash />
      ) : (
        <Routes>
          <Route path="/" element={<ModuleSelection />} />
          <Route path="/:moduleId/:sceneId?" element={<Scene />} />
        </Routes>
      )}
      <Static>
        <Layer
          zIndex={9349239434}
          position={[-3.15, 2.26, 0]}
          width="12%"
          aspectRatio={1}
          color="black"
          backgroundColor="white"
          borderRadius={0.2}
          textAlign="center"
          verticalAlign="center"
          textContent="v2.5"
          fontSize={0.4}
        />
      </Static>
      <Controllers />
    </>
  );
}
