import React from "react";
import assets from "@/config/assets";
import Card from "@/components/card";
import StartButton from "@/components/start-button";
import { useNavigate } from "react-router-dom";
import ArrowButton from "@/components/arrow-button";

export default function Module1Home() {
  const navigate = useNavigate();

  return (
    <>
      <Card
        title={`Building Trust with a New \n Advertiser`}
        image={assets["./content/autogenerated/script1/home.png"]}
        description={`This is the first call with Imani, owner of retail “Lucky Shrub.” Imani scheduled a meeting with a Marketing Pro after a cold outreach email. Here, the Marketing Pro will demonstrate selling the value of working with a Marketing Pro, discover important business information and goals, and help the advertiser set up the pitched solution.`}
        buttons={[
          <ArrowButton
            onDown={() => {
              navigate(`/?module-selection=true`);
            }}
            dir="prev"
          />,
          <StartButton moduleId="1" />,
        ]}
      />
    </>
  );
}
