
/*
    This is an autogenerated file. To regenerate assets from the
    public/assets/content/autogenerated folder, run this: npm run autogenerateassets
*/
const autogeneratedAssets = {
    "./content/autogenerated/cc-active.png": "./content/autogenerated/cc-active.png",
    "./content/autogenerated/cc-inactive.png": "./content/autogenerated/cc-inactive.png",
    "./content/autogenerated/disclaimer.svg": "./content/autogenerated/disclaimer.svg",
    "./content/autogenerated/home-active.png": "./content/autogenerated/home-active.png",
    "./content/autogenerated/home-inactive.png": "./content/autogenerated/home-inactive.png",
    "./content/autogenerated/icons/cc-off.png": "./content/autogenerated/icons/cc-off.png",
    "./content/autogenerated/icons/cc-on.png": "./content/autogenerated/icons/cc-on.png",
    "./content/autogenerated/icons/home.png": "./content/autogenerated/icons/home.png",
    "./content/autogenerated/main-room.jpg": "./content/autogenerated/main-room.jpg",
    "./content/autogenerated/menu-decoration.png": "./content/autogenerated/menu-decoration.png",
    "./content/autogenerated/menu-facebook-link.svg": "./content/autogenerated/menu-facebook-link.svg",
    "./content/autogenerated/menu-title.png": "./content/autogenerated/menu-title.png",
    "./content/autogenerated/meta-logo.svg": "./content/autogenerated/meta-logo.svg",
    "./content/autogenerated/models/meta-menu.gltf": "./content/autogenerated/models/meta-menu.gltf",
    "./content/autogenerated/module-menu-2-1.png": "./content/autogenerated/module-menu-2-1.png",
    "./content/autogenerated/module-menu-2-1.svg": "./content/autogenerated/module-menu-2-1.svg",
    "./content/autogenerated/module-menu-2-2.png": "./content/autogenerated/module-menu-2-2.png",
    "./content/autogenerated/module-menu-2-bg.png": "./content/autogenerated/module-menu-2-bg.png",
    "./content/autogenerated/module-menu-25-1.png": "./content/autogenerated/module-menu-25-1.png",
    "./content/autogenerated/module-menu-25-2.png": "./content/autogenerated/module-menu-25-2.png",
    "./content/autogenerated/module-menu-3-1.png": "./content/autogenerated/module-menu-3-1.png",
    "./content/autogenerated/module-menu-3-1.svg": "./content/autogenerated/module-menu-3-1.svg",
    "./content/autogenerated/module-menu-3-2.png": "./content/autogenerated/module-menu-3-2.png",
    "./content/autogenerated/module-menu-3-bg.png": "./content/autogenerated/module-menu-3-bg.png",
    "./content/autogenerated/module-menu-4-1.png": "./content/autogenerated/module-menu-4-1.png",
    "./content/autogenerated/module-menu-4-2.png": "./content/autogenerated/module-menu-4-2.png",
    "./content/autogenerated/module-menu-4-bg.png": "./content/autogenerated/module-menu-4-bg.png",
    "./content/autogenerated/module-menu-5-bg.png": "./content/autogenerated/module-menu-5-bg.png",
    "./content/autogenerated/module-menu-bg.png": "./content/autogenerated/module-menu-bg.png",
    "./content/autogenerated/new-images/1-2-1.jpg": "./content/autogenerated/new-images/1-2-1.jpg",
    "./content/autogenerated/new-images/1-2-5-7.jpg": "./content/autogenerated/new-images/1-2-5-7.jpg",
    "./content/autogenerated/new-images/1-5-12-14.jpg": "./content/autogenerated/new-images/1-5-12-14.jpg",
    "./content/autogenerated/new-images/1-5-8-9.jpg": "./content/autogenerated/new-images/1-5-8-9.jpg",
    "./content/autogenerated/new-images/1-6-1.jpg": "./content/autogenerated/new-images/1-6-1.jpg",
    "./content/autogenerated/new-images/2-2-8.jpg": "./content/autogenerated/new-images/2-2-8.jpg",
    "./content/autogenerated/new-images/2-4-14-15.jpg": "./content/autogenerated/new-images/2-4-14-15.jpg",
    "./content/autogenerated/new-images/2-4-8-9.jpg": "./content/autogenerated/new-images/2-4-8-9.jpg",
    "./content/autogenerated/new-images/3-2-1.jpg": "./content/autogenerated/new-images/3-2-1.jpg",
    "./content/autogenerated/new-images/3-2-2.jpg": "./content/autogenerated/new-images/3-2-2.jpg",
    "./content/autogenerated/new-images/3-2-5-7.jpg": "./content/autogenerated/new-images/3-2-5-7.jpg",
    "./content/autogenerated/new-images/3-2-9.jpg": "./content/autogenerated/new-images/3-2-9.jpg",
    "./content/autogenerated/new-images/3-4-4.jpg": "./content/autogenerated/new-images/3-4-4.jpg",
    "./content/autogenerated/new-images/3-6-1.jpg": "./content/autogenerated/new-images/3-6-1.jpg",
    "./content/autogenerated/new-images/4-3-5-7.jpg": "./content/autogenerated/new-images/4-3-5-7.jpg",
    "./content/autogenerated/new-images/4-5-1-2.jpg": "./content/autogenerated/new-images/4-5-1-2.jpg",
    "./content/autogenerated/new-images/4-7-4.jpg": "./content/autogenerated/new-images/4-7-4.jpg",
    "./content/autogenerated/new-images/5-2-1.jpg": "./content/autogenerated/new-images/5-2-1.jpg",
    "./content/autogenerated/new-images/5-3-2.jpg": "./content/autogenerated/new-images/5-3-2.jpg",
    "./content/autogenerated/new-images/5-4-4.jpg": "./content/autogenerated/new-images/5-4-4.jpg",
    "./content/autogenerated/new-images/5-7-3.jpg": "./content/autogenerated/new-images/5-7-3.jpg",
    "./content/autogenerated/new-images/6-2-1.jpg": "./content/autogenerated/new-images/6-2-1.jpg",
    "./content/autogenerated/new-images/6-2-4.jpg": "./content/autogenerated/new-images/6-2-4.jpg",
    "./content/autogenerated/new-images/6-3-1.jpg": "./content/autogenerated/new-images/6-3-1.jpg",
    "./content/autogenerated/new-images/6-3-5-7.jpg": "./content/autogenerated/new-images/6-3-5-7.jpg",
    "./content/autogenerated/new-images/6-3-8-9.jpg": "./content/autogenerated/new-images/6-3-8-9.jpg",
    "./content/autogenerated/new-images/6-4-1.jpg": "./content/autogenerated/new-images/6-4-1.jpg",
    "./content/autogenerated/new-images/6-5-4.jpg": "./content/autogenerated/new-images/6-5-4.jpg",
    "./content/autogenerated/new-images/6-5-8-9.jpg": "./content/autogenerated/new-images/6-5-8-9.jpg",
    "./content/autogenerated/new-images/6-6-4.jpg": "./content/autogenerated/new-images/6-6-4.jpg",
    "./content/autogenerated/new-images/7-2-1.jpg": "./content/autogenerated/new-images/7-2-1.jpg",
    "./content/autogenerated/new-images/7-2-4.jpg": "./content/autogenerated/new-images/7-2-4.jpg",
    "./content/autogenerated/new-images/7-3-4.jpg": "./content/autogenerated/new-images/7-3-4.jpg",
    "./content/autogenerated/new-images/7-5-5-7.jpg": "./content/autogenerated/new-images/7-5-5-7.jpg",
    "./content/autogenerated/new-images/7-6-2.jpg": "./content/autogenerated/new-images/7-6-2.jpg",
    "./content/autogenerated/new-images/8-2-1.jpg": "./content/autogenerated/new-images/8-2-1.jpg",
    "./content/autogenerated/new-images/8-2-5-7.jpg": "./content/autogenerated/new-images/8-2-5-7.jpg",
    "./content/autogenerated/new-images/8-4-1.jpg": "./content/autogenerated/new-images/8-4-1.jpg",
    "./content/autogenerated/new-images/8-5-2.jpg": "./content/autogenerated/new-images/8-5-2.jpg",
    "./content/autogenerated/new-images-2/1-2-2.jpg": "./content/autogenerated/new-images-2/1-2-2.jpg",
    "./content/autogenerated/new-images-2/1-2-4.png": "./content/autogenerated/new-images-2/1-2-4.png",
    "./content/autogenerated/new-images-2/1-5-4.jpg": "./content/autogenerated/new-images-2/1-5-4.jpg",
    "./content/autogenerated/new-images-2/1-5-5.jpg": "./content/autogenerated/new-images-2/1-5-5.jpg",
    "./content/autogenerated/new-images-2/2-2-1.jpg": "./content/autogenerated/new-images-2/2-2-1.jpg",
    "./content/autogenerated/new-images-2/2-4-1.jpg": "./content/autogenerated/new-images-2/2-4-1.jpg",
    "./content/autogenerated/new-images-2/2-7-1.jpg": "./content/autogenerated/new-images-2/2-7-1.jpg",
    "./content/autogenerated/new-images-2/3-3-1.jpg": "./content/autogenerated/new-images-2/3-3-1.jpg",
    "./content/autogenerated/new-images-2/3-3-question.png": "./content/autogenerated/new-images-2/3-3-question.png",
    "./content/autogenerated/new-images-2/4-3-correct.jpg": "./content/autogenerated/new-images-2/4-3-correct.jpg",
    "./content/autogenerated/new-images-2/4-3-incorrect.jpg": "./content/autogenerated/new-images-2/4-3-incorrect.jpg",
    "./content/autogenerated/new-images-2/4-4-1.png": "./content/autogenerated/new-images-2/4-4-1.png",
    "./content/autogenerated/new-images-2/4-7-1.jpg": "./content/autogenerated/new-images-2/4-7-1.jpg",
    "./content/autogenerated/new-images-2/5-3-question.jpg": "./content/autogenerated/new-images-2/5-3-question.jpg",
    "./content/autogenerated/new-images-2/5-5-4.jpg": "./content/autogenerated/new-images-2/5-5-4.jpg",
    "./content/autogenerated/new-images-2/6-2-2.jpg": "./content/autogenerated/new-images-2/6-2-2.jpg",
    "./content/autogenerated/new-images-2/6-3-2.png": "./content/autogenerated/new-images-2/6-3-2.png",
    "./content/autogenerated/new-images-2/6-4-4.jpg": "./content/autogenerated/new-images-2/6-4-4.jpg",
    "./content/autogenerated/new-images-2/6-6-1.jpg": "./content/autogenerated/new-images-2/6-6-1.jpg",
    "./content/autogenerated/new-images-2/7-3-2.jpg": "./content/autogenerated/new-images-2/7-3-2.jpg",
    "./content/autogenerated/new-images-2/7-4-1.png": "./content/autogenerated/new-images-2/7-4-1.png",
    "./content/autogenerated/new-images-2/7-4-4.jpg": "./content/autogenerated/new-images-2/7-4-4.jpg",
    "./content/autogenerated/new-images-2/8-3-correct2.jpg": "./content/autogenerated/new-images-2/8-3-correct2.jpg",
    "./content/autogenerated/new-images-2/8-3-incorrect2.jpg": "./content/autogenerated/new-images-2/8-3-incorrect2.jpg",
    "./content/autogenerated/new-images-2/8-4-2.png": "./content/autogenerated/new-images-2/8-4-2.png",
    "./content/autogenerated/new-images-2/8-5-incorrect.jpg": "./content/autogenerated/new-images-2/8-5-incorrect.jpg",
    "./content/autogenerated/play-blue-button-bg.png": "./content/autogenerated/play-blue-button-bg.png",
    "./content/autogenerated/play.png": "./content/autogenerated/play.png",
    "./content/autogenerated/player-back.png": "./content/autogenerated/player-back.png",
    "./content/autogenerated/player-forward.png": "./content/autogenerated/player-forward.png",
    "./content/autogenerated/player-pause.png": "./content/autogenerated/player-pause.png",
    "./content/autogenerated/player-play.png": "./content/autogenerated/player-play.png",
    "./content/autogenerated/player.png": "./content/autogenerated/player.png",
    "./content/autogenerated/repeat-btn.svg": "./content/autogenerated/repeat-btn.svg",
    "./content/autogenerated/script1/call-closing-correct.png": "./content/autogenerated/script1/call-closing-correct.png",
    "./content/autogenerated/script1/call-closing-incorrect.png": "./content/autogenerated/script1/call-closing-incorrect.png",
    "./content/autogenerated/script1/call-closing-step-0.png": "./content/autogenerated/script1/call-closing-step-0.png",
    "./content/autogenerated/script1/call-closing-step-1.jpeg": "./content/autogenerated/script1/call-closing-step-1.jpeg",
    "./content/autogenerated/script1/call-closing-step-3.png": "./content/autogenerated/script1/call-closing-step-3.png",
    "./content/autogenerated/script1/call-closing-step-4.png": "./content/autogenerated/script1/call-closing-step-4.png",
    "./content/autogenerated/script1/call-closing-step-6.png": "./content/autogenerated/script1/call-closing-step-6.png",
    "./content/autogenerated/script1/call-opening-question-1.png": "./content/autogenerated/script1/call-opening-question-1.png",
    "./content/autogenerated/script1/call-opening-question-correct-1.png": "./content/autogenerated/script1/call-opening-question-correct-1.png",
    "./content/autogenerated/script1/call-opening-question-incorrect-1.png": "./content/autogenerated/script1/call-opening-question-incorrect-1.png",
    "./content/autogenerated/script1/call-opening-step-1.png": "./content/autogenerated/script1/call-opening-step-1.png",
    "./content/autogenerated/script1/call-opening-step-2.jpg": "./content/autogenerated/script1/call-opening-step-2.jpg",
    "./content/autogenerated/script1/call-opening-step-4.png": "./content/autogenerated/script1/call-opening-step-4.png",
    "./content/autogenerated/script1/call-opening-step-6.png": "./content/autogenerated/script1/call-opening-step-6.png",
    "./content/autogenerated/script1/commercial-step-0.png": "./content/autogenerated/script1/commercial-step-0.png",
    "./content/autogenerated/script1/commercial-step-1.jpeg": "./content/autogenerated/script1/commercial-step-1.jpeg",
    "./content/autogenerated/script1/commercial-step-3.png": "./content/autogenerated/script1/commercial-step-3.png",
    "./content/autogenerated/script1/commercial-step-4.png": "./content/autogenerated/script1/commercial-step-4.png",
    "./content/autogenerated/script1/discovery-question-incorrect.png": "./content/autogenerated/script1/discovery-question-incorrect.png",
    "./content/autogenerated/script1/discovery-question.png": "./content/autogenerated/script1/discovery-question.png",
    "./content/autogenerated/script1/discovery-step-1.jpeg": "./content/autogenerated/script1/discovery-step-1.jpeg",
    "./content/autogenerated/script1/discovery-step-3.png": "./content/autogenerated/script1/discovery-step-3.png",
    "./content/autogenerated/script1/discovery-step-5.png": "./content/autogenerated/script1/discovery-step-5.png",
    "./content/autogenerated/script1/home.png": "./content/autogenerated/script1/home.png",
    "./content/autogenerated/script1/tailored-correct-2.png": "./content/autogenerated/script1/tailored-correct-2.png",
    "./content/autogenerated/script1/tailored-incorrect-2.png": "./content/autogenerated/script1/tailored-incorrect-2.png",
    "./content/autogenerated/script1/tailored-question-2.png": "./content/autogenerated/script1/tailored-question-2.png",
    "./content/autogenerated/script1/tailored-solutions-description.svg": "./content/autogenerated/script1/tailored-solutions-description.svg",
    "./content/autogenerated/script1/tailored-step-0.png": "./content/autogenerated/script1/tailored-step-0.png",
    "./content/autogenerated/script1/tailored-step-1.png": "./content/autogenerated/script1/tailored-step-1.png",
    "./content/autogenerated/script1/tailored-step-3.png": "./content/autogenerated/script1/tailored-step-3.png",
    "./content/autogenerated/script1/tailored-step-4.png": "./content/autogenerated/script1/tailored-step-4.png",
    "./content/autogenerated/script1/tailored-step-6.png": "./content/autogenerated/script1/tailored-step-6.png",
    "./content/autogenerated/script1/tailored-step-8.png": "./content/autogenerated/script1/tailored-step-8.png",
    "./content/autogenerated/script2/call-closing-step-0.png": "./content/autogenerated/script2/call-closing-step-0.png",
    "./content/autogenerated/script2/call-closing-step-1.jpeg": "./content/autogenerated/script2/call-closing-step-1.jpeg",
    "./content/autogenerated/script2/call-closing-step-3.png": "./content/autogenerated/script2/call-closing-step-3.png",
    "./content/autogenerated/script2/call-closing-step-4.png": "./content/autogenerated/script2/call-closing-step-4.png",
    "./content/autogenerated/script2/call-opening-correct.png": "./content/autogenerated/script2/call-opening-correct.png",
    "./content/autogenerated/script2/call-opening-incorrect.png": "./content/autogenerated/script2/call-opening-incorrect.png",
    "./content/autogenerated/script2/call-opening-step-1.png": "./content/autogenerated/script2/call-opening-step-1.png",
    "./content/autogenerated/script2/call-opening-step-2.jpeg": "./content/autogenerated/script2/call-opening-step-2.jpeg",
    "./content/autogenerated/script2/call-opening-step-4.png": "./content/autogenerated/script2/call-opening-step-4.png",
    "./content/autogenerated/script2/call-opening-step-5.png": "./content/autogenerated/script2/call-opening-step-5.png",
    "./content/autogenerated/script2/call-opening-summary.png": "./content/autogenerated/script2/call-opening-summary.png",
    "./content/autogenerated/script2/commercial-conversations-step-1.jpeg": "./content/autogenerated/script2/commercial-conversations-step-1.jpeg",
    "./content/autogenerated/script2/commercial-correct.png": "./content/autogenerated/script2/commercial-correct.png",
    "./content/autogenerated/script2/commercial-incorrect.png": "./content/autogenerated/script2/commercial-incorrect.png",
    "./content/autogenerated/script2/commercial-question.png": "./content/autogenerated/script2/commercial-question.png",
    "./content/autogenerated/script2/commercial-step-0.png": "./content/autogenerated/script2/commercial-step-0.png",
    "./content/autogenerated/script2/commercial-step-1.jpeg": "./content/autogenerated/script2/commercial-step-1.jpeg",
    "./content/autogenerated/script2/commercial-summary.png": "./content/autogenerated/script2/commercial-summary.png",
    "./content/autogenerated/script2/discovery-correct.png": "./content/autogenerated/script2/discovery-correct.png",
    "./content/autogenerated/script2/discovery-incorrect.png": "./content/autogenerated/script2/discovery-incorrect.png",
    "./content/autogenerated/script2/discovery-questions.png": "./content/autogenerated/script2/discovery-questions.png",
    "./content/autogenerated/script2/discovery-step-0.png": "./content/autogenerated/script2/discovery-step-0.png",
    "./content/autogenerated/script2/discovery-step-1.jpeg": "./content/autogenerated/script2/discovery-step-1.jpeg",
    "./content/autogenerated/script2/discovery-step-3.png": "./content/autogenerated/script2/discovery-step-3.png",
    "./content/autogenerated/script2/discovery-summary.png": "./content/autogenerated/script2/discovery-summary.png",
    "./content/autogenerated/script2/home.png": "./content/autogenerated/script2/home.png",
    "./content/autogenerated/script2/securing-commitment-step-0.png": "./content/autogenerated/script2/securing-commitment-step-0.png",
    "./content/autogenerated/script2/securing-commitment-step-1.jpeg": "./content/autogenerated/script2/securing-commitment-step-1.jpeg",
    "./content/autogenerated/script2/securing-commitment-step-3.png": "./content/autogenerated/script2/securing-commitment-step-3.png",
    "./content/autogenerated/script2/securing-commitment-step-4.png": "./content/autogenerated/script2/securing-commitment-step-4.png",
    "./content/autogenerated/script2/tailored-correct-1.png": "./content/autogenerated/script2/tailored-correct-1.png",
    "./content/autogenerated/script2/tailored-correct-2.png": "./content/autogenerated/script2/tailored-correct-2.png",
    "./content/autogenerated/script2/tailored-incorrect-1.png": "./content/autogenerated/script2/tailored-incorrect-1.png",
    "./content/autogenerated/script2/tailored-incorrect-2.png": "./content/autogenerated/script2/tailored-incorrect-2.png",
    "./content/autogenerated/script2/tailored-step-0.png": "./content/autogenerated/script2/tailored-step-0.png",
    "./content/autogenerated/script2/tailored-step-1.png": "./content/autogenerated/script2/tailored-step-1.png",
    "./content/autogenerated/script2/tailored-step-3.png": "./content/autogenerated/script2/tailored-step-3.png",
    "./content/autogenerated/script2/tailored-step-4.png": "./content/autogenerated/script2/tailored-step-4.png",
    "./content/autogenerated/script2/tailored-step-6.png": "./content/autogenerated/script2/tailored-step-6.png",
    "./content/autogenerated/script2/tailored-step-7.png": "./content/autogenerated/script2/tailored-step-7.png",
    "./content/autogenerated/script2/tailored-summary.png": "./content/autogenerated/script2/tailored-summary.png",
    "./content/autogenerated/script3/call-opening-correct-1.png": "./content/autogenerated/script3/call-opening-correct-1.png",
    "./content/autogenerated/script3/call-opening-incorrect-1.png": "./content/autogenerated/script3/call-opening-incorrect-1.png",
    "./content/autogenerated/script3/call-opening-step-0.png": "./content/autogenerated/script3/call-opening-step-0.png",
    "./content/autogenerated/script3/call-opening-step-1.jpeg": "./content/autogenerated/script3/call-opening-step-1.jpeg",
    "./content/autogenerated/script3/call-opening-step-3.png": "./content/autogenerated/script3/call-opening-step-3.png",
    "./content/autogenerated/script3/call-opening-step-4.png": "./content/autogenerated/script3/call-opening-step-4.png",
    "./content/autogenerated/script3/call-opening-step-5.png": "./content/autogenerated/script3/call-opening-step-5.png",
    "./content/autogenerated/script3/commercial-correct.png": "./content/autogenerated/script3/commercial-correct.png",
    "./content/autogenerated/script3/commercial-feedback.png": "./content/autogenerated/script3/commercial-feedback.png",
    "./content/autogenerated/script3/commercial-incorrect.png": "./content/autogenerated/script3/commercial-incorrect.png",
    "./content/autogenerated/script3/commercial-question.png": "./content/autogenerated/script3/commercial-question.png",
    "./content/autogenerated/script3/commercial-summary.png": "./content/autogenerated/script3/commercial-summary.png",
    "./content/autogenerated/script3/discover-summary.png": "./content/autogenerated/script3/discover-summary.png",
    "./content/autogenerated/script3/discovery-incorrect.png": "./content/autogenerated/script3/discovery-incorrect.png",
    "./content/autogenerated/script3/home.png": "./content/autogenerated/script3/home.png",
    "./content/autogenerated/script3/securing-incorrect.png": "./content/autogenerated/script3/securing-incorrect.png",
    "./content/autogenerated/script3/securing-questions.png": "./content/autogenerated/script3/securing-questions.png",
    "./content/autogenerated/script3/securing-summary.png": "./content/autogenerated/script3/securing-summary.png",
    "./content/autogenerated/script3/tailored-summary.png": "./content/autogenerated/script3/tailored-summary.png",
    "./content/autogenerated/script4/call-closing-summary.png": "./content/autogenerated/script4/call-closing-summary.png",
    "./content/autogenerated/script4/commercial-step-0.png": "./content/autogenerated/script4/commercial-step-0.png",
    "./content/autogenerated/script4/discovery-correct.png": "./content/autogenerated/script4/discovery-correct.png",
    "./content/autogenerated/script4/home.png": "./content/autogenerated/script4/home.png",
    "./content/autogenerated/script4/securing-correct.png": "./content/autogenerated/script4/securing-correct.png",
    "./content/autogenerated/script4/securing-incorrect.png": "./content/autogenerated/script4/securing-incorrect.png",
    "./content/autogenerated/script4/securing-question.png": "./content/autogenerated/script4/securing-question.png",
    "./content/autogenerated/script4/securing-summary.png": "./content/autogenerated/script4/securing-summary.png",
    "./content/autogenerated/script4/tailored-summary.png": "./content/autogenerated/script4/tailored-summary.png",
    "./content/autogenerated/script5/commercial-correct.png": "./content/autogenerated/script5/commercial-correct.png",
    "./content/autogenerated/script5/commercial-summary.png": "./content/autogenerated/script5/commercial-summary.png",
    "./content/autogenerated/script5/discovery-description.svg": "./content/autogenerated/script5/discovery-description.svg",
    "./content/autogenerated/script5/home.png": "./content/autogenerated/script5/home.png",
    "./content/autogenerated/script5/securing-step-3.png": "./content/autogenerated/script5/securing-step-3.png",
    "./content/autogenerated/script5/securing-summary.png": "./content/autogenerated/script5/securing-summary.png",
    "./content/autogenerated/script5/tailored-correct.png": "./content/autogenerated/script5/tailored-correct.png",
    "./content/autogenerated/script5/tailored-incorrect.png": "./content/autogenerated/script5/tailored-incorrect.png",
    "./content/autogenerated/script5/tailored-question.png": "./content/autogenerated/script5/tailored-question.png",
    "./content/autogenerated/script5/tailored-summary.png": "./content/autogenerated/script5/tailored-summary.png",
    "./content/autogenerated/script6/home.png": "./content/autogenerated/script6/home.png",
    "./content/autogenerated/script6/securing-correct.png": "./content/autogenerated/script6/securing-correct.png",
    "./content/autogenerated/script6/securing-incorrect.png": "./content/autogenerated/script6/securing-incorrect.png",
    "./content/autogenerated/script6/securing-question.png": "./content/autogenerated/script6/securing-question.png",
    "./content/autogenerated/script6/securing-step-3.png": "./content/autogenerated/script6/securing-step-3.png",
    "./content/autogenerated/script6/securing-summary.png": "./content/autogenerated/script6/securing-summary.png",
    "./content/autogenerated/script6/tailored-summary.png": "./content/autogenerated/script6/tailored-summary.png",
    "./content/autogenerated/script7/call-closing-summary.png": "./content/autogenerated/script7/call-closing-summary.png",
    "./content/autogenerated/script7/call-opening-correct.png": "./content/autogenerated/script7/call-opening-correct.png",
    "./content/autogenerated/script7/call-opening-incorrect.png": "./content/autogenerated/script7/call-opening-incorrect.png",
    "./content/autogenerated/script7/call-opening-question.png": "./content/autogenerated/script7/call-opening-question.png",
    "./content/autogenerated/script7/call-opening-step-4.png": "./content/autogenerated/script7/call-opening-step-4.png",
    "./content/autogenerated/script7/call-opening-summary.png": "./content/autogenerated/script7/call-opening-summary.png",
    "./content/autogenerated/script7/commercial-description.svg": "./content/autogenerated/script7/commercial-description.svg",
    "./content/autogenerated/script7/commercial-summary.png": "./content/autogenerated/script7/commercial-summary.png",
    "./content/autogenerated/script7/discovery-summary.png": "./content/autogenerated/script7/discovery-summary.png",
    "./content/autogenerated/script7/home.jpeg": "./content/autogenerated/script7/home.jpeg",
    "./content/autogenerated/script7/securing-step-3.png": "./content/autogenerated/script7/securing-step-3.png",
    "./content/autogenerated/script7/securing-summary.png": "./content/autogenerated/script7/securing-summary.png",
    "./content/autogenerated/script7/tailored-question.png": "./content/autogenerated/script7/tailored-question.png",
    "./content/autogenerated/script7/tailored-summary.png": "./content/autogenerated/script7/tailored-summary.png",
    "./content/autogenerated/script8/call-closing-correct.png": "./content/autogenerated/script8/call-closing-correct.png",
    "./content/autogenerated/script8/call-closing-incorrect.png": "./content/autogenerated/script8/call-closing-incorrect.png",
    "./content/autogenerated/script8/call-closing-question.png": "./content/autogenerated/script8/call-closing-question.png",
    "./content/autogenerated/script8/call-closing-summary.png": "./content/autogenerated/script8/call-closing-summary.png",
    "./content/autogenerated/script8/call-opening-correct-2.png": "./content/autogenerated/script8/call-opening-correct-2.png",
    "./content/autogenerated/script8/call-opening-correct.png": "./content/autogenerated/script8/call-opening-correct.png",
    "./content/autogenerated/script8/call-opening-incorrect-2.png": "./content/autogenerated/script8/call-opening-incorrect-2.png",
    "./content/autogenerated/script8/call-opening-incorrect.png": "./content/autogenerated/script8/call-opening-incorrect.png",
    "./content/autogenerated/script8/call-opening-question-2.png": "./content/autogenerated/script8/call-opening-question-2.png",
    "./content/autogenerated/script8/call-opening-question.png": "./content/autogenerated/script8/call-opening-question.png",
    "./content/autogenerated/script8/call-opening-summary.png": "./content/autogenerated/script8/call-opening-summary.png",
    "./content/autogenerated/script8/discovery-summary.png": "./content/autogenerated/script8/discovery-summary.png",
    "./content/autogenerated/script8/home.png": "./content/autogenerated/script8/home.png",
    "./content/autogenerated/script8/tailored-feedback.png": "./content/autogenerated/script8/tailored-feedback.png",
    "./content/autogenerated/script8/tailored-summary.png": "./content/autogenerated/script8/tailored-summary.png",
    "./content/autogenerated/shared/call-closing-1.svg": "./content/autogenerated/shared/call-closing-1.svg",
    "./content/autogenerated/shared/call-closing-2.svg": "./content/autogenerated/shared/call-closing-2.svg",
    "./content/autogenerated/shared/call-opening-1.svg": "./content/autogenerated/shared/call-opening-1.svg",
    "./content/autogenerated/shared/call-opening-2.svg": "./content/autogenerated/shared/call-opening-2.svg",
    "./content/autogenerated/shared/commercial-1.svg": "./content/autogenerated/shared/commercial-1.svg",
    "./content/autogenerated/shared/commercial-2.svg": "./content/autogenerated/shared/commercial-2.svg",
    "./content/autogenerated/shared/discovery-1.svg": "./content/autogenerated/shared/discovery-1.svg",
    "./content/autogenerated/shared/discovery-2.svg": "./content/autogenerated/shared/discovery-2.svg",
    "./content/autogenerated/shared/securing-1.svg": "./content/autogenerated/shared/securing-1.svg",
    "./content/autogenerated/shared/securing-2.svg": "./content/autogenerated/shared/securing-2.svg",
    "./content/autogenerated/shared/tailored-1.svg": "./content/autogenerated/shared/tailored-1.svg",
    "./content/autogenerated/shared/tailored-2.svg": "./content/autogenerated/shared/tailored-2.svg",
    "./content/autogenerated/skip-button.png": "./content/autogenerated/skip-button.png",
    "./content/autogenerated/welcome-room.png": "./content/autogenerated/welcome-room.png"
}
export default autogeneratedAssets;
