import React from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { useLocation } from "react-router-dom";

type Props = {
  src: string;
  children?: React.ReactNode;
};

const textureLoader = new THREE.TextureLoader();

export default function SphereImage({ src, children }: Props) {
  const viewport = useThree((state) => state.viewport);
  const meshRef = React.useRef<THREE.Mesh | null>(null);

  const texture = React.useMemo(() => {
    return textureLoader.load(src);
  }, [src]);

  return (
    <mesh ref={meshRef} rotation={[0, -2, 0]} renderOrder={-1} scale-x={-1}>
      <sphereGeometry args={[viewport.height * 2, 32, 32]}></sphereGeometry>
      <meshBasicMaterial color="gray" depthWrite={false} depthTest={false} map={texture} side={THREE.BackSide} />
      {children}
    </mesh>
  );
}
