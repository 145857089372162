import React, { useEffect } from "react";
import type { Answer } from "@/types";
import Frame from "@/components/frame";
import Question from "./question";
import Result from "./result";

type Props = {
  subtitle: string;
  image: string;
  imageIncorrect?: string;
  imageCorrect?: string;
  question: string;
  answers: Answer[];
  descriptionCorrect?: string;
  questionFontSize?: number | `${number}px` | undefined;
  onAnswer: (answer: Answer) => void;
};

export default function FillBlanksQuestion({
  subtitle,
  questionFontSize,
  descriptionCorrect = "",
  image,
  imageCorrect = image,
  imageIncorrect = image,
  question,
  answers,
  onAnswer,
}: Props) {
  const [step, setStep] = React.useState(0);
  const [answer, setAnswer] = React.useState<Answer | null>(null);
  const [answerHistory, setAnswerHistory] = React.useState<Answer[]>([]);

  React.useEffect(() => {
    setStep(answer === null ? 0 : 1);
  }, [answer]);

  useEffect(() => {
    console.log(answerHistory);
  }, [answerHistory]);

  return (
    <Frame>
      {step === 0 && (
        <Question
          useAnswerHistory={[answerHistory, setAnswerHistory]}
          questionFontSize={questionFontSize}
          subtitle={subtitle}
          image={image}
          question={question}
          answers={answers}
          onAnswer={setAnswer}
        />
      )}
      {step === 1 && (
        <Result
          subtitle={subtitle}
          image={answer?.correct === true ? imageCorrect : imageIncorrect}
          correct={answer?.correct === true}
          description={descriptionCorrect}
          onContinue={() => {
            onAnswer(answer!);
          }}
          onRetake={() => setAnswer(null)}
        />
      )}
    </Frame>
  );
}
