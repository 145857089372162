import React from "react";
import assets from "@/config/assets";
import Card from "@/components/card";
import StartButton from "@/components/start-button";
import { useNavigate } from "react-router-dom";
import ArrowButton from "@/components/arrow-button";

export default function Module8Home() {
  const navigate = useNavigate();

  return (
    <>
      <Card
        title={`Building Trust Through Initial\nAccount Optimization`}
        image={assets["./content/autogenerated/script3/home.png"]}
        descriptionFontSize={1 / 14}
        description={`Sindy, the Marketing Pro, is on a second call with Hair Day advertiser and owner, Jennelle. Jennelle’s hesitant to change their current strategy on running ads. They’ve been running traffic campaigns and have a goal to increase the number of salon booking appointments by driving more traffic to their website. Here’s where Sindy can push Leads: Messages to help the advertiser increase salon bookings without customers having to call the salon.`}
        buttons={[
          <ArrowButton
            onDown={() => {
              navigate(`/?module-selection=true`);
            }}
            dir="prev"
          />,
          <StartButton moduleId="3" />,
        ]}
      />
    </>
  );
}
