import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8CallOpening() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const [videoSrc, setVideoSrc] = React.useState(assets.video2);

  const videoRotation = useVideoRotation([0, -40, 0]);

  return (
    <>
      {![0, 3].includes(step) && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["1"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["1"].end}
          src={videoSrc}
          play={false}
        />
      )}
      <Title textContent="CALL OPENING" />
      {step === 0 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["1"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["1"].end}
          src={assets.video2}
          onEnded={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Showing \nup prepared`}
          subtitle="In the call opening: "
          image={assets["./content/autogenerated/new-images-2/2-2-1.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/call-opening-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(2)} />, <ArrowButton dir="next" onDown={() => setStep(3)} />]}
        />
      )}
      {step === 2 && (
        <CardLarge
          title={`Showing \nup prepared`}
          image={assets["./content/autogenerated/script2/call-opening-step-2.jpeg"]}
          subtitle="Examples:"
          descriptionImage={assets["./content/autogenerated/shared/call-opening-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(3)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 3 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["2"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["2"].end}
          src={assets.video2}
          onEnded={() => setStep(4)}
        />
      )}
      {step === 4 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Call Opening"
          image={assets["./content/autogenerated/script2/call-opening-step-4.png"]}
          description={`Simon did well by referring to the previous conversation, as this is a clear signal that they prepared for the call. The agenda was super clear, as well.`}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(5)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 5 && (
        <MultipleChoiceQuestion
          subtitle={`Call Opening`}
          image={assets["./content/autogenerated/script2/call-opening-step-5.png"]}
          imageCorrect={assets["./content/autogenerated/new-images/2-2-8.jpg"]}
          imageIncorrect={assets["./content/autogenerated/script2/call-opening-incorrect.png"]}
          descriptionCorrect={`A clear agenda keeps the call on track and ensures that you and the advertiser stay on the same page! You might still need to incorporate any new input from the advertiser when you validate your agenda with them.\n\nSimon also asked if the advertiser had anything they'd like to include, and incorporated the advertiser’s response into the agenda. Excellent work!`}
          question={`Why is a clear agenda important? `}
          answers={[
            {
              answer: `A clear agenda ensures that you and the advertiser have a plan to use your time together effectively.`,
              correct: true,
            },
            {
              answer: `A clear agenda protects you if the advertiser later insists that you did not cover the right topics.`,
              correct: false,
            },
            {
              answer: `A clear agenda enables you to drive the call, so you will not have to react to unexpected advertiser input.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/discovery`);
          }}
        />
      )}
    </>
  );
}
