import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8Discovery() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["6"]["3"].start}
          subtitlesFile="./subtitles/6.vtt"
          end={videoSplit["6"]["3"].end}
          src={assets.video6}
          play={false}
        />
      )}
      <Title textContent="DISCOVERY" />
      {step === 0 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          subtitle="During Discovery: "
          image={assets["./content/autogenerated/new-images/6-3-1.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          image={assets["./content/autogenerated/new-images-2/6-3-2.png"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["6"]["3"].start}
          subtitlesFile="./subtitles/6.vtt"
          end={videoSplit["6"]["3"].end}
          src={assets.video6}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Discovery & Connection"
          image={assets["./content/autogenerated/script2/discovery-step-3.png"]}
          description={`Sindy asked relevant questions to deepen and validate their knowledge of the client's business, which is another example of displaying their preparation.\n\n   Understanding the product offerings and understanding where and how a business sells are critical to building a marketing strategy with the advertiser. Great work! `}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Discovery`}
          image={assets["./content/autogenerated/new-images/6-3-5-7.jpg"]}
          imageCorrect={assets["./content/autogenerated/new-images/6-5-8-9.jpg"]}
          imageIncorrect={assets["./content/autogenerated/new-images/6-5-8-9.jpg"]}
          descriptionCorrect="Asking about the most popular products could help Sindy learn more about Lexi’s product catalog, and, depending on Lexi’s answer, it could unlock additional insights about her business model, clients and goals."
          question={`What is the best question Sindy could have asked to learn more about this business’s product offerings?`}
          answers={[
            { answer: `Do you ever offer sales, promotions, or discount codes? `, correct: false },
            {
              answer: `How much budget do you have to spend on online advertising?`,
              correct: false,
            },
            {
              answer: `What are some of your most popular products right now?`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/tailored-solutions`);
          }}
        />
      )}
    </>
  );
}
