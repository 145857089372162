import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8SecuringCommitment() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["6"]["5"].start}
          subtitlesFile="./subtitles/6.vtt"
          end={videoSplit["6"]["5"].end}
          src={assets.video6}
          play={false}
        />
      )}
      <Title textContent="SECURING COMMITMENT" />
      {step === 0 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-0.png"]}
          subtitle="During Securing Commitment:"
          descriptionImage={assets[`./content/autogenerated/shared/securing-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}

          image={assets["./content/autogenerated/script2/securing-commitment-step-1.jpeg"]}
          descriptionImage={assets[`./content/autogenerated/shared/securing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["6"]["5"].start}
          subtitlesFile="./subtitles/6.vtt"
          end={videoSplit["6"]["5"].end}
          src={assets.video6}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Securing Commitment`}
          image={assets["./content/autogenerated/new-images/6-5-4.jpg"]}
          description={`Sindy explained why acting sooner is important for the business and confirmed that the advertiser is aligned on the approach. Well done!\n\n   Sindy could have also confirmed any follow up actions the advertiser should take in preparation for the next call. This could be to review the performance of any existing ad sets, gather any creative assets needed for mobile friendly video or assess what resources might be required to install conversions API.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Discovery`}
          image={assets["./content/autogenerated/script6/securing-question.png"]}
          imageCorrect={assets["./content/autogenerated/new-images/6-5-8-9.jpg"]}
          imageIncorrect={assets["./content/autogenerated/new-images/6-5-8-9.jpg"]}
          descriptionCorrect="Sindy framed their argument in terms of what the advertiser wants to achieve. They also referenced current events to emphasize the urgency of making progress as soon as possible."
          question={`When Sindy said: at the risk of sounding pushy, sooner is better, really. When there's a big change in the industry, like what we're experiencing now, businesses need to choose to act or react. I'd like to help you gain a competitive advantage to act quickly. What did the advertiser likely find most convincing?`}
          questionFontSize={1 / 6.8}
          answers={[
            { answer: `That Sindy empathized with their upcoming busy schedule.`, correct: false },
            {
              answer: `That Sindy centered the reasoning around what’s beneficial to the advertiser.`,
              correct: true,
            },
            {
              answer: `That Sindy used jargon and complicated technical terms to sound like an expert.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/call-closing`);
          }}
        />
      )}
    </>
  );
}
