import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  to: string;
};

export default function Redirect({ to }: Props) {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(to);
  }, [to]);

  return <></>;
}
