import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import { appState } from "@/state/app-state";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8TailoredSolutions() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const videoRotation = useVideoRotation([0, -80, 0]);

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["4"]["4"].start}
          subtitlesFile="./subtitles/4.vtt"
          end={videoSplit["4"]["4"].end}
          src={assets.video4}
          play={false}
        />
      )}
      <Title textContent="TAILORED SOLUTIONS" />
      {step === 0 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets["./content/autogenerated/new-images/4-5-1-2.jpg"]}
          subtitle="During Tailored Solutions:"
          descriptionImage={assets[`./content/autogenerated/shared/tailored-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets["./content/autogenerated/new-images/4-5-1-2.jpg"]}

          descriptionImage={assets[`./content/autogenerated/shared/tailored-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["4"]["4"].start}
          subtitlesFile="./subtitles/4.vtt"
          end={videoSplit["4"]["4"].end}
          src={assets.video4}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle="Tailored Solutions"
          image={assets[`./content/autogenerated/script1/tailored-step-3.png`]}
          description={`Sindy communicated clearly how Advantage+ Placement works and did an excellent job of educating the advertiser on how that solution relates directly to their goal of finding more people interested in purchasing nail polish.\n\n   Sindy could have taken more time to explain how A/B Testing works and could have aligned with the advertiser on how they will measure the effectiveness of each ad, before consolidating.`}
          buttons={[<ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/securing-commitment`)} />]}
        />
      )}
    </>
  );
}
