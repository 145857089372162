import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8Discovery() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const videoRotation = useVideoRotation([0, -80, 0]);

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["4"]["2"].start}
          subtitlesFile="./subtitles/4.vtt"
          end={videoSplit["4"]["2"].end}
          src={assets.video4}
          play={false}
        />
      )}
      <Title textContent="DISCOVERY" />
      {step === 0 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          subtitle="During Discovery: "
          image={assets["./content/autogenerated/script2/discovery-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          image={assets["./content/autogenerated/script2/discovery-step-1.jpeg"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["4"]["3"].start}
          subtitlesFile="./subtitles/4.vtt"
          end={videoSplit["4"]["3"].end}
          src={assets.video4}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Discovery & Connection"
          image={assets["./content/autogenerated/script2/discovery-step-3.png"]}
          description={`This is an excellent example of Sindy asking relevant questions to stay connected to the advertiser’s current needs.\n\n   Sindy could have also provided context for the questions to make sure they get buy-in from the advertiser.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Discovery`}
          image={assets["./content/autogenerated/new-images/4-3-5-7.jpg"]}
          imageCorrect={assets["./content/autogenerated/new-images-2/4-3-correct.jpg"]}
          imageIncorrect={assets["./content/autogenerated/new-images-2/4-3-correct.jpg"]}
          descriptionCorrect="Context can improve the quality of the advertiser’s answers, as the additional context helps the advertiser understand the importance of each question and how each part of the conversation fits into the overall relationship."
          question={`What’s the best reason to provide context for the questions being asked?`}
          answers={[
            {
              answer: `While you’re taking a longer time to explain your question, the advertiser can write down more extensive notes.`,
              correct: false,
            },
            {
              answer: `When an advertiser understands the importance of your question, they are more likely to give a quality answer.`,
              correct: true,
            },
            {
              answer: `What contextual justification you provide now can guide the later commitments you want to secure.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/tailored-solutions`);
          }}
        />
      )}
    </>
  );
}
