import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import CardSummary from "@/components/card-summary";

export default function Module8CallClosing() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["8"]["5"].start}
          subtitlesFile="./subtitles/8.vtt"
          end={videoSplit["8"]["5"].end}
          src={assets.video8}
          play={false}
        />
      )}
      <Title textContent="CALL CLOSING" />
      {step === 0 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          subtitle="During the call closing:"
          image={assets["./content/autogenerated/script1/call-closing-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/call-closing-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          image={assets["./content/autogenerated/new-images/8-5-2.jpg"]}
          descriptionImage={assets[`./content/autogenerated/shared/call-closing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["8"]["5"].start}
          subtitlesFile="./subtitles/8.vtt"
          end={videoSplit["8"]["5"].end}
          src={assets.video8}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <MultipleChoiceQuestion
          subtitle={`Call Closing`}
          image={assets["./content/autogenerated/script8/call-closing-question.png"]}
          imageCorrect={assets["./content/autogenerated/script8/call-closing-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/new-images-2/8-5-incorrect.jpg"]}
          descriptionCorrect="Simon should have scheduled the next call and set expectations of what would be covered during it."
          question={`Simon summarized the key takeaways from the discussion, but what was missing?`}
          answers={[
            {
              answer: `Simon didn’t check if the advertiser had any additional or unanswered questions.`,
              correct: false,
            },
            {
              answer: `Simon didn’t schedule a follow-up call after the Meta Technical Pro call.`,
              correct: true,
            },
            {
              answer: `Simon didn’t summarize what had been covered on today’s call.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            setStep(4);
          }}
        />
      )}
      {step === 4 && (
        <CardSummary
          onPrev={() => {
            setStep(3);
          }}
          onFinish={() => {
            const state = appState.getState();
            state.endSession(params.moduleId!);
            state.reset();
            state.completeModule("8");
            navigate("/?module-selection=true");
          }}
        />
      )}
    </>
  );
}
