export default [
  {
    "tabs": [
      "Call Control & Leadership",
      "Clear Communication",
      "Listening & Empathy",
      "Professionalism & Respect",
    ],
    "scores": [
      100,
      75,
      100,
      100
    ],
    "average": 94,
    "description": [
      "Simon did a great job of leading the call with structure, confidence, and authority; he also prioritized time effectively, allowing enought time for a full Pixel walkthrough. ",
      "The targeting solution could have been explained more simply. Here’s an example…. \"I recommend you mostly rely on our ad delivery system to find the best people to show your ad to while still using just a few very basic targeting parameters of your own. Our algorithm will then find the audience within your delivery range who are most likely to purchase from your website or visit your physical store.\" He could also have checked the advertiser understands what “placement” means and provide some examples of placements. ",
      "Excellent throughout. Asked additional probinig quesitons based on feedback as well. ",
      "Simon did a great job as he added value to the client relationship and enhanced Meta's reputation throughout.  "
    ]
  },
  {
    "tabs": [
      "Call Control & Leadership",
      "Clear Communication",
      "Listening & Empathy",
      "Professionalism & Respect",
    ],
    "scores": [
      100,
      100,
      75,
      100
    ],
    "average": 94,
    "description": [
      "Excellent work. ",
      "Simon did a great job on the Pixel set up. He explained things clearly and used simple language that was easy to understand. ",
      "Good work over all. As above, in relation to the budget conversation,  it's important to listen and display empathy when the advertiser is sharing information, asking questions or sharing their concerns. ",
      "Excellent work. "
    ]
  },
  {
    "tabs": [
      "Call Control & Leadership",
      "Clear Communication",
      "Listening & Empathy",
      "Professionalism & Respect",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Sindy did an excellent job directing the call for the advertiser",
      "Sindy spoke clearly, free of unnecessary technical terminology.",
      "Sindy listened closely and empathised with the customer's goals and concerns.",
      "Sindy behaved with the utmost professionalism and respect for the customer."
    ]
  },
  {
    "tabs": [
      "Call Control & Leadership",
      "Clear Communication",
      "Listening & Empathy",
      "Professionalism & Respect",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Sindy did an excellent job directing the call for the advertiser",
      "Sindy spoke clearly, free of unnecessary technical terminology.",
      "Sindy listened closely and empathised with the customer's goals and concerns.",
      "Sindy behaved with the utmost professionalism and respect for the customer."
    ]
  },
  {
    "tabs": [
      "Call Control & Leadership",
      "Clear Communication",
      "Listening & Empathy",
      "Professionalism & Respect",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Sindy did an excellent job directing the call for the advertiser",
      "Sindy spoke clearly, free of unnecessary technical terminology.",
      "Sindy listened closely and empathised with the customer's goals and concerns.",
      "Sindy behaved with the utmost professionalism and respect for the customer."
    ]
  },
  {
    "tabs": [
      "Call Control & Leadership",
      "Clear Communication",
      "Listening & Empathy",
      "Professionalism & Respect",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Sindy did an excellent job directing the call for the advertiser",
      "Sindy spoke clearly, free of unnecessary technical terminology.",
      "Sindy listened closely and empathised with the customer's goals and concerns.",
      "Sindy behaved with the utmost professionalism and respect for the customer."
    ]
  },
  {
    "tabs": [
      "Call Control & Leadership",
      "Clear Communication",
      "Listening & Empathy",
      "Professionalism & Respect",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Simon did an excellent job directing the call for the advertiser",
      "Simon spoke clearly, free of unnecessary technical terminology.",
      "Simon listened closely and empathised with the customer's goals and concerns.",
      "Simon behaved with the utmost professionalism and respect for the customer."
    ]
  },
  {
    "tabs": [
      "Call Control & Leadership",
      "Clear Communication",
      "Listening & Empathy",
      "Professionalism & Respect",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Simon did an excellent job directing the call for the advertiser",
      "Simon spoke clearly, free of unnecessary technical terminology.",
      "Simon listened closely and empathised with the customer's goals and concerns.",
      "Simon behaved with the utmost professionalism and respect for the customer."
    ]
  }
] as const;