import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8TailoredSolutions() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!)
  }, [step])
  const navigate = useNavigate()


  return (
    <>
      {![2, 6].includes(step) && (
        <SphereVideo
          start={videoSplit["1"]["5"].start}
          subtitlesFile="./subtitles/1.vtt"
          end={videoSplit["1"]["5"].end}
          src={assets.video1}
          play={false}
        />
      )}
      <Title textContent="TAILORED SOLUTIONS" />
      {step === 0 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets[`./content/autogenerated/script1/tailored-step-0.png`]}
          subtitle="During Tailored Solutions:"
          descriptionImage={assets[`./content/autogenerated/shared/tailored-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets[`./content/autogenerated/script1/tailored-step-1.png`]}

          descriptionImage={assets[`./content/autogenerated/shared/tailored-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          start={videoSplit["1"]["5"].start}
          subtitlesFile="./subtitles/1.vtt"
          end={videoSplit["1"]["5"].end}
          src={assets.video1}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Tailored Solutions`}
          image={assets["./content/autogenerated/new-images-2/1-5-4.jpg"]}
          description={`While the logic behind Simon’s suggestion on broad targeting is solid, they could state this more simply, especially for a newer advertiser who is only starting out with Meta advertising.\n\n   On the next page, you will see an example of a simpler explanation.\n\n   Remember, it’s important to use the advertiser’s language, when possible. `}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <CardLarge
          title="Feedback"
          subtitle={`Tailored Solutions`}
          image={assets["./content/autogenerated/new-images-2/1-5-5.jpg"]}
          descriptionImage={assets["./content/autogenerated/script1/tailored-solutions-description.svg"]}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(5)} />]}
        />
      )}
      {step === 5 && (
        <MultipleChoiceQuestion
          subtitle={`Tailored Solutions`}
          image={assets[`./content/autogenerated/script1/tailored-step-4.png`]}
          imageCorrect={assets['./content/autogenerated/new-images/1-5-8-9.jpg']}
          imageIncorrect={assets['./content/autogenerated/new-images/1-5-8-9.jpg']}
          descriptionCorrect="Don’t use any overly technical explanations, jargon, or internal terms when you’re explaining concepts to an advertiser."
          question={`Which of these are a best practice for clear, simple communication?`}
          answers={[
            {
              answer: `Ask the advertiser for any terms that they prefer you avoid using at the start of each call.`,
              correct: false,
            },
            {
              answer: `Use a thesaurus to make sure you’re using sophisticated language to impress the advertiser.`,
              correct: false,
            },
            {
              answer: `Avoid using any overly technical jargon or language that we would only use internally.`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            //navigate(`/${params.moduleId}/call-closing`);
            setStep(6);
          }}
        />
      )}
      {step === 6 && (
        <SphereVideo
          start={videoSplit["1"]["6"].start}
          subtitlesFile="./subtitles/1.vtt"
          end={videoSplit["1"]["6"].end}
          src={assets.video1}
          onEnded={() => setStep(8)}
        />
      )}
      {step === 7 && (
        <CardLarge
          title="Feedback"
          subtitle={`Tailored Solutions`}
          image={assets[`./content/autogenerated/script1/tailored-step-6.png`]}
          description={`Simon gave an excellent explanation of the Pixel and how it can help the advertiser achieve their objectives by improving performance.\n\nSimon did a great job offering a collaborative walkthrough while they explained the targeting changes and Pixel setup.\n\nSimon gave a clear explanation of what Advantage Detailed Targeting is and how it works.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(7)} />]}
        />
      )}
      {step === 8 && (
        <MultipleChoiceQuestion
          subtitle={`Tailored Solutions`}
          image={assets[`./content/autogenerated/new-images/1-5-12-14.jpg`]}
          descriptionCorrect="Keep the advertiser’s goals in mind and refer to them throughout the entire conversation."
          question={`How could Simon have highlighted the value of this targeting approach more explicitly?`}
          answers={[
            {
              answer: `Share data from competitor accounts to prove the benefits of this targeting approach.`,
              correct: false,
            },
            {
              answer: `Link the targeting approach back to the advertiser's original goals.`,
              correct: true,
            },
            {
              answer: `Promise a refund to the advertiser as a guarantee of this targeting approach. `,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/call-closing`);
          }}
        />
      )}
    </>
  );
}
