import React from "react";
import assets from "@/config/assets";
import Card from "@/components/card";
import StartButton from "@/components/start-button";
import ArrowButton from "@/components/arrow-button";
import { useNavigate } from "react-router-dom";

export default function Module8Home() {
  const navigate = useNavigate();

  return (
    <>
      <Card
        title={`Experienced Advertiser Setting\nup Product Catalog`}
        titleFontSize={1 / 2.2}
        image={assets["./content/autogenerated/script8/home.png"]}
        descriptionFontSize={1 / 13}
        description={`This advertiser loves testing and scaling and wants to switch from partnering with Shopify to WooCommerce. When they do this, they plan to not reinstall the Meta Pixel but instead spend the time installing the Conversions API. They don’t see the value in the Pixel any more, because their goal is to use catalog ads to re-engage customers who abandoned their cart.`}
        justifyButtons="space-between"
        buttons={[
          <ArrowButton
            onDown={() => {
              navigate(`/?module-selection=true`);
            }}
            dir="prev"
          />,
          <StartButton moduleId="8" />,
        ]}
      />
    </>
  );
}
