import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8SecuringCommitment() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["3"]["6"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["6"].end}
          src={assets.video3}
          play={false}
        />
      )}
      <Title textContent="SECURING COMMITMENT" />
      {step === 0 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-0.png"]}
          subtitle="During Securing Commitment:"
          descriptionImage={assets[`./content/autogenerated/shared/securing-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-1.jpeg"]}
          descriptionImage={assets[`./content/autogenerated/shared/securing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          start={videoSplit["3"]["6"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["6"].end}
          src={assets.video3}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Securing Commitment`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-3.png"]}
          description={`Sindy walked the advertiser through the initial campaign set up and validated that they’re aligned on the approach. Most importantly, the timeline to have the creative ready was confirmed.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Securing Commitment`}
          image={assets["./content/autogenerated/new-images/3-6-1.jpg"]}
          imageIncorrect={assets["./content/autogenerated/script3/securing-incorrect.png"]}
          descriptionCorrect="It’s a good idea to schedule the next call to happen as soon as forward progress is unblocked. This helps ensure that the next call will be a relevant and valuable use of time."
          question={`Did Sindy choose a good time to schedule a follow-up call, in relation to the timeline for the creative?`}
          answers={[
            {
              answer: `No, they should have scheduled it just before the creative was due, to remind the advertiser that they need to finish it.`,
              correct: false,
            },
            {
              answer: `Yes, they scheduled it right after the creative would be ready, to maintain momentum and motivation.`,
              correct: true,
            },
            {
              answer: `Yes, any date after the creative is finished would be fine, as long as it’s in time for any upcoming seasonal events.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/call-closing`);
          }}
        />
      )}
    </>
  );
}
