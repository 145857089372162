import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8CommercialConversations() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const videoRotation = useVideoRotation([0, -40, 0]);

  return (
    <>
      {step !== 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["5"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["5"].end}
          src={assets.video2}
          play={false}
        />
      )}
      <Title textContent="COMMERCIAL CONVERSATIONS" />
      {step === 0 && (
        <CardLarge
          title={`Learn \nwhat’s possible`}
          subtitle="During Commercial Conversations:"
          image={assets[`./content/autogenerated/script1/commercial-step-0.png`]}
          descriptionImage={assets[`./content/autogenerated/shared/commercial-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Learn \nwhat’s possible`}
          image={assets[`./content/autogenerated/script2/commercial-step-1.jpeg`]}
          descriptionImage={assets[`./content/autogenerated/shared/commercial-2.svg`]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["5"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["5"].end}
          src={assets.video2}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Commercial Conversations`}
          image={assets[`./content/autogenerated/script1/commercial-step-3.png`]}
          description={`The solutions Simon chose were highly appropriate and particularly valuable as they're progressing the advertiser along a logical journey. Great work!\n\n   Simon explained the features of A/B Testing and Custom Audiences very well. However, they could have linked the benefits of each back to the advertisers goals even more strongly.\n\n   Connecting the benefits of a solution to the advertiser's needs helps drive action from advertisers.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Commercial Conversations`}
          image={assets[`./content/autogenerated/script2/commercial-question.png`]}
          imageCorrect={assets[`./content/autogenerated/script2/commercial-correct.png`]}
          imageIncorrect={assets[`./content/autogenerated/script2/commercial-incorrect.png`]}
          descriptionCorrect="With Custom Audiences, advertisers can retarget shoppers who have not completed a previous purchase, while driving down their cost per result."
          question={`For example, the advertiser wants to drive purchases. How does using Custom Audiences enable them to do that?`}
          answers={[
            {
              answer: `With Custom Audiences, they can retarget shoppers who have not completed a previous purchase. `,
              correct: true,
            },
            {
              answer: `With Custom Audiences, they can opt to deliver their ads in multiple placements for a broader reach. `,
              correct: false,
            },
            {
              answer: `With Custom Audiences, they can increase the frequency of how often their ads are shown to each audience member.`,
              correct: false,
            },
            {
              answer: `All of the above`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/securing-commitment`);
          }}
        />
      )}
    </>
  );
}
