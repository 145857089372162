import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8CommercialConversations() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -110, 0]}
          start={videoSplit["3"]["4"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["4"].end}
          src={assets.video3}
          play={false}
        />
      )}
      <Title textContent="COMMERCIAL CONVERSATIONS" />
      {step === 0 && (
        <CardLarge
          title={`Learn \nwhat’s possible`}
          subtitle="During Commercial Conversations:"
          image={assets[`./content/autogenerated/script1/commercial-step-0.png`]}
          descriptionImage={assets[`./content/autogenerated/shared/commercial-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Learn \nwhat’s possible`}
          image={assets[`./content/autogenerated/script2/commercial-step-1.jpeg`]}
          descriptionImage={assets[`./content/autogenerated/shared/commercial-2.svg`]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -110, 0]}
          start={videoSplit["3"]["4"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["4"].end}
          src={assets.video3}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Commercial Conversations`}
          image={assets["./content/autogenerated/new-images/3-4-4.jpg"]}
          description={`Sindy did well to ask about the overall marketing budget of $2500 per month.\n\nObjections about cost can often mask a true objection about perceived value for the money, so it’s great that Sindy justified their recommendations with the advertiser’s goals in mind.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Commercial Conversations`}
          image={assets[`./content/autogenerated/script3/commercial-question.png`]}
          imageCorrect={assets[`./content/autogenerated/script3/commercial-correct.png`]}
          imageIncorrect={assets[`./content/autogenerated/script3/commercial-incorrect.png`]}
          descriptionCorrect="It’s a good idea to explore the overall budget and marketing budget, the motivations and influences behind setting that budget, how it might change, and where else that budget is used."
          question={`What are some other budgeting topics Sindy could have asked about?`}
          answers={[
            {
              answer: `What other platforms and channels the advertiser invests in `,
              correct: false,
            },
            {
              answer: `If there are any longer term plans for adjusting the marketing budget`,
              correct: false,
            },
            {
              answer: `Who else influences budgeting decisions at the advertiser’s company `,
              correct: false,
            },
            {
              answer: `All of the above`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/tailored-solutions`);
          }}
        />
      )}
    </>
  );
}
