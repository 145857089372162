import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import { appState } from "@/state/app-state";

export default function Module8SecuringCommitment() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["6"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["6"].end}
          src={assets.video7}
          play={false}
        />
      )}
      <Title textContent="SECURING COMMITMENT" />
      {step === 0 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-0.png"]}
          subtitle="During Securing Commitment:"
          descriptionImage={assets[`./content/autogenerated/shared/securing-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}
          image={assets["./content/autogenerated/new-images/7-6-2.jpg"]}
          descriptionImage={assets[`./content/autogenerated/shared/securing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["6"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["6"].end}
          src={assets.video7}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Securing Commitment`}
          descriptionFontSize="34.3px"
          image={assets["./content/autogenerated/script7/securing-step-3.png"]}
          description={`Typically, securing commitment refers to the implementation of a specific solution, but in this case, Simon was correct to ask for a follow up call as the initial customer commitment. They are rebuilding trust here! Well done.\n\n   Simon also clearly gained some buy-in from the advertiser. This is largely due to good preparation before the call and the quality of earlier questions. Simon could also have asked the advertiser to complete a specific task (like reviewing how campaigns and ad sets might be consolidated) to further increase the likelihood of a follow-up call and to progress the relationship further. `}
          buttons={[<ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/call-closing`)} />]}
        />
      )}
    </>
  );
}
