import React from "react";
import { useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import scenes from "@/config/scenes";
import Progress from "@/components/progress";

export default function Scene() {
  const params = useParams<SceneParams>();
  const sceneId = React.useMemo(() => {
    return params.sceneId ?? "";
  }, [params.sceneId]);

  const SceneComponent = React.useMemo(() => {
    return scenes[params.moduleId!].find((scene) => scene.sceneId === sceneId)?.component ?? null;
  }, [params.moduleId, sceneId]);

  const [current, total] = React.useMemo(() => {
    const components = scenes[params.moduleId!];
    return [components.findIndex((scene) => scene.sceneId === sceneId) + 1, components.length];
  }, [params.moduleId, sceneId]);

  if (SceneComponent === null) {
    return <></>;
  }

  return (
    <>
      <SceneComponent />
      {sceneId !== "" && <Progress current={current - 1} total={total - 1} />}
    </>
  );
}
