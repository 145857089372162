import React from "react";
import { Layer, Interaction } from "react-xr-ui";
import { useXR } from "@react-three/xr";
import assets from "@/config/assets";
import ButtonInteraction from "./button-interaction";
import emitter from "@/services/emitter";
import { VR_OFFSET_Y, WEB_OFFSET_Y } from "@/config/consts";

type Props = {
  playing: boolean;
  progress: number;
  onPlayToggle: () => void;
  onFastForward: () => void;
  onRewind: () => void;
  onScrub: (percentage: number) => void;
};

export default function Player({ playing, progress, onPlayToggle, onFastForward, onRewind, onScrub }: Props) {
  const onPlayToggleRef = React.useRef(onPlayToggle);
  const onFastForwardRef = React.useRef(onFastForward);
  const onRewindRef = React.useRef(onRewind);
  React.useMemo(() => {
    onPlayToggleRef.current = onPlayToggle;
    onFastForwardRef.current = onFastForward;
    onRewindRef.current = onRewind;
  }, [onPlayToggle, onFastForward, onRewind]);

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.code === "Space") {
        onPlayToggleRef.current();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const isScrubbingRef = React.useRef(false);

  const isPresenting = useXR((state) => state.isPresenting);
  const offsetY = React.useMemo(() => {
    return isPresenting ? VR_OFFSET_Y : WEB_OFFSET_Y;
  }, [isPresenting]);

  return (
    <Layer
      zIndex={-1}
      aspectRatio={635 / 115}
      backgroundColor="#1C2B33"
      width="200%"
      height="40%"
      autoLayout={false}
      backgroundSize="contain"
      position={[0, 1.85 * offsetY, 0]}
      borderRadius={0.25}
      flexDirection="row"
      alignItems="end"
      justifyContent="start"
      padding={[0.1, 0.1, 0]}
      gap={0.05}
      borderColor=""
    >
      <PlayerButton
        onClick={() => {
          onPlayToggleRef.current();
        }}
        src={assets[`./content/autogenerated/player-${playing ? "pause" : "play"}.png`]}
      />
      <PlayerButton
        onClick={() => {
          onRewindRef.current();
        }}
        src={assets["./content/autogenerated/player-back.png"]}
      />
      <Layer height="40%" width="64%">
        <Layer
          backgroundColor="white"
          height="15%"
          width="100%"
          borderRadius={0.5}
          flexDirection="row"
          alignItems="start"
          justifyContent="start"
        >
          <Layer
            backgroundColor="#1877F2"
            height="100%"
            width={`${progress + 4}%`}
            borderRadius={0.5}
            justifyContent="end"
          >
            <Layer
              height="220%"
              aspectRatio={1}
              backgroundColor="white"
              borderRadius={0.5}
              borderColor="#1877F2"
              borderWidth={0.2}
            />
          </Layer>
        </Layer>
        <Interaction
          onDown={() => {
            isScrubbingRef.current = true;
            emitter.emit("controls.disable");
          }}
          onUp={() => {
            isScrubbingRef.current = false;
            emitter.emit("controls.enable");
          }}
          onOut={() => {
            isScrubbingRef.current = false;
            emitter.emit("controls.enable");
          }}
          onMove={(intersection) => {
            if (!isScrubbingRef.current) return;
            const x = intersection.uv?.x ?? 0;
            onScrub(x);
          }}
        >
          <Layer autoLayout={false} visible={false} backgroundColor="crimson" height="100%" width="100%" zIndex={1} />
        </Interaction>
      </Layer>
      <PlayerButton
        src={assets["./content/autogenerated/player-forward.png"]}
        onClick={() => {
          onFastForwardRef.current();
        }}
      />
    </Layer>
  );
}

type PlayerButtonProps = {
  src: string;
  onClick: () => void;
};

const PlayerButton = ({ src, onClick }: PlayerButtonProps) => {
  return (
    <ButtonInteraction onDown={onClick}>
      <Layer
        backgroundColor="#1877F2"
        backgroundImage={src}
        backgroundPosition={[0.5, 0.5]}
        aspectRatio={1}
        borderRadius={0.35}
        height="40%"
        borderColor="#1877F2"
      />
    </ButtonInteraction>
  );
};
