import React from "react";
import { Layer } from "react-xr-ui";
import FadeIn from "@/components/fade-in";
import Frame from "@/components/frame";
import assets from "@/config/assets";
import Button from "../../components/button";
import ButtonInteraction from "../../components/button-interaction";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

export default function CardModuleMenuComplete() {
  return (
    <Frame>
      <Layer
        height="100%"
        aspectRatio={1200 / 700}
        backgroundSize="contain"
        backgroundColor="rgba(255, 255, 255, 0.95)"
        autoLayout={false}
      >
        <FadeIn delay={700}>
          <Layer
            zIndex={2}
            height="10%"
            backgroundImage={assets["./content/autogenerated/meta-logo.svg"]}
            aspectRatio={167.64 / 71.7}
            autoLayout={false}
            width={`${(166 / 1200) * 100}%`}
            position={[-1.55, 0.9, 0]}
            backgroundSize="contain"
          />
        </FadeIn>
        <FadeIn delay={700 * 5}>
          <Layer
            zIndex={1}
            backgroundImage={assets["./content/autogenerated/menu-decoration.png"]}
            autoLayout={false}
            width={`100%`}
            position={[0, -2.26, 0]}
            aspectRatio={1200 / 700}
            backgroundSize="contain"
          />
        </FadeIn>
        <FadeIn delay={700 * 2}>
          <Layer
            zIndex={2}
            position={[0.245, 0.17, 0]}
            fontSize={0.2}
            width="100%"
            fontWeight="bold"
            color="#B2B2B2"
            autoLayout={false}
            textContent={`Congratulations`}
          />
        </FadeIn>
        <FadeIn delay={700 * 2.1}>
          <Layer
            zIndex={2}
            position={[0.245, -0.07, 0]}
            fontSize={0.2}
            width="100%"
            fontWeight="bold"
            color="black"
            autoLayout={false}
            textContent={`You've Completed Scaled\nShadowing 360`}
          />
        </FadeIn>
        <FadeIn delay={700 * 4}>
          <Layer
            flexDirection="row"
            zIndex={2}
            position={[-0.38, -1.4, 0]}
            autoLayout={false}
            width="65px"
            alignItems="start"
          >
            <Layer
              fontSize={"36px" as any}
              width="56%"
              height="7px"
              fontWeight="400"
              color="black"
              zIndex={4}
              textContent={`By using this website, you’re agreeing to the`}
            />
            <ButtonInteraction
              onDown={() => {
                window.open("https://www.facebook.com/terms.php", "_blank");
              }}
            >
              <Layer
                zIndex={4}
                fontSize={"36px" as any}
                width="50%"
                height="7px"
                fontWeight="400"
                color="#1877F2"
                textContent={`‎ Facebook Terms of Service`}
              />
            </ButtonInteraction>
          </Layer>
        </FadeIn>
      </Layer>
    </Frame>
  );
}
