import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8Discovery() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["5"]["3"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["3"].end}
          src={assets.video5}
          play={false}
        />
      )}
      <Title textContent="DISCOVERY" />
      {step === 0 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          subtitle="During Discovery: "
          image={assets["./content/autogenerated/script2/discovery-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Seek to \nunderstand`}

          image={assets["./content/autogenerated/new-images/5-3-2.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["5"]["3"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["3"].end}
          src={assets.video5}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Discovery & Connection"
          image={assets["./content/autogenerated/script2/discovery-step-3.png"]}
          descriptionImage={assets["./content/autogenerated/script5/discovery-description.svg"]}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Discovery`}
          image={assets["./content/autogenerated/new-images-2/5-3-question.jpg"]}
          imageCorrect={assets["./content/autogenerated/script2/discovery-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/script3/discovery-incorrect.png"]}
          descriptionCorrect="Being clear on the scope of their role and appropriately apologizing for this advertiser’s frustrations were great moves by Sindy."
          question={`What did Sindy do well to manage the advertiser’s expectations in this section?`}
          answers={[
            {
              answer: `They clarified the scope of their role to explain some of the limitations of information they can’t share or actions they can’t take.`,
              correct: false,
            },
            {
              answer: `They refocused the conversation on what they could offer the advertiser, like requesting an account quality review through proper channels.`,
              correct: false,
            },
            {
              answer: `They positioned themselves as the advertiser’s Meta representative by using “we” and apologizing on behalf of the company. `,
              correct: false,
            },
            {
              answer: `All of the above`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/commercial-conversations`);
          }}
        />
      )}
    </>
  );
}
