import React from "react";
import { Layer } from "react-xr-ui";
import SlideUp from "@/components/slide-up";
import FadeIn from "@/components/fade-in";
import Frame from "@/components/frame";
import { STACKED_TRANSITION_DELAY } from "@/config/consts";

type Props = {
  title: string;
  image: string;
  description: string;
  descriptionFontSize?: number;
  descriptionHeight?: `${number}%`;
  buttons: React.ReactNode[];
  contentAlignItems?: "end" | "center" | "start" | undefined;
  justifyButtons?: "end" | "center" | "start" | "space-between";
  titleFontSize?: number | `${number}px`;
};

export default function Card({
  justifyButtons = "space-between",
  title,
  image,
  description,
  descriptionHeight = "100%",
  descriptionFontSize = 1 / 12,
  contentAlignItems = "end",
  buttons,
  titleFontSize = .47,
}: Props) {
  return (
    <Frame>
      <Layer
        height="100%"
        aspectRatio={1200 / 584}
        flexDirection="column"
        justifyContent="start"
        padding={[0.1]}
        backgroundColor="rgba(255, 255, 255, 0.95)"
      >
        <SlideUp isVisible={false}>
          <Layer
            width="88.33333333333333%"
            height="24.205479452054796%"
            verticalAlign="center"
            fontSize={titleFontSize}
            color="#1C2B33"
            fontWeight="700"
            textContent={title}
          />
        </SlideUp>
        <Layer padding={[-0.2]} alignItems={contentAlignItems} width="100%" height="46.917808219178085%">
          <SlideUp delay={STACKED_TRANSITION_DELAY} isVisible={false}>
            <Layer
              width="40.33333333333333%"
              height="100%"
              backgroundSize="cover"
              backgroundPosition={[0.5, 0.5]}
              backgroundImage={image}
            />
          </SlideUp>
          <Layer width="3.7735849056603774%" height="100%" />
          <SlideUp delay={STACKED_TRANSITION_DELAY * 2} isVisible={false}>
            <Layer
              width="44.666666666666664%"
              height={descriptionHeight}
              fontSize={descriptionFontSize}
              lineHeight={1.65}
              color="#000000"
              fontWeight="300"
              textContent={description}
            />
          </SlideUp>
        </Layer>
        <Layer autoLayout={false} width="100%" height="100%" alignItems="end" justifyContent="end">
          <FadeIn delay={STACKED_TRANSITION_DELAY * 3}>
            <Layer width="100%" height="8.5%" justifyContent={justifyButtons} gap={0.01}>
              {buttons.map((button, index) => {
                return <React.Fragment key={index}>{button}</React.Fragment>;
              })}
            </Layer>
          </FadeIn>
        </Layer>
      </Layer>
    </Frame>
  );
}
