import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8Discovery() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const videoRotation = useVideoRotation([0, -40, 0]);

  return (
    <>
      {step !== 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["3"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["3"].end}
          src={assets.video2}
          play={false}
        />
      )}
      <Title textContent="DISCOVERY" />
      {step === 0 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          subtitle="During Discovery: "
          image={assets["./content/autogenerated/script2/discovery-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          image={assets["./content/autogenerated/script2/discovery-step-1.jpeg"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["3"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["3"].end}
          src={assets.video2}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Discovery & Connection"
          image={assets["./content/autogenerated/script2/discovery-step-3.png"]}
          descriptionFontSize={1 / 21}
          description={`Simon asked an “open” question to start and then acknowledged and responded to the advertiser's desire to focus on sales. This is a good example of listening and being aware of any hesitations or concerns that arise on calls. Well done.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Discovery`}
          image={assets["./content/autogenerated/script1/discovery-question.png"]}
          imageCorrect={assets["./content/autogenerated/script2/discovery-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/script2/discovery-incorrect.png"]}
          descriptionCorrect={`An open question requires a longer, more elaborate answer than just Yes or No.\n\nSimon could have probed further around the performance of the campaigns that were set up on the last call by asking more “structured, relevant and cohesive questions."\n\nIt's important to understand how each advertiser measures success and this was an ideal opportunity to do this before checking in about the Pixel Helper setup.`}
          question={`Which of these is an open question?`}
          answers={[
            { answer: `Have you tried using video for your creative?`, correct: false },
            {
              answer: `Do you have any additional budget to spend?`,
              correct: false,
            },
            {
              answer: `What’s been your targeting strategy so far?`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/tailored-solutions`);
          }}
        />
      )}
    </>
  );
}
