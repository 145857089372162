import autogeneratedAssets from "./autogenerated-assets";

const assets = {
  ...autogeneratedAssets,
  background: `./content/background.jpg`,
  home: `./content/home.png`,
  retake: `./assets/retake.png`,
  arrow: `./assets/arrow.png`,
  arrowBack: `./assets/arrow-back.png`,
  tick: `./assets/tick.png`,
  titleLogo: `./assets/logo-icon-b.png`,
  callOpeningStep1: `./content/call-opening-step-1.png`,
  callOpeningStep2: `./content/call-opening-step-2.jpg`,
  callOpeningStep4: `./content/call-opening-step-4.jpg`,
  discoveryStep1: `./content/discovery-step-1.jpg`,
  discoveryStep2: `./content/discovery-step-2.jpg`,
  discoveryStep4: `./content/discovery-step-4.jpg`,
  commercialConversationsStep1: `./content/commercial-conversations-step-1.png`,
  commercialConversationsStep2: `./content/commercial-conversations-step-2.png`,
  commercialConversationsStep4: `./content/commercial-conversations-step-4.png`,
  tailoredSolutionsStep1: `./content/tailored-solutions-step-1.png`,
  tailoredSolutionsStep2: `./content/tailored-solutions-step-2.png`,
  tailoredSolutionsStep4: `./content/tailored-solutions-step-4.png`,
  tailoredSolutionsStep5: `./content/tailored-solutions-step-5.png`,
  securingCommitmentStep1: `./content/securing-commitment-step-1.png`,
  securingCommitmentStep2: `./content/securing-commitment-step-2.jpg`,
  securingCommitmentStep4: `./content/securing-commitment-step-4.png`,
  callClosingStep1: `./content/call-closing-step-1.png`,
  callClosingStep2: `./content/call-closing-step-2.png`,
  callClosingStep4: `./content/call-closing-step-4.png`,
  video1: `./module-videos/1.mp4`,
  video2: `./module-videos/2.mp4`,
  video3: `./module-videos/3.mp4`,
  video4: `./module-videos/4.mp4`,
  video5: `./module-videos/5.mp4`,
  video6: `./module-videos/6.mp4`,
  video7: `./module-videos/7.mp4`,
  video8: `./module-videos/8.mp4`,
};

if (
  ["meta-over-the-shoulder-training.uat.finervision.com", "localhost"].includes(location.hostname) ||
  location.hostname.startsWith("192.168.")
) {
  assets.video1 = `https://meta-otst.s3.eu-west-2.amazonaws.com/1.mp4`;
  assets.video2 = `https://meta-otst.s3.eu-west-2.amazonaws.com/2.mp4`;
  assets.video3 = `https://meta-otst.s3.eu-west-2.amazonaws.com/3.mp4`;
  assets.video4 = `https://meta-otst.s3.eu-west-2.amazonaws.com/4.mp4`;
  assets.video5 = `https://meta-otst.s3.eu-west-2.amazonaws.com/5.mp4`;
  assets.video6 = `https://meta-otst.s3.eu-west-2.amazonaws.com/6.mp4`;
  assets.video7 = `https://meta-otst.s3.eu-west-2.amazonaws.com/7.mp4`;
  assets.video8 = `https://meta-otst.s3.eu-west-2.amazonaws.com/8.mp4`;
}

if (process.env.LOCAL_VIDEOS === "true") {
  assets.video1 = `./module-videos-hq/1.mp4`;
  assets.video2 = `./module-videos-hq/2.mp4`;
  assets.video3 = `./module-videos-hq/3.mp4`;
  assets.video4 = `./module-videos-hq/4.mp4`;
  assets.video5 = `./module-videos-hq/5.mp4`;
  assets.video6 = `./module-videos-hq/6.mp4`;
  assets.video7 = `./module-videos-hq/7.mp4`;
  assets.video8 = `./module-videos-hq/8.mp4`;
}

export default assets;
