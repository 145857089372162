import { create } from "zustand";
import XAPI, { type Agent } from "@xapi/xapi";
import type { Answer, Immerse, Session } from "@/types";
import { endSession, getSignedVideos, preload, startSession } from "@/utils";
import scenes from "@/config/scenes";
import assets from "@/config/assets";

const XAPI_STATE_COMPLETED_MODULE_IDS = "https://meta.com/xapi/SS360/data/completed-module-ids";
const XAPI_STATE_PROGRESS = "https://meta.com/xapi/SS360/data/progress";
const XAPI_STATE_ANSWERS = "https://meta.com/xapi/SS360/data/answers";

type AppState = {
  interacted: boolean;
  setInteracted: () => void;
  answers: {
    [moduleId: string]: {
      [sceneId: string]: Answer;
    };
  };
  setAnswer: (moduleId: string, sceneId: string, answer: Answer) => void;
  startSession: () => Promise<void>;
  endSession: (moduleId: string) => void;
  reset: () => void;
  session: Session | null;
  setSession: (session: AppState["session"]) => void;
  immerse: Immerse | null;
  setImmerse: (session: AppState["immerse"]) => void;
  menuStep: number;
  setMenuStep: (step: number) => void;
  name: string;
  setName: (name: AppState["name"]) => void;
  subtitlesEnabled: boolean;
  setSubtitlesEnabled: (subtitlesEnabled: AppState["subtitlesEnabled"]) => void;
  toggleSubtitlesEnabled: () => void;
  completedModuleIds: string[];
  completeModule: (moduleId: string) => void;
  progress: Record<string, Record<string, boolean>>;
  addProgress: (moduleId: string, sceneId: string) => void;
  init: () => Promise<void>;
};

const data = XAPI.getTinCanLaunchData();
let xapi: XAPI | undefined = undefined;
const agent: Agent | undefined = data.actor as Agent;
if (data.endpoint !== undefined && data.actor !== undefined) {
  xapi = new XAPI({ endpoint: data.endpoint, auth: data.auth });
}
// For debugging in SCORM Cloud
(window as any).data = data;
(window as any).agent = agent;
(window as any).XAPI = XAPI;
(window as any).xapi = xapi;

export const appState = create<AppState>((set, get) => {
  return {
    async init() {
      try {
        const { session, immerse } = await startSession();
        set({ session, immerse });
        if (process.env.NODE_ENV === "production") {
          preload().then(() => console.log("Preloaded"));
        }
      } catch (err) {
        console.error(err);
      }
      // todo: init immerse sdk
      if (xapi !== undefined && agent !== undefined) {
        set({ name: agent.name ?? "" });
        xapi
          .getState({ agent, activityId: data.activity_id ?? "", stateId: XAPI_STATE_COMPLETED_MODULE_IDS })
          .then((res: any) => {
            set({ completedModuleIds: res.data ?? [] });
          })
          .catch(console.error);
        xapi
          .getState({ agent, activityId: data.activity_id ?? "", stateId: XAPI_STATE_PROGRESS })
          .then((res: any) => {
            set({ progress: res.data ?? {} });
          })
          .catch(console.error);
        xapi
          .getState({ agent, activityId: data.activity_id ?? "", stateId: XAPI_STATE_ANSWERS })
          .then((res: any) => {
            set({ answers: res.data ?? {} });
          })
          .catch(console.error);
      }
    },
    progress: {},
    addProgress(moduleId, sceneId) {
      const { progress } = get();
      progress[moduleId] = { ...progress[moduleId], [sceneId]: true };
      set({ progress });
      if (xapi !== undefined && agent !== undefined) {
        xapi
          .setState({
            agent,
            activityId: data.activity_id ?? "",
            stateId: XAPI_STATE_PROGRESS,
            state: JSON.stringify(progress),
          })
          .catch(console.error);
      }
    },
    interacted: false,
    setInteracted() {
      set({ interacted: true });
    },
    answers: {},
    setAnswer(moduleId, sceneId, answer) {
      const { answers } = get();
      answers[moduleId] = {
        ...(answers[moduleId] ?? {}),
        [sceneId]: answer,
      };
      set({ answers });
      const { session, immerse } = get();
      if (session !== null) {
        try {
          const question = session.level(`${moduleId}-${sceneId}`);
          question.complete(immerse.verbs.completed, {
            completion: true,
            success: answer.correct,
            score: { min: 0, max: 1, raw: answer.correct ? 1 : 0 },
            response: answer.answer,
          });
        } catch {
          // Intentionally ignore Immersive errors
        }
      }
      if (xapi !== undefined && agent !== undefined) {
        xapi
          .sendStatement({
            statement: {
              actor: agent,
              verb: XAPI.Verbs.ANSWERED,
              object: {
                id: `https://meta.com/xapi/SS360/questions/${moduleId}-${sceneId}`,
                definition: {
                  name: {
                    "en-US": `${moduleId}-${sceneId}`,
                  },
                  description: {
                    "en-US": `Answer: ${answer.answer} (${answer.correct ? "correct" : "incorrect"})`,
                  },
                  type: "http://adlnet.gov/expapi/activities/question",
                },
              },
            },
          })
          .catch(console.error);
        xapi
          .setState({
            agent,
            activityId: data.activity_id ?? "",
            stateId: XAPI_STATE_ANSWERS,
            state: JSON.stringify(answers),
          })
          .catch(console.error);
      }
    },
    async startSession() {
      const { immerse, session } = get();
      if (immerse !== null && session !== null) return;
      try {
        const { session, immerse } = await startSession();
        set({ session, immerse });
      } catch (err) {
        console.error(err);
      }
    },
    endSession(moduleId) {
      endSession(moduleId);
    },
    reset() {
      set({ answers: {} });
    },
    session: null,
    setSession(session) {
      set({ session });
    },
    immerse: null,
    setImmerse(immerse) {
      set({ immerse });
    },
    menuStep: 0,
    setMenuStep(step) {
      set({ menuStep: step });
    },
    name: "",
    setName(name) {
      set({ name });
    },
    subtitlesEnabled: true,
    setSubtitlesEnabled(subtitlesEnabled) {
      set({ subtitlesEnabled });
    },
    toggleSubtitlesEnabled() {
      set({ subtitlesEnabled: !get().subtitlesEnabled });
    },
    completedModuleIds: [],
    completeModule(moduleId) {
      const completedModuleIds = [...get().completedModuleIds];
      if (!completedModuleIds.includes(moduleId)) {
        completedModuleIds.push(moduleId);
      }
      set({ completedModuleIds });

      const totalModules = Object.keys(scenes).length;

      const { session, immerse } = get();
      if (session !== null) {
        try {
          const question = session.level(moduleId);
          question.complete(immerse.verbs.completed, {
            completion: true,
            success: true,
            score: { min: 0, max: 1, raw: 1 },
            response: "Complete",
          });
        } catch {
          // Intentionally ignore Immersive errors
        }
      }

      if (xapi !== undefined && agent !== undefined) {
        const progress = completedModuleIds.length / totalModules;
        xapi
          .sendStatement({
            statement: {
              actor: agent,
              verb: XAPI.Verbs.PROGRESSED,
              object: {
                id: data.activity_id ?? "",
                definition: {
                  name: { "en-US": "Meta Over the Shoulder Training" },
                  type: "http://adlnet.gov/expapi/activities/course",
                },
              },
              result: {
                score: {
                  min: 0,
                  max: 100,
                  raw: progress * 100,
                  scaled: progress,
                },
                completion: progress === 1,
              },
            },
          })
          .catch(console.error);
        if (progress === 1) {
          xapi
            .sendStatement({
              statement: {
                actor: agent,
                verb: XAPI.Verbs.COMPLETED,
                object: {
                  id: data.activity_id ?? "",
                  definition: {
                    name: { "en-US": "Meta Over the Shoulder Training" },
                    type: "http://adlnet.gov/expapi/activities/course",
                  },
                },
                result: {
                  score: {
                    min: 0,
                    max: 100,
                    raw: 100,
                    scaled: 1,
                  },
                  completion: true,
                },
              },
            })
            .catch(console.error);
          xapi
            .sendStatement({
              statement: {
                actor: agent,
                verb: XAPI.Verbs.PASSED,
                object: {
                  id: data.activity_id ?? "",
                  definition: {
                    name: { "en-US": "Meta Over the Shoulder Training" },
                    type: "http://adlnet.gov/expapi/activities/course",
                  },
                },
                result: {
                  score: {
                    min: 0,
                    max: 100,
                    raw: 100,
                    scaled: 1,
                  },
                  completion: true,
                  success: true,
                },
              },
            })
            .catch(console.error);
        }
        xapi
          .setState({
            agent,
            activityId: data.activity_id ?? "",
            stateId: XAPI_STATE_COMPLETED_MODULE_IDS,
            state: JSON.stringify(completedModuleIds),
          })
          .catch(console.error);
      }
    },
  };
});
