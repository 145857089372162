import React from "react";
import { useThree } from "@react-three/fiber";
import { Layer } from "react-xr-ui";
import Static from "@/components/static";
import SlideUp from "@/components/slide-up";

type Props = {
  children?: React.ReactNode;
  heightScale?: number;
  aspectRatio?: number;
};

export default function Frame({ children, heightScale = 0.5, aspectRatio = 1200 / 584 }: Props) {
  const viewport = useThree((state) => state.viewport);
  const height = React.useMemo(() => {
    return viewport.height * heightScale;
  }, [viewport, heightScale]);

  return (
    <SlideUp>
      <Static>
        <Layer
          height={height}
          aspectRatio={aspectRatio}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Layer>
      </Static>
    </SlideUp>
  );
}
