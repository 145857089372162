import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import { appState } from "@/state/app-state";

export default function Module8CommercialConversations() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {step !== 2 && (
        <SphereVideo
          start={videoSplit["1"]["4"].start}
          subtitlesFile="./subtitles/1.vtt"
          end={videoSplit["1"]["4"].end}
          src={assets.video1}
          play={false}
        />
      )}
      <Title textContent="COMMERCIAL CONVERSATIONS" />
      {step === 0 && (
        <CardLarge
          title={`Learn \nwhat’s possible`}
          subtitle="During Commercial Conversations:"
          image={assets[`./content/autogenerated/script1/commercial-step-0.png`]}
          descriptionImage={assets[`./content/autogenerated/shared/commercial-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Learn \nwhat’s possible`}
          image={assets[`./content/autogenerated/script1/call-opening-step-1.png`]}
          descriptionImage={assets[`./content/autogenerated/shared/commercial-2.svg`]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          start={videoSplit["1"]["4"].start}
          subtitlesFile="./subtitles/1.vtt"
          end={videoSplit["1"]["4"].end}
          src={assets.video1}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Commercial Conversations`}
          image={assets[`./content/autogenerated/script1/commercial-step-3.png`]}
          description={`Simon did a great job making recommendations based on the KB and explaining how the recommended budget can improve campaign performance.\n\n   Simon could also refer to previous spend information in Ads Manager and ask the advertiser to validate it with additional information or context.\n\n   Simon could have probed more to deepen their understanding of the client's overall marketing budget, to learn about any constraints, channel specifics, competitor influences, and short or long term plans.`}
          buttons={[<ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/tailored-solutions`)} />]}
        />
      )}
    </>
  );
}
