import React from "react";
import { Interaction } from "react-xr-ui";

type Props = {
  children?: React.ReactNode;
  onOver?: () => void;
  onOut?: () => void;
  onDown?: () => void;
  onUp?: () => void;
  enabled?: boolean;
  disableCursor?: boolean;
};

export default function ButtonInteraction({ disableCursor, children, onOver, onOut, onDown, onUp, enabled = true }: Props) {
  return (
    <Interaction
      enabled={enabled}
      onOver={() => {
        onOver?.();
        document.body.style.cursor = disableCursor ? "auto" : "pointer";
      }}
      onOut={() => {
        onOut?.();
        document.body.style.cursor = "auto";
      }}
      onDown={() => {
        onDown?.();
        document.body.style.cursor = "auto";
      }}
      onUp={onUp}
    >
      {children}
    </Interaction>
  );
}
