import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8CommercialConversations() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["5"]["4"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["4"].end}
          src={assets.video5}
          play={false}
        />
      )}
      <Title textContent="COMMERCIAL CONVERSATIONS" />
      {step === 0 && (
        <CardLarge
          title={`Learn \nwhat’s possible`}
          subtitle="During Commercial Conversations:"
          image={assets[`./content/autogenerated/script1/commercial-step-0.png`]}
          descriptionImage={assets[`./content/autogenerated/shared/commercial-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Learn \nwhat’s possible`}
          image={assets[`./content/autogenerated/script2/commercial-step-1.jpeg`]}

          descriptionImage={assets[`./content/autogenerated/shared/commercial-2.svg`]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["5"]["4"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["4"].end}
          src={assets.video5}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Commercial Conversations`}
          image={assets["./content/autogenerated/new-images/5-4-4.jpg"]}
          description={`Sindy did well here to connect the advertiser’s blocked account issue to the overall ad account and how campaigns are set up. Sindy also explained how this behavior can lead to higher CPAs and inefficient spending of the budget.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Commercial Conversations`}
          image={assets[`./content/autogenerated/script2/commercial-question.png`]}
          imageCorrect={assets[`./content/autogenerated/script5/commercial-correct.png`]}
          imageIncorrect={assets[`./content/autogenerated/script2/commercial-incorrect.png`]}
          descriptionCorrect="Sindy likely made the right decision not to go too deeply into the budget conversation, due to the advertiser still being quite upset. It will be important to circle back to this topic on subsequent calls, as providing spend recommendations and influencing budget decisions are crucial in helping advertisers drive positive results."
          question={`Sindy didn’t go too deeply into any budget conversations. Was this a good call?`}
          answers={[
            {
              answer: `Yes, this advertiser is too frustrated for a budget discussion right now.`,
              correct: true,
            },
            {
              answer: `No, budget conversations are critical and should be part of every call.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/tailored-solutions`);
          }}
        />
      )}
    </>
  );
}
