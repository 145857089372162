import React from "react";
import { Layer } from "react-xr-ui";
import SlideUp from "@/components/slide-up";
import assets from "@/config/assets";
import RetakeButton from "@/components/retake-button";
import { STACKED_TRANSITION_DELAY } from "@/config/consts";
import ArrowButton from "../arrow-button";
import FadeIn from "../fade-in";

type Props = {
  subtitle: string;
  description: string;
  image: string;
  correct: boolean;
  onContinue: () => void;
  onRetake: () => void;
};

export default function Result({ subtitle, description, image, correct, onContinue, onRetake }: Props) {
  if (correct) {
    return (
      <Layer height="100%" aspectRatio={1200 / 700} backgroundColor="rgba(255, 255, 255, 0.95)" justifyContent="center">
        <Layer width="58.5%" height="100%" flexDirection="column" alignItems="start" justifyContent="start">
          <Layer width="100%" height="8.285714285714285%" />
          <SlideUp isVisible={false}>
            <Layer
              width="100%"
              height="8.571428571428571%"
              fontSize={1}
              fontWeight="800"
              lineHeight={1}
              textContent={`That’s Correct!`}
              color="#1C2B33"
            />
          </SlideUp>
          <Layer width="100%" height="5%" />
          <SlideUp delay={STACKED_TRANSITION_DELAY} isVisible={false}>
            <Layer
              width="100%"
              height="4.714285714285714%"
              fontWeight="700"
              textContent={subtitle}
              fontSize={1 / 1.3}
              color="#000000"
            />
          </SlideUp>
          <Layer width="100%" height="5%" />
          <SlideUp delay={STACKED_TRANSITION_DELAY * 2} isVisible={false}>
            <Layer
              width="100%"
              height="60%"
              textContent={description}
              fontSize={1 / 18}
              lineHeight={1.65}
              color="#000000"
              flexDirection="column"
              alignItems="start"
              justifyContent="start"
            />
          </SlideUp>
          <Layer width="100%" height="2.2857142857142856%" />
        </Layer>
        <SlideUp delay={STACKED_TRANSITION_DELAY * 4} isVisible={false}>
          <Layer
            width="31%"
            height="100%"
            backgroundSize="cover"
            backgroundPosition={[0.5, 0.5]}
            backgroundImage={image}
          />
        </SlideUp>
        <Layer autoLayout={false} width="100%" height="100%" alignItems="end" justifyContent="end">
          <FadeIn delay={STACKED_TRANSITION_DELAY * 3}>
            <Layer width="100%" height="8.5%" justifyContent="end" gap={0.01}>
              <ArrowButton dir="next" onDown={onContinue} />
            </Layer>
          </FadeIn>
        </Layer>
      </Layer>
    );
  }

  return (
    <Layer
      height="100%"
      aspectRatio={1200 / 700}
      backgroundColor="rgba(255, 255, 255, 0.95)"
      justifyContent="space-around"
    >
      <Layer width="47.91666666666667%" height="100%" flexDirection="column" alignItems="start" justifyContent="start">
        <Layer width="100%" height="8.285714285714285%" />
        <SlideUp isVisible={false}>
          <Layer
            width="100%"
            alignItems="center"
            justifyContent="center"
            verticalAlign="center"
            height="9%"
            fontSize="100px"
            fontWeight="800"
            lineHeight={1}
            textContent={`Not Quite...`}
            color="#1C2B33"
          />
        </SlideUp>
        <Layer width="100%" height="2.7142857142857144%" />
        <SlideUp delay={STACKED_TRANSITION_DELAY} isVisible={false}>
          <Layer
            width="100%"
            height="4.714285714285714%"
            textContent={`Try Again!`}
            fontSize={1 / 1.3}
            color="#000000"
          />
        </SlideUp>
        <Layer width="100%" height="2.2857142857142856%" />
        <SlideUp delay={STACKED_TRANSITION_DELAY * 2} isVisible={false}>
          <Layer width="100%" height="40%">
            <Layer
              backgroundImage={assets.retake}
              backgroundSize="contain"
              backgroundPosition={[0.5, 0.5]}
              aspectRatio={285 / 235}
              height="100%"
            />
          </Layer>
        </SlideUp>
        <Layer width="100%" height="2.2857142857142856%" />
        <SlideUp delay={STACKED_TRANSITION_DELAY * 3} isVisible={false}>
          <Layer width="100%" height="15%">
            <RetakeButton onDown={onRetake} />
          </Layer>
        </SlideUp>
      </Layer>
      <SlideUp delay={STACKED_TRANSITION_DELAY * 4} isVisible={false}>
        <Layer
          width="36%"
          height="100%"
          backgroundSize="cover"
          backgroundPosition={[0.5, 0.5]}
          backgroundImage={image}
        />
      </SlideUp>
    </Layer>
  );
}
