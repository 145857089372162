import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8CallOpening() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const [videoSrc, setVideoSrc] = React.useState(assets.video7);

  return (
    <>
      {![0, 3].includes(step) && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["1"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["1"].end}
          src={videoSrc}
          play={false}
        />
      )}
      <Title textContent="CALL OPENING" />
      {step === 0 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["1"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["1"].end}
          src={assets.video7}
          onEnded={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Showing \nup prepared`}
          subtitle="In the call opening: "
          image={assets["./content/autogenerated/new-images/7-2-1.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/call-opening-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(2)} />, <ArrowButton dir="next" onDown={() => setStep(3)} />]}
        />
      )}
      {step === 2 && (
        <CardLarge
          title={`Showing \nup prepared`}
          image={assets["./content/autogenerated/script2/call-opening-step-2.jpeg"]}
          subtitle="Examples:"
          descriptionImage={assets["./content/autogenerated/shared/call-opening-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(3)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 3 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["2"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["2"].end}
          src={assets.video7}
          onEnded={() => setStep(4)}
        />
      )}
      {step === 4 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Call Opening"
          image={assets["./content/autogenerated/new-images/6-2-4.jpg"]}
          description={`Simon showcased their preparation for the conversation by referring to previous interactions with Meta, demonstrating an understanding of the overall business model and their current use of conversions API. This helped begin to establish credibility, which for this advertiser is key! Simon also demonstrated enthusiasm for the business and acknowledged the advertiser’s experience with their previous Meta Pro. Simon could have shared a more detailed agenda to hook the advertiser, even before verifying the Ad Account and letting the customer know the call will be recorded.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(5)} />]}
        />
      )}
      {step === 5 && (
        <MultipleChoiceQuestion
          subtitle={`Call Opening`}
          image={assets["./content/autogenerated/script7/call-opening-question.png"]}
          imageCorrect={assets["./content/autogenerated/script7/call-opening-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/script7/call-opening-incorrect.png"]}
          descriptionCorrect="As Simon demonstrated, you should offer genuine empathy for the experience the advertiser had with a previous Meta Pro."
          question={`What tone is most appropriate to talk about past negative experiences an advertiser had with another Meta Pro?`}
          answers={[
            { answer: `It’s best to avoid this topic, entirely, if they don’t bring it up first.`, correct: false },
            {
              answer: `Show genuine empathy for the experience they had with their previous Meta Pro.`,
              correct: true,
            },
            {
              answer: `Explain how you are very different from the other Meta Pro, who wasn’t very good.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/discovery`);
          }}
        />
      )}
    </>
  );
}
