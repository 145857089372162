import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import { appState } from "@/state/app-state";

export default function Module8Discovery() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["3"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["3"].end}
          src={assets.video7}
          play={false}
        />
      )}
      <Title textContent="DISCOVERY" />
      {step === 0 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          subtitle="During Discovery: "
          image={assets["./content/autogenerated/script2/discovery-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          image={assets["./content/autogenerated/new-images-2/7-3-2.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["3"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["3"].end}
          src={assets.video7}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Discovery & Connection"
          image={assets["./content/autogenerated/new-images/7-3-4.jpg"]}
          description={`The questions Simon asked were relevant to the advertiser, largely due to the prep they did for the call. This is a good example of Simon using the Discovery phase to deepen and validate their knowledge of the business and their goals.\n\n   Staying connected to the advertiser’s needs is important, particularly in this instance where the advertiser has had a less valuable experience in the past.`}
          buttons={[<ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/commercial-conversations`)} />]}
        />
      )}
    </>
  );
}
