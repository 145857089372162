import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import videoSplit from "@/config/video-split";
import ExamplesButton from "@/components/examples-button";
import { appState } from "@/state/app-state";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8SecuringCommitment() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const videoRotation = useVideoRotation([0, -40, 0]);

  return (
    <>
      {step !== 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["6"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["6"].end}
          src={assets.video2}
          play={false}
        />
      )}
      <Title textContent="SECURING COMMITMENT" />
      {step === 0 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}
          image={assets["./content/autogenerated/new-images-2/2-7-1.jpg"]}
          subtitle="During Securing Commitment:"
          descriptionImage={assets[`./content/autogenerated/shared/securing-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Confirm actions \nand timelines`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-1.jpeg"]}
          descriptionImage={assets[`./content/autogenerated/shared/securing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["6"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["6"].end}
          src={assets.video2}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Securing Commitment`}
          image={assets["./content/autogenerated/script2/securing-commitment-step-3.png"]}
          description={`Great job seeking a specific timeline to complete the campaign setup!\n\n   This can often help with planning when to schedule the next call as well, when it’s most appropriate to review results or advise the advertiser on the next step of their journey.`}
          buttons={[<ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/call-closing`)} />]}
        />
      )}
    </>
  );
}
