import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8CallOpening() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!)
  }, [step])
  const navigate = useNavigate()



  const [videoSrc, setVideoSrc] = React.useState(assets.video1);

  return (
    <>
      {![0, 3].includes(step) && (
        <SphereVideo start={videoSplit["1"]["1"].start} end={videoSplit["1"]["1"].end} src={videoSrc} play={false} />
      )}
      <Title textContent="CALL OPENING" />
      {step === 0 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["1"]["1"].start}
          end={videoSplit["1"]["1"].end}
          subtitlesFile="./subtitles/1.vtt"
          src={assets.video1}
          onEnded={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Showing \nup prepared`}
          image={assets["./content/autogenerated/new-images/1-2-1.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/call-opening-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(2)} />, <ArrowButton dir="next" onDown={() => setStep(3)} />]}
        />
      )}
      {step === 2 && (
        <CardLarge
          title={`Showing \nup prepared`}
          image={assets["./content/autogenerated/script1/call-opening-step-2.jpg"]}
          subtitle="Examples:"
          descriptionImage={assets["./content/autogenerated/shared/call-opening-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(3)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 3 && (
        <SphereVideo
          start={videoSplit["1"]["2"].start}
          end={videoSplit["1"]["2"].end}
          subtitlesFile="./subtitles/1.vtt"
          src={assets.video1}
          onEnded={() => setStep(4)}
        />
      )}
      {step === 4 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Call Opening"
          image={assets["./content/autogenerated/new-images-2/1-2-4.png"]}
          description={`Simon explained the program well and set an agenda for the call.\n\n    However, during the call opening, Simon could have demonstrated additional knowledge specific to the client's business, such as their challenges, products and priorities.`}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(3)} />,
            <ArrowButton dir="next" onDown={() => setStep(5)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 5 && (
        <MultipleChoiceQuestion
          subtitle={`Call Opening`}
          image={assets["./content/autogenerated/new-images/1-2-5-7.jpg"]}
          descriptionCorrect={`Sharing what you learned as you prepared for the call is important to build trust and establish credibility at the start of each conversation.\n\nThis references the Trust Equation:\n\nCredibility + Reliability + Intimacy / Self-orientation = Trust`}
          question={`Why is it important to communicate existing knowledge of the advertiser's situation?`}
          answers={[
            {
              answer: `This saves time, so you don't have to listen to the advertiser telling you about their business goals.`,
              correct: false,
            },
            {
              answer: `This builds trust because the advertiser finds you credible and understands that you have the context you need to help them.`,
              correct: true,
            },
            {
              answer: `This lends structure, so the advertiser understands what topics are on your agenda and available for discussion.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/discovery`);
          }}
        />
      )}
    </>
  );
}
