import React from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { appState } from "@/state/app-state";
import { parseSubtitles } from "@/utils";
import type { ParsedSubtitle } from "@/types";
import Static from "@/components/static";
import { Layer } from "react-xr-ui";
import Player from "./player";
import emitter from "@/services/emitter";
import { mapLinear } from "three/src/math/MathUtils";
import { useXR } from "@react-three/xr";
import { VR_OFFSET_Y, WEB_OFFSET_Y } from "@/config/consts";

type Props = {
  src: string;
  play?: boolean;
  onEnded?: () => void;
  subtitlesFile?: string;
  start: `${number}:${number}:${number}.${number}`;
  end: `${number}:${number}:${number}.${number}`;
  rotation?: [x: number, y: number, z: number];
};

function timeToSeconds(time: string) {
  const [hours, minutes, seconds] = time.split(":").map((unit) => parseFloat(unit));
  return hours * 3600 + minutes * 60 + seconds;
}

export default function SphereVideo({ start, end, src, play = true, onEnded, subtitlesFile, rotation }: Props) {
  const onEndedRef = React.useRef(onEnded);
  React.useMemo(() => {
    onEndedRef.current = onEnded;
  }, [onEnded]);
  const viewport = useThree((state) => state.viewport);
  const interacted = appState((state) => state.interacted);

  const parsedSubtitlesRef = React.useRef<ParsedSubtitle[]>([]);
  const [subtitleLine, setSubtitleLine] = React.useState("");
  const subtitleLineRef = React.useRef("");
  React.useMemo(() => {
    subtitleLineRef.current = subtitleLine;
  }, [subtitleLine]);
  const [progressPercent, setProgressPercent] = React.useState(0);

  const subtitlesEnabled = appState((state) => state.subtitlesEnabled);

  React.useEffect(() => {
    if (subtitlesFile === undefined) return;
    fetch(subtitlesFile)
      .then((res) => res.text())
      .then((subtitles) => {
        parsedSubtitlesRef.current = [...parseSubtitles(subtitles)];
        setSubtitleLine("");
      })
      .catch(console.error);
  }, [subtitlesFile, start, end]);

  const video = React.useMemo(() => {
    const video = document.createElement("video");
    video.muted = true;
    video.autoplay = false;
    video.crossOrigin = "anonymous";
    video.src = src;
    return video;
  }, [src]);

  const texture = React.useMemo(() => {
    return new THREE.VideoTexture(video);
  }, [video]);

  const [playing, setPlaying] = React.useState(play);

  React.useEffect(() => {
    video.muted = !interacted;

    const startTimeInSeconds = timeToSeconds(start);
    const endTimeInSeconds = timeToSeconds(end);

    let loaded = false;

    function onLoadedMetadata() {
      if (loaded) return;
      emitter.emit("controls.reset");
      loaded = true;
      video.currentTime = startTimeInSeconds;
      if (play) {
        video
          .play()
          .then(() => setPlaying(true))
          .catch((err) => console.error(err));
      }
    }

    function onTimeupdate() {
      const startTimeInSeconds = timeToSeconds(start);
      const endTimeInSeconds = timeToSeconds(end);
      const duration = endTimeInSeconds - startTimeInSeconds;
      const currentProgress = (video.currentTime - startTimeInSeconds) / duration;
      setProgressPercent(currentProgress * 100);

      if (video.currentTime >= endTimeInSeconds) {
        video.pause();
        setPlaying(false);
        onEndedRef.current?.();
      }
    }

    function onKeyDown(event: KeyboardEvent) {
      const key = event.key.toLowerCase();
      if (key === "k") {
        video.currentTime = endTimeInSeconds;
      }
    }

    const timeout = setTimeout(onLoadedMetadata, 250);
    video.addEventListener("loadedmetadata", onLoadedMetadata);
    video.addEventListener("timeupdate", onTimeupdate);
    window.addEventListener("keydown", onKeyDown);
    return () => {
      video.removeEventListener("loadedmetadata", onLoadedMetadata);
      video.removeEventListener("timeupdate", onTimeupdate);
      window.removeEventListener("keydown", onKeyDown);
      video.pause();
      setPlaying(false);
      video.remove();
      clearTimeout(timeout);
    };
  }, [interacted, play, video, start, end]);

  const scrubDebounceTimeoutRef = React.useRef<NodeJS.Timeout>();
  const scrubbingRef = React.useRef(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(scrubDebounceTimeoutRef.current);
    };
  }, []);

  React.useEffect(() => {
    function onTimeupdate() {
      if (scrubbingRef.current) return;
      if (parsedSubtitlesRef.current.length === 0) {
        return setSubtitleLine("");
      }
      if (video.currentTime > parsedSubtitlesRef.current[parsedSubtitlesRef.current.length - 1].to) {
        return setSubtitleLine("");
      }
      for (const parsedSubtitle of parsedSubtitlesRef.current) {
        const line = parsedSubtitle.lines.join("\n");
        if (subtitleLineRef.current === line) continue;
        if (video.currentTime >= parsedSubtitle.from && video.currentTime <= parsedSubtitle.to) {
          setSubtitleLine(line);
        }
      }
    }

    video.addEventListener("timeupdate", onTimeupdate);
    return () => {
      video.removeEventListener("timeupdate", onTimeupdate);
    };
  }, [video]);

  const updatedRotation = React.useMemo(() => {
    if (rotation === undefined) return [0, THREE.MathUtils.degToRad(-80), 0] as Props["rotation"];
    return rotation.map((value) => THREE.MathUtils.degToRad(value)) as Props["rotation"];
  }, [rotation]);

  const session = useXR((state) => state.session);

  const layersSupported = React.useMemo(() => {
    // return "XRMediaBinding" in window;
    return false;
  }, []);

  const mediaLayerRef = React.useRef<XREquirectLayer | null>(null);
  const referenceSpaceRef = React.useRef<XRReferenceSpace | XRBoundedReferenceSpace | null>(null);

  const xrMediaFactoryRef = React.useRef<XRMediaBinding | null>(null);

  const recreatingMediaLayerRef = React.useRef(false);

  const recreateMediaLayer = React.useCallback(async () => {
    if (!layersSupported) return;
    if (session === null) return;
    if (recreatingMediaLayerRef.current) return;
    recreatingMediaLayerRef.current = true;
    try {
      mediaLayerRef.current?.destroy();
      if (xrMediaFactoryRef.current === null) {
        xrMediaFactoryRef.current = new XRMediaBinding(session);
      }
      if (referenceSpaceRef.current === null) {
        referenceSpaceRef.current = await session.requestReferenceSpace("local");
      }
      if (session.renderState.layers === undefined) return;
      if (xrMediaFactoryRef.current === null) return;
      const zoomDistance = 3;
      mediaLayerRef.current = xrMediaFactoryRef.current.createEquirectLayer(video, {
        space: referenceSpaceRef.current!.getOffsetReferenceSpace(
          new XRRigidTransform(new DOMPointReadOnly(0, 0, zoomDistance, 1), {
            x: updatedRotation?.[0] ?? 0,
            y: (updatedRotation?.[1] ?? 0) * -1,
            z: updatedRotation?.[2] ?? 0,
            w: 1,
          })
        ),
        radius: viewport.height * 2,
        layout: "mono",
      });
      await session.updateRenderState({ layers: [mediaLayerRef.current, ...session.renderState.layers] });
    } catch (err) {
      console.error(err);
    }
    recreatingMediaLayerRef.current = false;
  }, [layersSupported, session, updatedRotation, viewport.height]);

  React.useEffect(() => {
    if (!layersSupported) return;
    recreateMediaLayer().finally(() => console.log("recreateMediaLayer"));
    video.addEventListener("loadstart", recreateMediaLayer);
    video.addEventListener("canplay", recreateMediaLayer);
    video.addEventListener("canplaythrough", recreateMediaLayer);
    return () => {
      video.removeEventListener("loadstart", recreateMediaLayer);
      video.removeEventListener("canplay", recreateMediaLayer);
      video.removeEventListener("canplaythrough", recreateMediaLayer);
    };
  }, [layersSupported, recreateMediaLayer]);

  React.useEffect(() => {
    if (!layersSupported) return;
    return () => {
      mediaLayerRef.current?.destroy();
      if (session !== null && session.renderState.layers !== undefined) {
        session.updateRenderState({ layers: [...session.renderState.layers] });
      }
    };
  }, [layersSupported, session]);

  const isPresenting = useXR((state) => state.isPresenting);
  const offsetY = React.useMemo(() => {
    return isPresenting ? VR_OFFSET_Y : WEB_OFFSET_Y;
  }, [isPresenting]);

  return (
    <>
      {play && (
        <Static>
          <Player
            onScrub={(progress) => {
              scrubbingRef.current = true;
              if (!video.paused) {
                video.pause();
              }
              const startTimeInSeconds = timeToSeconds(start);
              const endTimeInSeconds = timeToSeconds(end);
              const duration = endTimeInSeconds - startTimeInSeconds;
              video.currentTime = mapLinear(progress, 0, 1, startTimeInSeconds, endTimeInSeconds);
              const currentProgress = (video.currentTime - startTimeInSeconds) / duration;
              setProgressPercent(currentProgress * 100);
              if (video.currentTime >= endTimeInSeconds) {
                video.pause();
                setPlaying(false);
                onEndedRef.current?.();
                scrubbingRef.current = false;
                return;
              }
              clearTimeout(scrubDebounceTimeoutRef.current);
              scrubDebounceTimeoutRef.current = setTimeout(() => {
                scrubbingRef.current = false;
                if (video.paused) {
                  video.play().catch(console.error);
                }
              }, 250);
            }}
            playing={playing}
            onPlayToggle={() => {
              if (video.paused) {
                video
                  .play()
                  .then(() => setPlaying(true))
                  .catch((err) => console.error(err));
              } else {
                video.pause();
                setPlaying(false);
              }
            }}
            onFastForward={() => {
              video.currentTime = Math.min(timeToSeconds(end), video.currentTime + 15);
            }}
            onRewind={() => {
              video.currentTime = Math.max(timeToSeconds(start), video.currentTime - 15);
            }}
            progress={progressPercent}
          />
        </Static>
      )}
      <group rotation={updatedRotation}>
        {!layersSupported && (
          <mesh scale-x={-1} renderOrder={0}>
            <sphereGeometry args={[viewport.height * 2, 32, 32]} />
            <meshBasicMaterial depthWrite={false} map={texture} side={THREE.BackSide} />
          </mesh>
        )}
      </group>
      {play !== false && subtitleLine !== "" && subtitlesEnabled && (
        <Static>
          <Layer
            position-y={(viewport.height / -2 + 0.25 + 0.6) * offsetY}
            width={3}
            height={0.5}
            backgroundColor="rgba(0, 0, 0, 0.75)"
            color="white"
            textAlign="center"
            verticalAlign="middle"
            fontSize="50px"
            textContent={subtitleLine}
          />
        </Static>
      )}
    </>
  );
}
