import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8TailoredSolutions() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["5"]["5"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["5"].end}
          src={assets.video5}
          play={false}
        />
      )}
      <Title textContent="TAILORED SOLUTIONS" />
      {step === 0 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets[`./content/autogenerated/script1/tailored-step-0.png`]}
          subtitle="During Tailored Solutions:"
          descriptionImage={assets[`./content/autogenerated/shared/tailored-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets[`./content/autogenerated/script1/tailored-step-1.png`]}

          descriptionImage={assets[`./content/autogenerated/shared/tailored-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["5"]["5"].start}
          subtitlesFile="./subtitles/5.vtt"
          end={videoSplit["5"]["5"].end}
          src={assets.video5}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle="Tailored Solutions"
          image={assets["./content/autogenerated/new-images-2/5-5-4.jpg"]}
          description={`Sindy did a great job of providing relevant and  personalized recommendations that will help solve the advertiser's business goal of generating more sales.\n\n   However, Sindy could have communicated even more explicitly about how the solution is relevant to achieving the advertiser’s goals.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Tailored Solutions`}
          image={assets["./content/autogenerated/script5/tailored-question.png"]}
          imageCorrect={assets["./content/autogenerated/script5/tailored-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/script5/tailored-incorrect.png"]}
          descriptionCorrect="This ties the features of Advantage+ Creative back to the advertiser’s goals of generating more sales."
          question={`How could they have framed this for Advantage+ Creative?`}
          answers={[
            {
              answer: `“Choosing to let the algorithm assemble your ads from multiple creatives will result in fewer ad rejections and help keep your account quality high.”`,
              correct: false,
            },
            {
              answer: `“Uploading multiple creatives now will save you time when you want to create additional campaigns that use some or all of the same images and videos.”`,
              correct: false,
            },
            {
              answer: `“If the ad is customized to target each person who sees it, they are more likely to react positively to the ad, which will help you generate more sales.”`,
              correct: true,
            },
            {
              answer: `All of the above`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/securing-commitment`);
          }}
        />
      )}
    </>
  );
}
