import React from "react";
import { Layer } from "react-xr-ui";
import { useThree } from "@react-three/fiber";
import { useXR } from "@react-three/xr";
import assets from "@/config/assets";
import Static from "@/components/static";
import { VR_OFFSET_Y, WEB_OFFSET_Y } from "@/config/consts";

type Props = {
  textContent: string;
  height?: number;
};

export default function Title({ textContent, height = 0.35 }: Props) {
  const viewport = useThree((state) => state.viewport);

  const isPresenting = useXR((state) => state.isPresenting);
  const offsetY = React.useMemo(() => {
    return isPresenting ? VR_OFFSET_Y : WEB_OFFSET_Y;
  }, [isPresenting]);

  return (
    <Static>
      <Layer
        position-y={(viewport.height / 2.08 - height / 2) * offsetY}
        width={3.4}
        height={height}
        autoLayout={false}
        borderWidth={0}
        borderColor="#1C2B33"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#1C2B33"
        opacity={1}
        borderRadius={0.2}
      >
        <Layer
          width="100%"
          height="100%"
          borderRadius={0.2}
          backgroundImage={assets.titleLogo}
          backgroundPosition={[0.045, 0.5]}
        />
        <Layer
          position-y={-0.005}
          autoLayout={false}
          width="100%"
          height="100%"
          color="white"
          textContent={textContent}
          textAlign="center"
          verticalAlign="middle"
          zIndex={2}
          fontSize={0.4}
        />
      </Layer>
    </Static>
  );
}
