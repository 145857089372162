export default [
  {
    "tabs": [
      "Scheduled Follow Up Call",
      "Privacy",
    ],
    "scores": [
      100,
      100
    ],
    "average": 100,
    "description": [
      "Excellent Work",
      "Verified the last 4 digits of the Ad Account"
    ]
  },
  {
    "tabs": [
      "Scheduled Follow Up Call",
      "Post Call Email Communication",
      "Effective Notes Capture",
      "Privacy",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Simon scheduled a follow up call with the customer. Great work",
      "Simon outlined what they would be emailing the customer after the call.",
      "Simon accurately captured notes throughout the call",
      "Simon adhered to privacy guidance throughout"
    ]
  },
  {
    "tabs": [
      "Scheduled Follow Up Call",
      "Post Call Email Communication",
      "Effective Notes Capture",
      "Privacy",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Sindy scheduled a follow up call with the customer. Great work",
      "Sindy outlined what they would be emailing the customer after the call.",
      "Sindy accurately captured notes throughout the call",
      "Sindy adhered to privacy guidance throughout"
    ]
  },
  {
    "tabs": [
      "Scheduled Follow Up Call",
      "Post Call Email Communication",
      "Effective Notes Capture",
      "Privacy",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Sindy scheduled a follow up call with the customer. Great work",
      "Sindy outlined what they would be emailing the customer after the call.",
      "Sindy accurately captured notes throughout the call",
      "Sindy adhered to privacy guidance throughout"
    ]
  },
  {
    "tabs": [
      "Scheduled Follow Up Call",
      "Post Call Email Communication",
      "Effective Notes Capture",
      "Privacy",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Sindy scheduled a follow up call with the customer. Great work",
      "Sindy outlined what they would be emailing the customer after the call.",
      "Sindy accurately captured notes throughout the call",
      "Sindy adhered to privacy guidance throughout"
    ]
  },
  {
    "tabs": [
      "Scheduled Follow Up Call",
      "Post Call Email Communication",
      "Effective Notes Capture",
      "Privacy",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Sindy scheduled a follow up call with the customer. Great work",
      "Sindy outlined what they would be emailing the customer after the call.",
      "Sindy accurately captured notes throughout the call",
      "Sindy adhered to privacy guidance throughout"
    ]
  },
  {
    "tabs": [
      "Scheduled Follow Up Call",
      "Post Call Email Communication",
      "Effective Notes Capture",
      "Privacy",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Simon scheduled a follow up call with the customer. Great work",
      "Simon outlined what they would be emailing the customer after the call.",
      "Simon accurately captured notes throughout the call",
      "Simon adhered to privacy guidance throughout"
    ]
  },
  {
    "tabs": [
      "Scheduled Follow Up Call",
      "Post Call Email Communication",
      "Effective Notes Capture",
      "Privacy",
    ],
    "scores": [
      100,
      100,
      100,
      100
    ],
    "average": 100,
    "description": [
      "Simon scheduled a follow up call with the customer. Great work",
      "Simon outlined what they would be emailing the customer after the call.",
      "Simon accurately captured notes throughout the call",
      "Simon adhered to privacy guidance throughout"
    ]
  }
] as const;