import Module8Home from "@/pages/8/module-8-home";
import Module8Discovery from "@/pages/8/scenes/module-8-discovery";
import Module8TailoredSolutions from "@/pages/8/scenes/module-8-tailored-solutions";
import Module8CallClosing from "@/pages/8/scenes/module-8-call-closing";
import Module8CallOpening from "@/pages/8/scenes/module-8-call-opening";

import Module1Home from "@/pages/1/module-1-home";
import Module1CallOpening from "@/pages/1/scenes/module-1-call-opening";
import Module1Discovery from "@/pages/1/scenes/module-1-discovery";
import Module1CommercialConversations from "@/pages/1/scenes/module-1-commercial-conversations";
import Module1TailoredSolutions from "@/pages/1/scenes/module-1-tailored-solutions";
import Module1CallClosing from "@/pages/1/scenes/module-1-call-closing";

import Module2Home from "@/pages/2/module-2-home";
import Module2CallOpening from "@/pages/2/scenes/module-2-call-opening";
import Module2Discovery from "@/pages/2/scenes/module-2-discovery";
import Module2CommercialConversations from "@/pages/2/scenes/module-2-commercial-conversations";
import Module2TailoredSolutions from "@/pages/2/scenes/module-2-tailored-solutions";
import Module2CallClosing from "@/pages/2/scenes/module-2-call-closing";
import Module2SecuringCommitment from "@/pages/2/scenes/module-2-securing-commitment";

import Module3Home from "@/pages/3/module-3-home";
import Module3CallOpening from "@/pages/3/scenes/module-3-call-opening";
import Module3Discovery from "@/pages/3/scenes/module-3-discovery";
import Module3TailoredSolutions from "@/pages/3/scenes/module-3-tailored-solutions";
import Module3CallClosing from "@/pages/3/scenes/module-3-call-closing";
import Module3SecuringCommitment from "@/pages/3/scenes/module-3-securing-commitment";
import Module3CommercialConversations from "@/pages/3/scenes/module-3-commercial-conversations";

import Module4Home from "@/pages/4/module-4-home";
import Module4CallOpening from "@/pages/4/scenes/module-4-call-opening";
import Module4Discovery from "@/pages/4/scenes/module-4-discovery";
import Module4TailoredSolutions from "@/pages/4/scenes/module-4-tailored-solutions";
import Module4CallClosing from "@/pages/4/scenes/module-4-call-closing";
import Module4SecuringCommitment from "@/pages/4/scenes/module-4-securing-commitment";
import Module4CommercialConversations from "@/pages/4/scenes/module-4-commercial-conversations";

import Module5Home from "@/pages/5/module-5-home";
import Module5CallOpening from "@/pages/5/scenes/module-5-call-opening";
import Module5Discovery from "@/pages/5/scenes/module-5-discovery";
import Module5TailoredSolutions from "@/pages/5/scenes/module-5-tailored-solutions";
import Module5CallClosing from "@/pages/5/scenes/module-5-call-closing";
import Module5SecuringCommitment from "@/pages/5/scenes/module-5-securing-commitment";
import Module5CommercialConversations from "@/pages/5/scenes/module-5-commercial-conversations";

import Module6Home from "@/pages/6/module-6-home";
import Module6CallOpening from "@/pages/6/scenes/module-6-call-opening";
import Module6Discovery from "@/pages/6/scenes/module-6-discovery";
import Module6TailoredSolutions from "@/pages/6/scenes/module-6-tailored-solutions";
import Module6CallClosing from "@/pages/6/scenes/module-6-call-closing";
import Module6SecuringCommitment from "@/pages/6/scenes/module-6-securing-commitment";

import Module7Home from "@/pages/7/module-7-home";
import Module7CallOpening from "@/pages/7/scenes/module-7-call-opening";
import Module7Discovery from "@/pages/7/scenes/module-7-discovery";
import Module7TailoredSolutions from "@/pages/7/scenes/module-7-tailored-solutions";
import Module7CallClosing from "@/pages/7/scenes/module-7-call-closing";
import Module7SecuringCommitment from "@/pages/7/scenes/module-7-securing-commitment";
import Module7CommercialConversations from "@/pages/7/scenes/module-7-commercial-conversations";

const scenes = {
  "1": [
    { sceneId: "", component: Module1Home },
    { sceneId: "call-opening", component: Module1CallOpening },
    { sceneId: "discovery", component: Module1Discovery },
    { sceneId: "commercial-conversations", component: Module1CommercialConversations },
    { sceneId: "tailored-solutions", component: Module1TailoredSolutions },
    { sceneId: "call-closing", component: Module1CallClosing },
  ],
  "2": [
    { sceneId: "", component: Module2Home },
    { sceneId: "call-opening", component: Module2CallOpening },
    { sceneId: "discovery", component: Module2Discovery },
    { sceneId: "tailored-solutions", component: Module2TailoredSolutions },
    { sceneId: "commercial-conversations", component: Module2CommercialConversations },
    { sceneId: "securing-commitment", component: Module2SecuringCommitment },
    { sceneId: "call-closing", component: Module2CallClosing },
  ],
  "3": [
    { sceneId: "", component: Module3Home },
    { sceneId: "call-opening", component: Module3CallOpening },
    { sceneId: "discovery", component: Module3Discovery },
    { sceneId: "commercial-conversations", component: Module3CommercialConversations },
    { sceneId: "tailored-solutions", component: Module3TailoredSolutions },
    { sceneId: "securing-commitment", component: Module3SecuringCommitment },
    { sceneId: "call-closing", component: Module3CallClosing },
  ],
  "4": [
    { sceneId: "", component: Module4Home },
    { sceneId: "call-opening", component: Module4CallOpening },
    { sceneId: "discovery", component: Module4Discovery },
    { sceneId: "tailored-solutions", component: Module4TailoredSolutions },
   // { sceneId: "commercial-conversations", component: Module4CommercialConversations },
    { sceneId: "securing-commitment", component: Module4SecuringCommitment },
    { sceneId: "call-closing", component: Module4CallClosing },
  ],
  "5": [
    { sceneId: "", component: Module5Home },
    { sceneId: "call-opening", component: Module5CallOpening },
    { sceneId: "discovery", component: Module5Discovery },
    { sceneId: "commercial-conversations", component: Module5CommercialConversations },
    { sceneId: "tailored-solutions", component: Module5TailoredSolutions },
    { sceneId: "securing-commitment", component: Module5SecuringCommitment },
    { sceneId: "call-closing", component: Module5CallClosing },
  ],
  "6": [
    { sceneId: "", component: Module6Home },
    { sceneId: "call-opening", component: Module6CallOpening },
    { sceneId: "discovery", component: Module6Discovery },
    { sceneId: "tailored-solutions", component: Module6TailoredSolutions },
    { sceneId: "securing-commitment", component: Module6SecuringCommitment },
    { sceneId: "call-closing", component: Module6CallClosing },
  ],
  "7": [
    { sceneId: "", component: Module7Home },
    { sceneId: "call-opening", component: Module7CallOpening },
    { sceneId: "discovery", component: Module7Discovery },
    { sceneId: "commercial-conversations", component: Module7CommercialConversations },
    { sceneId: "tailored-solutions", component: Module7TailoredSolutions },
    { sceneId: "securing-commitment", component: Module7SecuringCommitment },
    { sceneId: "call-closing", component: Module7CallClosing },
  ],
  "8": [
    { sceneId: "", component: Module8Home },
    { sceneId: "call-opening", component: Module8CallOpening },
    { sceneId: "discovery", component: Module8Discovery },
    { sceneId: "tailored-solutions", component: Module8TailoredSolutions },
    { sceneId: "call-closing", component: Module8CallClosing },
  ],
};

export default scenes;
