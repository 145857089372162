import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8CallOpening() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const [videoSrc, setVideoSrc] = React.useState(assets.video8);

  return (
    <>
      {![0, 3].includes(step) && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["8"]["1"].start}
          subtitlesFile="./subtitles/8.vtt"
          end={videoSplit["8"]["1"].end}
          src={videoSrc}
          play={false}
        />
      )}
      <Title textContent="CALL OPENING" />
      {step === 0 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["8"]["1"].start}
          subtitlesFile="./subtitles/8.vtt"
          end={videoSplit["8"]["1"].end}
          src={assets.video8}
          onEnded={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Showing \nup prepared`}
          subtitle="In the call opening: "
          image={assets["./content/autogenerated/new-images/8-2-1.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/call-opening-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(2)} />, <ArrowButton dir="next" onDown={() => setStep(3)} />]}
        />
      )}
      {step === 2 && (
        <CardLarge
          title={`Showing \nup prepared`}
          image={assets["./content/autogenerated/script2/call-opening-step-2.jpeg"]}
          subtitle="Examples:"
          descriptionImage={assets["./content/autogenerated/shared/call-opening-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(3)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 3 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["8"]["2"].start}
          subtitlesFile="./subtitles/8.vtt"
          end={videoSplit["8"]["2"].end}
          src={assets.video8}
          onEnded={() => setStep(4)}
        />
      )}
      {step === 4 && (
        <CardLarge
          title={`Feedback`}
          subtitle="Call Opening"
          image={assets["./content/autogenerated/script2/call-opening-step-4.png"]}
          description={`Because Simon has already established a relationship with this advertiser, there’s no need to ask for the last 4 digits of the ad account as verification.\n\n   Sharing what was learned during preparation for the call is important to build trust and establish credibility at the start of each conversation.`}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(1)} />,
            <ArrowButton dir="next" onDown={() => setStep(5)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 5 && (
        <MultipleChoiceQuestion
          subtitle={`Call Opening`}
          image={assets["./content/autogenerated/script8/call-opening-question.png"]}
          imageCorrect={assets["./content/autogenerated/script8/call-opening-correct.png"]}
          imageIncorrect={assets["./content/autogenerated/script8/call-opening-incorrect.png"]}
          descriptionCorrect="Correct! Simon missed an opportunity to share how much they had learned while preparing for this conversation. Maybe they can work that in, later in the call."
          question={`True or False: In this call opening, Simon adequately showcased how they had prepared for the call. `}
          answers={[
            { answer: `True`, correct: false },
            {
              answer: `False`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            setStep(6);
          }}
        />
      )}
      {step === 6 && (
        <MultipleChoiceQuestion
          subtitle={`Call Opening`}
          image={assets["./content/autogenerated/script8/call-opening-question-2.png"]}
          imageCorrect={assets["./content/autogenerated/script8/call-opening-correct-2.png"]}
          imageIncorrect={assets["./content/autogenerated/script8/call-opening-incorrect-2.png"]}
          descriptionCorrect="Correct! Simon missed an opportunity to explain what solution recommendations, new ideas, and campaign result reviews they wanted to cover. They should have also asked the advertiser if there’s anything they’d like to cover. For future reference, use the POInT framework during the call opening to cover the bases"
          question={`True or False: In this call opening, Simon shared the agenda and explained what would be covered during the call.`}
          answers={[
            { answer: `True`, correct: false },
            {
              answer: `False`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/discovery`);
          }}
        />
      )}
      {step === 7 && (
        <CardLarge
          direction="rtl"
          title={`Summary`}
          subtitle="Call Opening"
          image={assets["./content/autogenerated/script8/call-opening-summary.png"]}
          description={`In the call opening, Simon did not showcase any preparation. He missed an opportunity to demonstrate knowledge of the client's business, including their challenges, products, and previous conversations. Additionally, he omitted the agenda for the call, which could have provided a brief explanation of the topics to be covered and allowed the advertiser to include any specific items they wanted to discuss. As Simon already had an established relationship with the advertiser, there was no need to ask for the last 4 digits of the ad account as verification.`}
          buttons={[<ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/discovery`)} />]}
        />
      )}
    </>
  );
}
