import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import CardSummary from "@/components/card-summary";

export default function Module8CallClosing() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["6"]["6"].start}
          subtitlesFile="./subtitles/6.vtt"
          end={videoSplit["6"]["6"].end}
          src={assets.video6}
          play={false}
        />
      )}
      <Title textContent="CALL CLOSING" />
      {step === 0 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          subtitle="During the call closing:"
          image={assets["./content/autogenerated/new-images-2/6-6-1.jpg"]}
          descriptionImage={assets["./content/autogenerated/shared/call-closing-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Plan for \nthe next call`}

          image={assets["./content/autogenerated/script1/call-closing-step-1.jpeg"]}
          descriptionImage={assets[`./content/autogenerated/shared/call-closing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["6"]["6"].start}
          subtitlesFile="./subtitles/6.vtt"
          end={videoSplit["6"]["6"].end}
          src={assets.video6}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Call Closing`}
          image={assets["./content/autogenerated/new-images/6-6-4.jpg"]}
          description={`Sindy summarized the key takeaways and checked that the advertiser had no unanswered questions.\n\n   Sindy also outlined the follow-up actions and set expectations of what will be discussed on the next call. Great work!`}
          buttons={[
            <ArrowButton
              dir="next"
              onDown={() => {
                setStep(4);
              }}
            />,
          ]}
        />
      )}
      {step === 4 && (
        <CardSummary
          onPrev={() => {
            setStep(3);
          }}
          onFinish={() => {
            const state = appState.getState();
            state.endSession(params.moduleId!);
            state.reset();
            state.completeModule("6");
            navigate("/?module-selection=true");
          }}
        />
      )}
    </>
  );
}
