import React from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

type Props = {
  children: React.ReactNode;
  startY?: number;
  startX?: number;
  delay?: number;
  speed?: number;
  isVisible?: boolean;
  onFinish?: () => void;
};

export default function SlideUp({
  children,
  startY = -1,
  startX = 0,
  delay = 0,
  speed = 0.05,
  isVisible = true,
  onFinish,
}: Props) {
  const groupRef = React.useRef<THREE.Group>(null);
  const startRef = React.useRef(false);
  const animationFinishedRef = React.useRef(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const group = groupRef.current;
      if (!group) return;
      group.traverse((object) => {
        if (!(object instanceof THREE.Mesh)) return;
        if (!(object.material instanceof THREE.Material)) return;
        object.material.transparent = true;
        object.material.opacity = 0;
        object.material.needsUpdate = true;
      });

      startRef.current = true;
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  useFrame(() => {
    if (!startRef.current) return;
    const group = groupRef.current;
    if (!group) return;
    group.visible = true;
    group.position.y = THREE.MathUtils.lerp(group.position.y, 0, speed);
    group.position.x = THREE.MathUtils.lerp(group.position.x, 0, speed);
    let opacityApproachingOne = true;

    group.traverse((object) => {
      if (!(object instanceof THREE.Mesh)) return;
      if (!(object.material instanceof THREE.Material)) return;
      object.material.transparent = true;
      object.material.opacity = THREE.MathUtils.lerp(object.material.opacity, 1, speed);
      object.material.needsUpdate = true;
      if (object.material.opacity < 0.99) opacityApproachingOne = false;
    });

    if (!animationFinishedRef.current && group.position.y > -0.01 && group.position.x < 0.01 && opacityApproachingOne) {
      animationFinishedRef.current = true;
      if (onFinish) onFinish();
    }
  });

  return (
    <group ref={groupRef} position-y={startY} position-x={startX} visible={isVisible}>
      {children}
    </group>
  );
}
