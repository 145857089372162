import React from "react";
import { useXR } from "@react-three/xr";

type Rotation = [x: number, y: number, z: number];

export default function useVideoRotation(rotation: Rotation, vrRotation?: Rotation) {
  const isPresenting = useXR((state) => state.isPresenting);

  return React.useMemo(() => {
    if (!isPresenting) {
      return rotation;
    }
    return vrRotation ?? rotation;
  }, [isPresenting, rotation, vrRotation]);
}
