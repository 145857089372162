import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import { appState } from "@/state/app-state";

export default function Module8TailoredSolutions() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["3"]["5"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["5"].end}
          src={assets.video3}
          play={false}
        />
      )}
      <Title textContent="TAILORED SOLUTIONS" />
      {step === 0 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets[`./content/autogenerated/script1/tailored-step-0.png`]}
          subtitle="During Tailored Solutions:"
          descriptionImage={assets[`./content/autogenerated/shared/tailored-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets[`./content/autogenerated/script1/tailored-step-1.png`]}
          descriptionImage={assets[`./content/autogenerated/shared/tailored-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -80, 0]}
          start={videoSplit["3"]["5"].start}
          subtitlesFile="./subtitles/3.vtt"
          end={videoSplit["3"]["5"].end}
          src={assets.video3}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle="Tailored Solutions"
          image={assets[`./content/autogenerated/script1/tailored-step-3.png`]}
          descriptionFontSize="31px"
          description={`Sindy did a great job educating the advertiser on how the benefits of Lead Ads tie back to the originally stated goal of increasing salon bookings. Sindy also covered why a Lead is considered a more valuable event to invest budget in, which is great. She could have given an additional brief explanation of how the auction works, especially to explain the competitive nature of it when bidding on more valuable actions, such as conversions and leads.\n\n   Sindy showed such enthusiasm for the business and suggested creative ways to take advantage of the upcoming seasonal events of Valentines Day and Mothers Day. While Sindy justified their recommendation to target older audiences due to them potentially being more loyal, they could also have reminded the advertiser about the benefits of broad audience targeting more generally.`}
          buttons={[<ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/securing-commitment`)} />]}
        />
      )}
    </>
  );
}
