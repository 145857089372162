import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8TailoredSolutions() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const videoRotation = useVideoRotation([0, -40, 0]);

  return (
    <>
      {step !== 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["4"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["4"].end}
          src={assets.video2}
          play={false}
        />
      )}
      <Title textContent="TAILORED SOLUTIONS" />
      {step === 0 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets["./content/autogenerated/new-images-2/2-4-1.jpg"]}
          subtitle="During Tailored Solutions:"
          descriptionImage={assets[`./content/autogenerated/shared/tailored-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets[`./content/autogenerated/script1/tailored-step-1.png`]}
          descriptionImage={assets[`./content/autogenerated/shared/tailored-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["4"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["4"].end}
          src={assets.video2}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Tailored Solutions`}
          image={assets[`./content/autogenerated/script1/tailored-step-3.png`]}
          description={`The advertiser shared some concern about the recommended level of budget increase.\n\n   It's important to acknowledge the advertiser's concern and then ask questions to understand the concern more deeply before providing a recommendation to address it.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Tailored Solutions`}
          image={assets[`./content/autogenerated/script2/tailored-step-4.png`]}
          imageCorrect={assets["./content/autogenerated/new-images/2-4-8-9.jpg"]}
          imageIncorrect={assets["./content/autogenerated/new-images/2-4-8-9.jpg"]}
          descriptionCorrect="Simon shouldn’t rush into the next topic before getting validation from their advertiser that they are aligned on this budget recommendation."
          question={`Before moving on, what should Simon do next? `}
          answers={[
            {
              answer: `They should start the next topic before they have a chance to push back on their budget recommendation.`,
              correct: false,
            },
            {
              answer: `They should validate that the advertiser is aligned with their budget recommendation.`,
              correct: true,
            },
            {
              answer: `They should inform the advertiser that there are no other options if they don’t agree with their recommendation.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            setStep(5);
          }}
        />
      )}
      {step === 5 && (
        <CardLarge
          title="Feedback"
          subtitle={`Tailored Solutions`}
          image={assets[`./content/autogenerated/script2/tailored-step-6.png`]}
          description={`Simon explained Pixel Events setup clearly, using simple language that was easy to understand.\n\n   Simon did the right thing by sharing the recommended budget from KB, but they could also have explained that some events are more valuable than others.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(6)} />]}
        />
      )}
      {step === 6 && (
        <MultipleChoiceQuestion
          subtitle={`Tailored Solutions`}
          image={assets[`./content/autogenerated/script2/tailored-step-7.png`]}
          imageCorrect={assets["./content/autogenerated/new-images/2-4-14-15.jpg"]}
          imageIncorrect={assets["./content/autogenerated/new-images/2-4-14-15.jpg"]}
          descriptionCorrect={`The advertiser might be more easily convinced to increase their budget if they understand that the auction is more competitive for certain types of events.\n\nSimon could also have probed more to better understand the overall quarterly budget and constraints the advertiser may have, to align more tightly on the overall strategy.`}
          question={`Why would some events being more valuable than others matter to the advertiser’s budget?`}
          answers={[
            {
              answer: `Because the auction is more competitive for certain types of events, it’s likely there will be a need to invest more to get them. `,
              correct: true,
            },
            {
              answer: `Because the advertiser may need to invest in a more advanced partner integration if they want to focus on the most valuable events.`,
              correct: false,
            },
            {
              answer: `Because there is a discount for targeting a mixture of low value and high value events that will reduce the advertiser’s required budget.`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/commercial-conversations`);
          }}
        />
      )}
    </>
  );
}
