import React, { useState } from "react";
import { Layer } from "react-xr-ui";
import SlideUp from "@/components/slide-up";
import FadeIn from "@/components/fade-in";
import Frame from "@/components/frame";
import { STACKED_TRANSITION_DELAY } from "@/config/consts";
import CustomerContinuityComplianceSummaries from "@/config/summaries/Customer Continuity & Compliance Summaries";
import CustomerOutcomesImpactSummaries from "@/config/summaries/Customer Outcomes & Impact Summaries";
import CustomerRelationshipTrustSummaries from "@/config/summaries/Customer Relationship & Trust Summaries";
import { SceneParams } from "@/types";
import { useParams } from "react-router-dom";
import ButtonInteraction from "./button-interaction";
import ArrowButton from "./arrow-button";
import Button from "./button";
import { appState } from "@/state/app-state";

const summaries = {
  "Customer Outcomes & Impact Summaries": CustomerOutcomesImpactSummaries,
  "Customer Relationship & Trust Summaries": CustomerRelationshipTrustSummaries,
  "Customer Continuity & Compliance Summaries": CustomerContinuityComplianceSummaries,
} as const;

type Props = {
  onPrev: Function;
  onFinish: Function;
};

export default function CardSummary({ onFinish, onPrev }: Props) {
  const params = useParams<SceneParams>();
  const moduleId = React.useMemo(() => {
    return params.moduleId ?? "";
  }, [params.sceneId]);
  const sceneId = React.useMemo(() => {
    return params.sceneId ?? "";
  }, [params.sceneId]);

  const [summary, setSummary] = useState<keyof typeof summaries>("Customer Outcomes & Impact Summaries");
  const summaryData = summaries[summary][`${parseInt(moduleId) - 1}` as "1"];
  const tabs1 = Object.fromEntries(summaryData.tabs.slice(0, 4).map((tab, i) => [i, tab])) as Record<number, string>;
  const tabs2 = Object.fromEntries(summaryData.tabs.slice(4, 8).map((tab, i) => [i + 4, tab])) as Record<
    number,
    string
  >;
  const [tab, setTab] = useState<number>(-1);
  const desc = summaryData.description[tab];
  const score = summaryData.scores[tab];
  const totalScore = summaryData.average;
  const isFirstSummary = summary === "Customer Outcomes & Impact Summaries";
  const isLastSummary = summary === "Customer Continuity & Compliance Summaries";
  
  const addProgress = appState((state) => state.addProgress);

  const handleNextSummary = () => {
    const keys = Object.keys(summaries);
    const index = keys.indexOf(summary);
    const nextIndex = (index + 1) % keys.length;
    setSummary(keys[nextIndex] as keyof typeof summaries);
  };

  const handlePrevSummary = () => {
    const keys = Object.keys(summaries);
    const index = keys.indexOf(summary);
    const prevIndex = (index - 1 + keys.length) % keys.length;
    setSummary(keys[prevIndex] as keyof typeof summaries);
  };

  React.useEffect(() => {
    setTab(-1);
  }, [summary]);

  return (
    <Frame>
      <Layer height="100%" aspectRatio={1200 / 700}>
        <Layer
          width="100%"
          height="100%"
          backgroundColor="rgba(255, 255, 255, 0.95)"
          justifyContent="start"
          padding={0.09}
          flexDirection="row"
        >
          <Layer width="100%" height="100%" flexDirection="column" alignItems="start" justifyContent="start">
            <Layer width="100%" height="8.285714285714285%" />
            <SlideUp delay={STACKED_TRANSITION_DELAY * 0} isVisible={false}>
              <Layer
                width="100%"
                height={`${8.571428571428571 * 1}%`}
                fontSize={1 / 1}
                fontWeight="800"
                lineHeight={1}
                textContent="Summary"
                color="#1C2B33"
              />
            </SlideUp>
            <Layer width="100%" height="4.285714285714286%" />

            <SlideUp delay={STACKED_TRANSITION_DELAY * 2} isVisible={false}>
              <Layer
                width="100%"
                height="5%"
                fontWeight="700"
                textContent={summary}
                fontSize={1 / 1.5}
                color="#000000"
              />
            </SlideUp>
            <Layer width="100%" height="2%" />
            <SlideUp delay={STACKED_TRANSITION_DELAY * 3} isVisible={false}>
              <Layer width="100%" justifyContent="start" height="10%" gap={0.05} flexDirection="row">
                {Object.entries(tabs1).map(([t, text]) => {
                  return <Tab selected={parseInt(t) === tab} text={text} onDown={() => setTab(parseInt(t))} />;
                })}
              </Layer>
            </SlideUp>
            <Layer width="100%" height="2%" />
            <SlideUp delay={STACKED_TRANSITION_DELAY * 4} isVisible={false}>
              <Layer width="100%" justifyContent="start" height="10%" gap={0.05} flexDirection="row">
                {Object.entries(tabs2).map(([t, text]) => {
                  return <Tab selected={parseInt(t) === tab} text={text} onDown={() => setTab(parseInt(t))} />;
                })}
              </Layer>
            </SlideUp>
            <Layer width="100%" height="2%" />
            {tab !== -1 && (
              <Layer height="40%" width="100%" flexDirection="row" justifyContent="start" alignItems="end">
                <Layer
                  width="73%"
                  height="100%"
                  verticalAlign="center"
                  fontSize={0.08}
                  textContent={desc as string}
                  color="#000000"
                />
                <Layer
                  width="20%"
                  height="100%"
                  textAlign="center"
                  verticalAlign="center"
                  fontSize={0.27}
                  fontWeight="bold"
                  textContent={`${score}%`}
                  color="#1877F2"
                />
              </Layer>
            )}
            {tab === -1 && (
              <Layer flexDirection="column" width="100%" height="40%" position={[-0.1, 0, 0]}>
                <Layer
                  width="50%"
                  height="90%"
                  textAlign="center"
                  verticalAlign="center"
                  fontSize={0.27}
                  fontWeight="bold"
                  textContent={`Overall Score - ${totalScore}%`}
                  color="#1877F2"
                />
                <Layer
                  width="73%"
                  height="20%"
                  verticalAlign="center"
                  fontSize={0.4}
                  textAlign="center"
                  textContent="Navigate the tabs above to see the score breakdown and feedback"
                  color="#000000"
                />
              </Layer>
            )}
          </Layer>
        </Layer>
        <Layer autoLayout={false} width="100%" height="100%" alignItems="end" justifyContent="end">
          <FadeIn delay={STACKED_TRANSITION_DELAY * 4}>
            <Layer width="100%" height="8.5%" justifyContent={"space-between"} gap={0.01}>
              <ArrowButton
                dir="prev"
                onDown={() => {
                  if (isFirstSummary) {
                    onPrev!();
                  } else {
                    handlePrevSummary();
                  }
                }}
              />
              {isLastSummary ? (
                <Button
                  height={0.2}
                  text="FINISH"
                  fontSize="35%"
                  aspectRatio={12.625 / 3.75}
                  onDown={() => {
                    onFinish!();
                  }}
                />
              ) : (
                <ArrowButton
                  dir="next"
                  onDown={() => {
                    handleNextSummary();
                  }}
                />
              )}
            </Layer>
          </FadeIn>
        </Layer>
      </Layer>
    </Frame>
  );
}

const Tab = ({ selected, text, onDown }: { selected: boolean; text: string; onDown: Function }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <ButtonInteraction onOver={() => setHovered(true)} onOut={() => setHovered(false)} onDown={() => onDown()}>
      <Layer
        backgroundColor={hovered || selected ? "#1877F2" : "#DBDBDB"}
        width="22%"
        height="100%"
        aspectRatio={15 / 3.5625}
        textAlign="center"
        verticalAlign="center"
        textContent={text}
        fontSize={0.27}
        color={hovered || selected ? "#FFFFFF" : "#000000"}
      />
    </ButtonInteraction>
  );
};
