import React from "react";
import assets from "@/config/assets";
import Card from "@/components/card";
import StartButton from "@/components/start-button";
import { useNavigate } from "react-router-dom";
import ArrowButton from "@/components/arrow-button";

export default function Module8Home() {
  const navigate = useNavigate();

  return (
    <>
      <Card
        title={`Securing Budget \nCommitment`}
        image={assets["./content/autogenerated/script2/home.png"]}
        description={`During this call, Simon, the Marketing Pro, will ask about the advertiser’s available budget to spend on Meta platforms, overcome hesitation to discuss the budget and explain the importance of investment to exit the learning stage, while diving into tactics to pace budget spend.`}
        buttons={[
          <ArrowButton
            onDown={() => {
              navigate(`/?module-selection=true`);
            }}
            dir="prev"
          />,
          <StartButton moduleId="2" />,
        ]}
      />
    </>
  );
}
