import React from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { useXR } from "@react-three/xr";

const TARGET = new THREE.Vector3();

type Props = {
  children?: React.ReactNode;
};

export default function Static({ children }: Props) {
  const isPresenting = useXR((state) => state.isPresenting);
  const player = useXR((state) => state.player);

  const groupRef = React.useRef<THREE.Group | null>(null);

  useFrame(({ camera }) => {
    const group = groupRef.current;
    if (group === null) return;

    const object = isPresenting ? player : camera;
    let dist = 5;

    if (isPresenting) {
      dist = -4;
    }

    TARGET.set(0, 0, 0);

    object.getWorldDirection(TARGET);

    TARGET.multiplyScalar(dist);
    TARGET.add(object.position);

    group.position.set(TARGET.x, TARGET.y + (isPresenting ? 0.5 : 0), TARGET.z);
    group.setRotationFromQuaternion(object.quaternion);
  });

  React.useEffect(() => {
    if (!isPresenting) return;
    player.position.set(0, 0, 2);
  }, [isPresenting, player]);

  return <group ref={groupRef}>{children}</group>;
}
