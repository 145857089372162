import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import CardSummary from "@/components/card-summary";
import useVideoRotation from "@/hooks/use-video-rotation";

export default function Module8CallClosing() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  const videoRotation = useVideoRotation([0, -40, 0]);

  return (
    <>
      {step !== 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["7"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["7"].end}
          src={assets.video2}
          play={false}
        />
      )}
      <Title textContent="CALL CLOSING" />
      {step === 0 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          subtitle="During the call closing:"
          image={assets["./content/autogenerated/script1/call-closing-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/call-closing-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Plan for \nthe next call`}
          image={assets["./content/autogenerated/script1/call-closing-step-1.jpeg"]}
          descriptionImage={assets[`./content/autogenerated/shared/call-closing-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={videoRotation}
          start={videoSplit["2"]["7"].start}
          subtitlesFile="./subtitles/2.vtt"
          end={videoSplit["2"]["7"].end}
          src={assets.video2}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle={`Call Closing`}
          image={assets["./content/autogenerated/script1/call-closing-step-3.png"]}
          description={`Simon summarized the key takeaways from the discussion, validated that the advertiser has no unanswered questions and set expectations of what will be discussed on the next call.\n\n   Simon could have reinforced the follow-up actions required by the advertiser, again, with one last reminder. This helps ensure the advertiser takes those actions and can continue to make progress.`}
          buttons={[
            <ArrowButton
              dir="next"
              onDown={() => {
                setStep(4);
              }}
            />,
          ]}
        />
      )}
      {step === 4 && (
        <CardSummary
          onPrev={() => {
            setStep(3);
          }}
          onFinish={() => {
            const state = appState.getState();
            state.endSession(params.moduleId!);
            state.reset();
            state.completeModule("2");
            navigate("/?module-selection=true");
          }}
        />
      )}
    </>
  );
}
