import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import { appState } from "@/state/app-state";

export default function Module8TailoredSolutions() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["8"]["4"].start}
          subtitlesFile="./subtitles/8.vtt"
          end={videoSplit["8"]["4"].end}
          src={assets.video8}
          play={false}
        />
      )}
      <Title textContent="TAILORED SOLUTIONS" />
      {step === 0 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets["./content/autogenerated/new-images/8-4-1.jpg"]}
          subtitle="During Tailored Solutions:"
          descriptionImage={assets[`./content/autogenerated/shared/tailored-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets["./content/autogenerated/new-images-2/8-4-2.png"]}
          descriptionImage={assets[`./content/autogenerated/shared/tailored-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["8"]["4"].start}
          subtitlesFile="./subtitles/8.vtt"
          end={videoSplit["8"]["4"].end}
          src={assets.video8}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle="Tailored Solutions"
          image={assets[`./content/autogenerated/script8/tailored-feedback.png`]}
          description={`Simon did an excellent job providing a simple and clear walkthrough of setting up the Conversions API and Product Catalog on WooCommerce.\n\n   They also educated the advertiser on the benefits of these solutions when they said “to help you improve campaign targeting, decrease CPA and more accurately measure your ad’s success.“\n\n   Well done!`}
          buttons={[<ArrowButton dir="next" onDown={() => navigate(`/${params.moduleId}/call-closing`)} />]}
        />
      )}
    </>
  );
}
