export default [
  {
    "tabs": [
      "Call Opening",
      "Discovery & Connection",
      "Commercial Conversations",
      "Tailored Solutions",
      "Securing Commitment",
      "Unlocking Opportunities",
      "Call Closing"
    ],
    "scores": [
      75,
      75,
      75,
      75,
      100,
      75,
      100
    ],
    "average": 82,
    "description": [
      "Simon explained the program well and set an agenda for the call but could have also demonstrated some specific knowledge of the client's business (challenges, products, priorities, previous conversations, etc.) during the call opening. Simon did refer to his prep later in the call but it's important to include it in the opening as it helps to build trust by establishing credibility at the start of a call. ",
      "Simon asked some well structured and cohesive questions. There were also 1-2 closed questions that could have been asked as open questions. Be mindful as well of making assumptions. Stay curious and probe to deepen or validate what you think you know. ",
      "Simon did a good job making recommendations based on the KB and explaining how the recommended budget can improve campaign performance. He could have asked some additional questions to better understand client's overall marketing budget (constraints, channels, competitors, short / long term, etc.)",
      "Simon clearly explained what Advantage Detailed Targeting is and how it works but he could have been even more explicit about how its features can benefit the advertiser by tying them back to the original goals staed by the advertiser. Excellent explanation of the Pixel and how it can help the advertiser can achieve their objectives by improving performance.",
      "Simon did a great job offering the walkthrough to collaborate while he explained the targeting changes and Pixel set up. ",
      "Overall, how Simon managed the advertiser's concerns had a positive impact on the call, well done!  When the advertiser mentioned she was worried about overspending or not bidding enough, he could have asked 1-2 clarifying questions to understand that concern more deeply. Was it based on past experiences, or her need to satisfy other stakeholders (example)? The advertiser also had valid concern around tracking results and while he did well here to probe for more information, he could also have taken a moment just to acknowledge the concern is valid and that you’ve heard and understood it. ",
      "Simon completely showcased this capability as expected by summarizing the key takeaways and checking the advertiser had no unanswered questions. Simon also outlined the follow up actions and set expectations of what will be discussed on the next call."
    ]
  },
  {
    "tabs": [
      "Call Opening",
      "Discovery & Connection",
      "Commercial Conversations",
      "Tailored Solutions",
      "Securing Commitment",
      "Unlocking Opportunities",
      "Call Closing"
    ],
    "scores": [
      100,
      50,
      75,
      75,
      100,
      50,
      75
    ],
    "average": 75,
    "description": [
      "Simon did a great job referring to the previous conversation as this is a clear signal that he prepared for the call. The Agenda was super clear as well - this is really important as both he and the advertiser have a plan now and that will help them use their time together effecitvely. He also asked if the advertiser had anything they'd like to include and incorporated her repsonse into the agenda. Excellent work. ",
      "Simon asked an “open” question to start and then acknowledged and responded to the advertiser's desire to focus on sales. This is a good example of listening and being aware of any hesitations or concerns that arise on calls. Well done. He could have probed further around the performance of the campaigns you set up on the last call though by asking  more “structured, relevant and cohesive questions.” It's important to understand how each advertiser measures success and this was an ideal opportunity to do this before checking in re. the Pixel Helper set up.",
      "Well done for sharing the recommended budget from KB but Simon could also have explained that some events are more valuable than others. As a result, the auction is more competitive and it’s likely there will be a need to invest more. \n\nSimon could also have probed more to better understand the overall quarterly budget and constraints the advertiser may have. This way he can align on the overall strategy.",
      "The solutions Simon chose are highly appropriate and particulary valuable as they're progressing the advertiser along a logical journey. He explained the feaures of each very well but he could have linked the benefits of each back to the advertisers goals even more strongly. \n\nFor example, the advertiser wants to drive purchases and using Custom Audiences to retarget shoppers who have not completed a previous purchase is a great way to do that. Connecting the benefits of a solution to the advertiser's needs will help drive action from advertisers. ",
      "Simon did a great job seeking a specific timeline to complete the campaign setup. This can often help plan when to schedule the next call as well, as you’ll know when it’s most appropriate to review results or progress the advertiser on the next step of their journey.",
      "The advertiser shared some concern about the receommended level of budget increase. It's important to acknowledge the advertiser concern and then ask questions to understand the concern more deeply before providing a recommendation to address it. Finally, it's important to validate the customer is aligned.",
      "Simon summarized the key takeaways from the discussion and validated the advertiser has no unanswered questions and set expectations of what will be discussed on the next call. \n\nHe could have taken another opportunity to outline and reinfirce the follow up actions required by the advertiser. This will help ensure the advertiser takes those actions and they can continue to make progress."
    ]
  },
  {
    "tabs": [
      "Call Opening",
      "Discovery & Connection",
      "Commercial Conversations",
      "Tailored Solutions",
      "Securing Commitment",
      "Unlocking Opportunities",
      "Call Closing"
    ],
    "scores": [
      75,
      25,
      75,
      75,
      100,
      100,
      100
    ],
    "average": 79,
    "description": [
      "Sindy did a great job of displaying her preparation for the call by referring to her last conversation with the advertiser and taking into account the advertiser's previously stated business goal. By showing that she was well-prepared, Sindy effectively built credibility, which increased the likelihood of the advertiser trusting her recommendations. However, she could have also confirmed with the advertiser if she was okay with the agenda and provided her with an opportunity to add any additional items she wanted to discuss. This approach would have ensured that the advertiser felt involved and valued in the conversation.",
      "Sindy could have asked more structured and relevant questions to deepen her understanding of the advertiser’s business challenges and priorities. Spending time in discovery also gives the advertiser a chance to confirm for themselves what’s most important and increases the likelihood of them actioning the solution she eventually recommends. She only asked two discovery questions; how satisfied are you with your campaigns and have you tried Lead Ads? It’s also important for her to provide context for questions to gain buy-in, especially when the advertiser has confirmed that time is limited.",
      "Well done for Sindy asking about the overall marketing budget of $2500 per month. However, she could have followed up to confirm which platforms, channels, and where else in the funnel the advertiser typically invests. She could have also gone into more detail as to why a Lead is considered a more valuable event to invest budget and give a brief explanation of how the auction works, especially the competitive nature of it when bidding on more valuable actions, such as conversions and leads. Objections around cost most often mask a true objection about perceived value for money, so it’s important for Sindy to justify her recommendations with that in mind.",
      "Sindy did a great job in educating the advertiser on how the benefits of Lead Ads tie back to the originally stated goal of increasing salon bookings. It’s also nice to see her showing such enthusiasm for the business and suggesting creative ways to take advantage of the upcoming seasonal events of Valentine's Day and Mother's Day. While she justified her recommendation to target older audiences due to them potentially being more loyal, she could also have reminded the advertiser about the benefits of broad audience targeting more generally.\n",
      "Nice work by Sindy in walking the advertiser through the initial campaign set up and validating they’re aligned on the approach. Most importantly, she confirmed the timeline to have the creative ready. This helps her ensure the next call will be a valuable use of both their time.",
      "Sindy managed this completely in line with the expectations; \"To be honest with you, Sindy, no I haven't. And there's a specific reason for that. If traffic campaigns already cost so much -- so much that I can barely keep my business running -- I can only imagine the added expenses of doing campaigns at lower funnel stages and goals.\"  She could consider addressing these concerns and providing additional information to help the advertiser understand the potential return on investment.",
      "Sindy clearly summarized the key takeaways from the discussion and built urgency for the next call by setting the expectations of what will be discussed. Great work on her part."
    ]
  },
  {
    "tabs": [
      "Call Opening",
      "Discovery & Connection",
      "Tailored Solutions",
      "Securing Commitment",
      "Unlocking Opportunities",
      "Call Closing"
    ],
    "scores": [
      75,
      75,
      50,
      75,
      100,
      100
    ],
    "average": 79,
    "description": [
      "Sindy again demonstrated excellent preparation for the conversation, this time by referring to her previous conversations with the advertiser and highlighting the successful implementation of CAPI. As a reminder, this is important as it maintains her credibility and builds trust. She also presented a clear agenda item for the call, giving her and the advertiser a sense of purpose and focus. Well done. However, she could have also asked the advertiser if they had any agenda items they’d like to include, ensuring their input and engagement.",
      "This is an excellent example of Sindy asking relevant questions as they help her stay connected to the advertiser’s current needs. However, she could have provided context for the questions to ensure buy-in from the advertiser. Providing additional context helps the advertiser understand the importance of each question and how it fits into the overall conversation.",
      "Sindy communicated clearly how Advantage+ Placement works and did an excellent job of educating the advertiser on how that solution directly relates to their goal of finding more people interested in purchasing nail polish. However, she could have taken more time to explain how A/B Testing works and aligned with the advertiser on how they will measure the effectiveness of each ad before consolidating. This would ensure that both parties are on the same page.",
      "She validated that the advertiser is aligned on the approach and offered a walkthrough, which is commendable. However, she could have provided more detail about what she would cover in that walkthrough. Similarly, she could have been more detailed and explicit about what she would like the advertiser to do before the next call and set clear timelines. This level of clarity ensures that both parties make the most of their time together on the next call.",
      "Sindy did an excellent job acknowledging the customer's concern. \"She completely understands wanting to test what creative resonates the most with the audience.\" However, she could have offered a more complete response addressing the concern and providing additional information to reassure the advertiser.",
      "She reviewed the key takeaways from the discussion and highlighted the actions she will take, such as sending the follow-up email. However, she could have reiterated the key actions that the advertiser needs to take as well. It's important for both parties to be 100% clear on what they will do next to ensure the relationship and overall marketing strategy continue moving forward with purpose."
    ]
  },
  {
    "tabs": [
      "Call Opening",
      "Discovery & Connection",
      "Commercial Conversations",
      "Tailored Solutions",
      "Securing Commitment",
      "Unlocking Opportunities",
      "Call Closing"
    ],
    "scores": [
      50,
      100,
      50,
      75,
      100,
      100,
      75
    ],
    "average": 79,
    "description": [
      "Sindy did a great job of acknowledging the advertiser's frustration and reassuring them that she's committed to helping them resolve their issues. However, she could have further showcased her preparation for the conversation by demonstrating knowledge of the client's current challenges. Similarly, she could have provided a brief explanation of what she wanted to review during the call, enhancing the structure of the conversation.",
      "Well done to Sindy for confirming the ad rejections and providing guidance on requesting a review of the account and rejected ads, particularly regarding the cooking knives product. She also did well to clarify the scope of her role, indicating where she can offer assistance and where she can't. Additionally, she positioned herself as the advertiser's Meta representative by using \"we\" and apologizing on behalf of the company. However, she could have asked a few more questions, particularly to gather information on when these issues started occurring and any previous efforts to resolve them.",
      "Sindy did well to connect the advertiser's blocked account issue to the overall setup of the ad account and campaigns. She explained how this connection can lead to higher CPAs and inefficient spending of the budget. It was a wise decision on her part not to delve too deeply into the budget conversation due to the advertiser's upset state. However, it will be crucial to revisit this topic in subsequent calls as providing spend recommendations and influencing budget decisions is essential for helping advertisers drive positive results.",
      "She provided relevant and personalized recommendations that would help solve the advertiser's business goal of generating more sales. However, she could have communicated even more explicitly how the solution is directly relevant to achieving the advertiser's goals. For example, clearly explaining the expected impact of implementing the recommendations on the desired outcomes.",
      "Sindy did a great job by aligning with the advertiser on the approach and confirming the timeline for completing the creative task after the call. This seamless transition led to scheduling the next call, ensuring its continued value. Well done on her part.",
      "Throughout the conversation, Sindy demonstrated great work by acknowledging each advertiser's concern, probing further when appropriate, and providing clear responses across various topics.",
      "Excellent! She summarized the key takeaways, validated that the advertiser had no unanswered questions, and set expectations for the next call. However, she could have also repeated the advertiser's actions and checked in again to ensure their clarity on what needs to be done before the next call. This reinforcement would ensure that both parties are on the same page moving forward."
    ]
  },
  {
    "tabs": [
      "Call Opening",
      "Discovery & Connection",
      "Tailored Solutions",
      "Securing Commitment",
      "Call Closing"
    ],
    "scores": [
      100,
      100,
      100,
      75,
      100
    ],
    "average": 95,
    "description": [
      "The reason Sindy called was very from the outset. Sindy displayed clear preparation for the call and effectively positioned her \"hook,\" creating a sense of urgency and importance for the advertiser. This demonstrates her commitment to the conversation. Well done on her part.",
      "She asked relevant questions to deepen her understanding and validate her knowledge of the client's business. This showcases the preparation she had done beforehand. Understanding the product offering and the business's sales methods is crucial for developing a successful marketing strategy. Great work on Sindy's part.",
      "Sindy provided an excellent overview of Performance 5, focusing on the benefits of each solution and how they directly relate to the advertiser's business goals. This helps the advertiser see the value in implementing these solutions.",
      "She effectively explained the importance of taking action sooner and confirmed that the advertiser was aligned with the approach. However, she could have also confirmed any follow-up actions the advertiser should take in preparation for the next call. This could include reviewing the performance of existing ad sets, gathering creative assets for mobile-friendly videos, or assessing the resources needed to install conversions API.",
      "As expected, Sindy summarized the key takeaways, ensuring that the advertiser had no unanswered questions. She also outlined the follow-up actions and set expectations for the next call, showcasing her capability and professionalism."
    ]
  },
  {
    "tabs": [
      "Call Opening",
      "Discovery & Connection",
      "Commercial Conversations",
      "Tailored Solutions",
      "Securing Commitment",
      "Unlocking Opportunities",
      "Call Closing"
    ],
    "scores": [
      75,
      100,
      50,
      100,
      75,
      100,
      75
    ],
    "average": 82,
    "description": [
      "Simon showcased the preparation he had done for the conversation by referring to previous interactions with Meta, demonstrating an understanding of the advertiser's overall business model, and mentioning their current use of conversions API. This helped him establish credibility and build rapport with the advertiser. While he could have shared a more detailed agenda and informed the advertiser about the call recording beforehand, his enthusiasm for the business and empathy towards the advertiser's past experience were evident.",
      "The questions Simon asked were relevant and aligned with the advertiser's needs, showcasing his preparation for the call. This allowed him to deepen his understanding of the business and validate the advertiser's goals during the Discovery phase. By focusing on the advertiser's current campaign performance and the impact of IOS14 changes on CPAs, he addressed important areas. However, he could have delved deeper into the overall marketing budget, considering the advertiser's mention of smaller budgets compared to other channels. Understanding the budget would have helped Simon influence spending decisions and align his strategy accordingly.",
      "While it’s good that Simon focused on the current performance of the campaigns and the increase to CPAs post the changes to IOS14, he could have taken more time to understand the overall marketing budget. The advertiser mentioned that the budgets are quite small compared to other channels.  and he could have used this as an opportunity to understand this more deeply. This is important because armed with that knowledge later, Simon could look to influence spend decisions and ensure your strategy is being well supported with the appropriate investment.",
      "Simon did well in presenting personalized and relevant solutions to the advertiser. He effectively highlighted the benefits of each solution and their importance in improving performance. However, he could have provided more detailed examples and guidance, such as sharing specific ad sets and campaigns that would benefit from the Structure for Scale solution.",
      "We typically think of securing commitment as the implementation of a specific solution but in this case, Simon was correct in asking for a follow up call as the initial customer commitment. He's rebuilding trust here after all! Well done. Simon also clearly gained some buy-in from the advertiser, so well done again. This is all down to his prep before the call and the quality of his earlier questions. He could also have asked the advertiser to complete a specific task, like reviewing how campaigns and adsets might be consolidated. This could increase the likelihood of a follow up call to progress the relationship further.",
      "Simon handled the advertiser's concern about the perceived value of the Meta Marketing Pro Team effectively. By acknowledging and addressing the hesitation, he showed attentiveness throughout the call. However, he could have reconfirmed the topics to be discussed in a subsequent call to help the advertiser assess whether giving the Meta Marketing Pro team another chance was worth it.",
      "Simon did a great job. You reviewed the key takeaways from the discussion and checked to make sure the advertiser had no further questions. You confirmed your next steps and how the advertiser can get i touch if he needs to. You could have reconfirmed what you would talk about in a subsequent call, if given the chance. This could help the client decide if it’s worth giving the Meta Marketing Pro team another chance!"
    ]
  },
  {
    "tabs": [
      "Call Opening",
      "Discovery & Connection",
      "Tailored Solutions",
      "Securing Commitment",
      "Unlocking Opportunities",
      "Call Closing"
    ],
    "scores": [
      0,
      75,
      100,
      100,
      100,
      75
    ],
    "average": 75,
    "description": [
      "In the call opening, Simon did not showcase any preparation. He missed an opportunity to demonstrate knowledge of the client's business, including their challenges, products, and previous conversations. Additionally, he omitted the agenda for the call, which could have provided a brief explanation of the topics to be covered and allowed the advertiser to include any specific items they wanted to discuss. As Simon already had an established relationship with the advertiser, there was no need to ask for the last 4 digits of the ad account as verification.",
      "Simon asked a relevant question about changing to WooCommerce, which tied back to a previous conversation that the advertiser cared about. His follow-up question about analytics demonstrated coherence, as it directly responded to something the advertiser mentioned earlier. However, he could have delved deeper into the topic of analytics by probing for examples of discrepancies. Providing more context for his questions would have ensured buy-in from the advertiser and elicited higher quality responses.",
      "When explaining the setup process for the Conversions API and Product Catalog on WooCommerce, Simon did an excellent job of providing a clear walkthrough. He effectively educated the advertiser on the benefits of these solutions, highlighting how they can improve campaign targeting, decrease CPA, and enable more accurate ad measurement.",
      "Simon did an excellent job securing commitments with the advertiser. ",
      "Simon did a fantastic job unlocking future opportunities for the advertiser to work with Meta in the future.",
      "While Simon summarized the key takeaways from the discussion, he failed to check if the advertiser had any additional or unanswered questions. Although he outlined the follow-up actions required, the absence of scheduling a follow-up call prevented him from setting expectations for the next conversation. It would have been beneficial for Simon to schedule the next call shortly after the call with the Meta Technical Pro, ensuring clarity and continuity in the relationship."
    ]
  }
] as const;