import React from "react";
import { Layer } from "react-xr-ui";
import type { Answer } from "@/types";
import { STACKED_TRANSITION_DELAY } from "@/config/consts";
import SlideUp from "@/components/slide-up";
import ButtonInteraction from "@/components/button-interaction";
import FadeIn from "@/components/fade-in";
import ArrowButton from "@/components/arrow-button";

type Props = {
  subtitle: string;
  image: string;
  question: string;
  answers: Answer[];
  onAnswer: (answer: Answer) => void;
  questionFontSize?: number | `${number}px` | undefined;
  useAnswerHistory: [Answer[], React.Dispatch<React.SetStateAction<Answer[]>>];
};

export default function Question({
  subtitle,
  image,
  questionFontSize = '36px',
  question,
  answers,
  onAnswer,
  useAnswerHistory,
}: Props) {
  const [hoverAnswerIndex, setHoverAnswerIndex] = React.useState(-1);
  const [answerIndex, setAnswerIndex] = React.useState(-1);
  const [answerHistory, setAnswerHistory] = useAnswerHistory;

  const answersRow1 = answers.slice(0, 3);
  const answersRow2 = answers.slice(3, 6);

  return (
    <Layer height="110%" aspectRatio={1200 / 700}>
      <Layer width="100%" height="100%" backgroundColor="rgba(255, 255, 255, 0.95)" justifyContent="space-around">
        <Layer
          width="47.91666666666667%"
          height="100%"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
        >
          <Layer width="100%" height="8.285714285714285%" />
          <SlideUp isVisible={false}>
            <Layer
              width="100%"
              height="8.571428571428571%"
              fontSize={1}
              fontWeight="800"
              lineHeight={1}
              textContent={`Knowledge Check`}
              color="#1C2B33"
            />
          </SlideUp>
          <Layer width="100%" height="2.7142857142857144%" />
          <SlideUp delay={STACKED_TRANSITION_DELAY * 2} isVisible={false}>
            <Layer
              width="100%"
              height="4.714285714285714%"
              textContent={subtitle}
              fontWeight="700"
              fontSize={1 / 1.3}
              color="#000000"
            />
          </SlideUp>
          <Layer width="100%" height="10%" />
          <SlideUp delay={STACKED_TRANSITION_DELAY * 3} isVisible={false}>
            <Layer
              width="100%"
              height="10%"
              textContent={`Fill in the blank for the following statement:`}
              fontSize={0.35}
              fontWeight="500"
              color="#000000"
            />
          </SlideUp>
          <Layer width="100%" height="2%" />
          <SlideUp delay={STACKED_TRANSITION_DELAY * 3} isVisible={false}>
            <Layer
              width="100%"
              height={`${Math.ceil(question.length / 51) * 4}%`}
              textContent={question}
              fontSize={questionFontSize}
              lineHeight={1.65}
              fontWeight="700"
              color="#000000"
            />
          </SlideUp>
          <Layer width="100%" height="2.2857142857142856%" />
          <SlideUp delay={STACKED_TRANSITION_DELAY * 4} isVisible={false}>
            <Layer width="100%" height="7%" justifyContent="start" gap={0.025}>
              {answersRow1.map((answer, index) => {
                return (
                  <Layer
                    key={index}
                    width="33%"
                    height="100%"
                    aspectRatio={2.6875 / 12.375}
                    backgroundColor={
                      !answerHistory.includes(answer) && [hoverAnswerIndex, answerIndex].includes(index)
                        ? "#1877F2"
                        : answerHistory.includes(answer)
                        ? "gray"
                        : "#DBDBDB"
                    }
                  >
                    <ButtonInteraction
                      onOver={() => {
                        if (answerHistory.includes(answer)) return;
                        setHoverAnswerIndex(index);
                      }}
                      onOut={() => {
                        if (answerHistory.includes(answer)) return;
                        setHoverAnswerIndex(-1);
                      }}
                      onDown={() => {
                        if (answerHistory.includes(answer)) return;
                        setAnswerIndex(index);
                      }}
                    >
                      <Layer
                        width="92.03980099502488%"
                        height="100%"
                        fontSize={1 / 3}
                        textAlign="center"
                        fontWeight="500"
                        lineHeight={1.3}
                        color={[hoverAnswerIndex, answerIndex].includes(index) ? "#FFFFFF" : "#000000"}
                        textContent={answer.answer}
                        verticalAlign="middle"
                      />
                    </ButtonInteraction>
                  </Layer>
                );
              })}
            </Layer>
          </SlideUp>
          <Layer width="100%" height="25%" />
          <SlideUp delay={STACKED_TRANSITION_DELAY * 4} isVisible={false}>
            <Layer width="100%" height="7%" justifyContent="start" gap={0.025}>
              {answersRow2.map((answer, index) => {
                return (
                  <Layer
                    key={index}
                    width="33%"
                    height="100%"
                    aspectRatio={2.6875 / 12.375}
                    backgroundColor={
                      !answerHistory.includes(answer) && [hoverAnswerIndex, answerIndex].includes(index)
                        ? "#1877F2"
                        : answerHistory.includes(answer)
                        ? "gray"
                        : "#DBDBDB"
                    }
                  >
                    <ButtonInteraction
                      onOver={() => {
                        if (answerHistory.includes(answer)) return;
                        setHoverAnswerIndex(index);
                      }}
                      onOut={() => {
                        if (answerHistory.includes(answer)) return;
                        setHoverAnswerIndex(-1);
                      }}
                      onDown={() => {
                        if (answerHistory.includes(answer)) return;
                        setAnswerIndex(index);
                      }}
                    >
                      <Layer
                        width="92.03980099502488%"
                        height="100%"
                        fontSize={1 / 3}
                        textAlign="center"
                        fontWeight="500"
                        lineHeight={1.3}
                        color={[hoverAnswerIndex, answerIndex].includes(index) ? "#FFFFFF" : "#000000"}
                        textContent={answer.answer}
                        verticalAlign="middle"
                      />
                    </ButtonInteraction>
                  </Layer>
                );
              })}
            </Layer>
          </SlideUp>
        </Layer>
        <SlideUp delay={STACKED_TRANSITION_DELAY * 5} isVisible={false}>
          <Layer
            width="36%"
            height="100%"
            backgroundSize="cover"
            backgroundPosition={[0.5, 0.5]}
            backgroundImage={image}
          />
        </SlideUp>
      </Layer>
      <Layer autoLayout={false} width="100%" height="100%" alignItems="end" justifyContent="end">
        {answerIndex > -1 && (
          <FadeIn>
            <Layer width="100%" height="8.5%" justifyContent="end" gap={0.01}>
              <ArrowButton
                dir="next"
                onDown={() => {
                  onAnswer(answers[answerIndex]);
                  setAnswerHistory([...answerHistory, answers[answerIndex]]);
                }}
              />
            </Layer>
          </FadeIn>
        )}
      </Layer>
    </Layer>
  );
}
