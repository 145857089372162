import React from "react";
import { useNavigate } from "react-router-dom";
import { Layer } from "react-xr-ui";
import ButtonInteraction from "@/components/button-interaction";

type Props = {
  moduleId: string;
};

export default function StartButton({ moduleId }: Props) {
  const navigate = useNavigate();
  const [isHover, setIsHover] = React.useState(false);

  return (
    <ButtonInteraction
      onOver={() => setIsHover(true)}
      onOut={() => setIsHover(false)}
      onDown={() => navigate(`/${moduleId}/call-opening`)}
    >
      <Layer
        height="100%"
        aspectRatio={202 / 60}
        backgroundColor={isHover ? "#1C2B33" : "#1877F2"}
        color="#ffffff"
        textContent="BEGIN"
        fontSize={0.38333333333333336}
        textAlign="center"
        verticalAlign="middle"
      />
    </ButtonInteraction>
  );
}
