import React from "react";
import { Layer, LayerRef } from "react-xr-ui";
import ButtonInteraction from "@/components/button-interaction";
import { Vector3 } from "three";

type Props = {
  onDown?: () => void;
  enabled?: boolean;
  text: string;
  position?: number[];
  height: number | `${number}%`;
  aspectRatio?: number;
  fontSize?: `${number}%`;
  zIndex?: number;
};

export default function Button({
  zIndex = 0,
  onDown,
  enabled = true,
  text,
  position,
  height,
  aspectRatio,
  fontSize = "39.6551724137931%",
}: Props) {
  const layerRef = React.useRef<LayerRef>(null);

  return (
    <Layer
      zIndex={zIndex}
      position={position as any as Vector3}
      ref={layerRef}
      opacity={enabled ? 1 : 0}
      height={height}
      aspectRatio={aspectRatio}
      backgroundColor="#1877F2"
    >
      <Layer
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position={[0, -0.005, 0]}
        height={fontSize}
        zIndex={3}
      >
        <Layer width="100%" height="100%" textContent={text} fontSize={1} color="#FFFFFF" textAlign="center" />
      </Layer>
      <ButtonInteraction
        enabled={enabled}
        onOver={() => {
          if (layerRef.current === null) return;
          layerRef.current.setAttrs((attrs) => {
            return { ...attrs, backgroundColor: "#1C2B33" };
          });
        }}
        onOut={() => {
          if (layerRef.current === null) return;
          layerRef.current.setAttrs((attrs) => {
            return { ...attrs, backgroundColor: "#1877F2" };
          });
        }}
        onDown={onDown}
      >
        <Layer autoLayout={false} width="100%" height="100%" />
      </ButtonInteraction>
    </Layer>
  );
}
