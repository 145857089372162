import React from "react";
import { Layer } from "react-xr-ui";
import FadeIn from "@/components/fade-in";
import Frame from "@/components/frame";
import assets from "@/config/assets";
import Button from "../../components/button";
import ButtonInteraction from "../../components/button-interaction";
import { appState } from "@/state/app-state";
import { useNavigate } from "react-router-dom";

export default function CardModuleMenu() {
  const setStep = appState((state) => state.setMenuStep);
  const name = appState((state) => state.name);
  const navigate = useNavigate();

  return (
    <Frame>
      <Layer
        height="100%"
        aspectRatio={1200 / 700}
        backgroundSize="contain"
        backgroundColor="rgba(255, 255, 255, 0.95)"
        autoLayout={false}
      >
        <FadeIn delay={700}>
          <Layer
            zIndex={2}
            height="10%"
            backgroundImage={assets["./content/autogenerated/meta-logo.svg"]}
            aspectRatio={167.64 / 71.7}
            autoLayout={false}
            width={`${(166 / 1200) * 100}%`}
            position={[-1.55, 0.9, 0]}
            backgroundSize="contain"
          />
        </FadeIn>
        <FadeIn delay={700 * 5}>
          <Layer
            zIndex={1}
            backgroundImage={assets["./content/autogenerated/menu-decoration.png"]}
            autoLayout={false}
            width={`100%`}
            position={[0, -2.26, 0]}
            aspectRatio={1200 / 700}
            backgroundSize="contain"
          />
        </FadeIn>
        <FadeIn delay={700 * 2}>
          <Layer
            zIndex={2}
            position={[0.245, .6, 0]}
            fontSize={.5}
            width="100%"
            verticalAlign="end"
            height="20%"
            fontWeight="bold"
            color="#B2B2B2"
            autoLayout={false}
            textContent={`Hi${name === "" ? "" : `, ${name}`}`}
          />
        </FadeIn>
        <FadeIn delay={700 * 2.1}>
          <Layer
            zIndex={2}
            position={[0.245, -0.07, 0]}
            fontSize={0.2}
            width="100%"
            fontWeight="bold"
            color="black"
            autoLayout={false}
            textContent={`Welcome to Scaled\nShadowing 360`}
          />
          <Layer
            zIndex={2}
            position={[0.245, -0.55, 0]}
            fontSize={0.1}
            width="100%"
            fontWeight="lighter"
            color="black"
            autoLayout={false}
            textContent="This is an immersive experience. Feel free to have a look around the room."
          />
        </FadeIn>
        <FadeIn delay={700 * 3}>
          <Button
            zIndex={2}
            position={[-1.42, -0.39, 0]}
            text="ONBOARDING"
            height={0.2}
            fontSize="31%"
            onDown={() => {
              setStep(1);
            }}
            aspectRatio={12.625 / 3.75}
          />
          <ButtonInteraction
            onDown={() => {
              navigate("/?module-selection=true");
            }}
          >
            <Layer
              zIndex={2}
              position={[-1.68, -0.64, 0]}
              aspectRatio={48 / 23}
              height={`${(23 / 700) * 100}%`}
              backgroundSize="contain"
              backgroundColor="white"
              backgroundImage={assets["./content/autogenerated/skip-button.png"]}
              autoLayout={false}
            ></Layer>
          </ButtonInteraction>
        </FadeIn>
        <FadeIn delay={700 * 4}>
          <Layer
            flexDirection="row"
            zIndex={2}
            position={[-0.38, -1.4, 0]}
            autoLayout={false}
            width="65px"
            alignItems="start"
          >
            <Layer
              fontSize={"36px" as any}
              width="56%"
              height="7px"
              fontWeight="400"
              color="black"
              zIndex={4}
              textContent={`By using this website, you’re agreeing to the`}
            />
            <ButtonInteraction
              onDown={() => {
                window.open("https://www.facebook.com/terms.php", "_blank");
              }}
            >
              <Layer
                zIndex={4}
                fontSize="36px"
                width="50%"
                height="7px"
                fontWeight="400"
                color="#1877F2"
                textContent={`‎ Facebook Terms of Service`}
              />
            </ButtonInteraction>
          </Layer>
        </FadeIn>
      </Layer>
    </Frame>
  );
}
