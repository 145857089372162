import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import SphereVideo from "@/components/sphere-video";
import { appState } from "@/state/app-state";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";
import FillBlanksQuestion from "@/components/fill-blanks-question/fill-blanks-question";

export default function Module8Discovery() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["8"]["3"].start}
          subtitlesFile="./subtitles/8.vtt"
          end={videoSplit["8"]["3"].end}
          src={assets.video8}
          play={false}
        />
      )}
      <Title textContent="DISCOVERY" />
      {step === 0 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          subtitle="During Discovery: "
          image={assets["./content/autogenerated/script2/discovery-step-0.png"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-1.svg"]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Seek to \nunderstand`}
          image={assets["./content/autogenerated/script2/discovery-step-1.jpeg"]}
          descriptionImage={assets["./content/autogenerated/shared/discovery-2.svg"]}
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
          buttonsJustifyContent="space-between"
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["8"]["3"].start}
          subtitlesFile="./subtitles/8.vtt"
          end={videoSplit["8"]["3"].end}
          src={assets.video8}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <FillBlanksQuestion
          subtitle={`Discovery`}
          image={assets["./content/autogenerated/new-images/8-5-2.jpg"]}
          imageCorrect={assets["./content/autogenerated/new-images-2/8-3-correct2.jpg"]}
          imageIncorrect={assets["./content/autogenerated/new-images-2/8-3-correct2.jpg"]}
          descriptionCorrect="Relevant questions refer to topics that have been established as important to the advertiser. Cohesive questions are part of a natural flow of the conversation."
          question={`Simon’s question about LaFleur’s plans to change to WooCommerce was highly (1) _______ because it referred back to a previous important conversation with the advertiser.\n\n   The follow-up question about analytics is a great example of asking a (2) _______ question because it was in direct response to something the advertiser said earlier in this conversation.`}
          answers={[
            { answer: `(1) relevant; (2) cohesive`, correct: true },
            {
              answer: `(1) open; (2) insightful`,
              correct: false,
            },
            {
              answer: `(1) commercial; (2) structured`,
              correct: false,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/tailored-solutions`);
          }}
        />
      )}
    </>
  );
}
