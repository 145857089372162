import React from "react";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { useXR } from "@react-three/xr";
import { useLocation } from "react-router-dom";
import { OrbitControls as OrbitControlsType } from "three-stdlib/controls/OrbitControls";
import emitter from "@/services/emitter";

export default function Camera() {
  const isPresenting = useXR((state) => state.isPresenting);
  const location = useLocation();
  const controlsRef = React.useRef<OrbitControlsType | null>(null);
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (mounted) return;
    setMounted(true);
  }, [mounted]);

  React.useLayoutEffect(() => {
    if (isPresenting) return;
    if (!mounted) return;
    const controls = controlsRef.current;
    if (controls === null) return;
    controls.update();
    controls.saveState();
    return () => {
      if (controls === null) return;
      controls.reset();
    };
  }, [isPresenting, mounted, location]);

  React.useEffect(() => {
    const controls = controlsRef.current;

    function onControlsReset() {
      if (controls === null) return;
      controls.reset();
    }

    emitter.on("controls.reset", onControlsReset);
    return () => {
      emitter.off("controls.reset", onControlsReset);
    };
  }, [mounted]);

  React.useEffect(() => {
    if (!mounted) return;

    const controls = controlsRef.current;

    function onControlsDisable() {
      if (controls === null) return;
      controls.enabled = false;
      controls.update();
    }

    function onControlsEnable() {
      if (controls === null) return;
      controls.enabled = true;
      controls.update();
    }

    emitter.on("controls.disable", onControlsDisable);
    emitter.on("controls.enable", onControlsEnable);
    return () => {
      emitter.off("controls.disable", onControlsDisable);
      emitter.off("controls.enable", onControlsEnable);
    };
  }, [mounted]);

  return (
    <>
      <PerspectiveCamera makeDefault position={[0, 0, 5]} />
      {!isPresenting && (
        <OrbitControls ref={controlsRef} rotateSpeed={-1} makeDefault enableZoom={false} enablePan={false} />
      )}
    </>
  );
}
