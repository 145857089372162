import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { SceneParams } from "@/types";
import assets from "@/config/assets";
import { appState } from "@/state/app-state";
import Title from "@/components/title";
import ArrowButton from "@/components/arrow-button";
import CardLarge from "@/components/card-large";
import MultipleChoiceQuestion from "@/components/multiple-choice-question/multiple-choice-question";
import SphereVideo from "@/components/sphere-video";
import ExamplesButton from "@/components/examples-button";
import videoSplit from "@/config/video-split";

export default function Module8TailoredSolutions() {
  const [step, setStep] = React.useState(0);
  const addProgress = appState.getState().addProgress;
  const params = useParams<SceneParams>();
  React.useEffect(() => {
    addProgress(params.moduleId!, params.sceneId!);
  }, [step]);
  const navigate = useNavigate();

  return (
    <>
      {![2].includes(step) && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["5"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["5"].end}
          src={assets.video7}
          play={false}
        />
      )}
      <Title textContent="TAILORED SOLUTIONS" />
      {step === 0 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets[`./content/autogenerated/script1/tailored-step-0.png`]}
          subtitle="During Tailored Solutions:"
          descriptionImage={assets[`./content/autogenerated/shared/tailored-1.svg`]}
          buttons={[<ExamplesButton onDown={() => setStep(1)} />, <ArrowButton dir="next" onDown={() => setStep(2)} />]}
        />
      )}
      {step === 1 && (
        <CardLarge
          title={`Discuss what \nmatters most`}
          image={assets[`./content/autogenerated/script1/tailored-step-1.png`]}
          descriptionImage={assets[`./content/autogenerated/shared/tailored-2.svg`]}
          buttonsJustifyContent="space-between"
          buttons={[
            <ArrowButton dir="prev" onDown={() => setStep(0)} />,
            <ArrowButton dir="next" onDown={() => setStep(2)} />,
          ]}
        />
      )}
      {step === 2 && (
        <SphereVideo
          rotation={[0, -40, 0]}
          start={videoSplit["7"]["5"].start}
          subtitlesFile="./subtitles/7.vtt"
          end={videoSplit["7"]["5"].end}
          src={assets.video7}
          onEnded={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <CardLarge
          title="Feedback"
          subtitle="Tailored Solutions"
          image={assets[`./content/autogenerated/script1/tailored-step-3.png`]}
          description={`Well done.\n\n   The solutions Simon mentioned were personalized and relevant to the advertiser. They also linked the benefits of each, highlighting how the solutions will serve the overall strategy and why they’re important when it comes to improving performance.`}
          buttons={[<ArrowButton dir="next" onDown={() => setStep(4)} />]}
        />
      )}
      {step === 4 && (
        <MultipleChoiceQuestion
          subtitle={`Tailored Solutions`}
          image={assets["./content/autogenerated/new-images/7-5-5-7.jpg"]}
          descriptionCorrect="If Simon had pointed out a few of Shawn’s ad sets and campaigns that would benefit the most from Structure for Scale, that would have really highlighted the value of the solution in a customized way."
          question={`Simon could have shared more about each solution. For example, with Structure for Scale, how could Simon have delivered even more efficiently?`}
          answers={[
            {
              answer: `Simon could have shown Shawn some successful campaigns from their other clients that implemented Structure for Scale.`,
              correct: false,
            },
            {
              answer: `Simon could have offered an additional call with their team lead, to fill in any additional gaps that Shawn might have questions about.`,
              correct: false,
            },
            {
              answer: `Simon could have shared some examples of Shawn’s ad sets and campaigns that would benefit most from Structure for Scale.`,
              correct: true,
            },
          ]}
          onAnswer={(answer) => {
            appState.getState().setAnswer(params.moduleId!, params.sceneId!, answer);
            navigate(`/${params.moduleId}/securing-commitment`);
          }}
        />
      )}
    </>
  );
}
